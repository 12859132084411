import { Injectable } from 'injection-js';
import { Module } from 'shared/core';
import { Zoom } from 'shared/lib';
import { Webshop } from 'apps/webshop';

@Injectable()
export class ProductGalleryModule extends Module {
  private zoomablePhoto = $('#productPhoto');
  private zoomableContainer = $('#zoomable-container');
  private mainPhoto = $('#main_image');
  private thumbs = $('.productThumb');
  private hoverOverMessage = $('.product-details-td-gallery-legend');
  private zoom: Zoom;

  public init(): void {
    this.createZoom();
    this.thumbs.on('click', e => this.changePhoto($(e.target)));
  }

  private createZoom(): void {
    const size = Webshop.display.isMobile$.value.resolution ? '45%' : '100%';
    this.zoom = new Zoom({
      selector: '#productPhoto',
      size: size,
    });
  }

  private changePhoto(target: JQuery): void {
    if (typeof target === typeof undefined || target.length == 0) {
      return;
    }

    const isZoomable: boolean = (<HTMLImageElement>target.closest('.productThumb')
      .find('.invisible-zoomed img')[0]).naturalWidth > 1000;

    const viewSrc = target.closest('.productThumb').data('viewSrc')

    if (typeof viewSrc === typeof undefined) {
      return;
    }
    

    if (isZoomable) {
      const zoomSrc = target.closest('.productThumb').data('zoomSrc')
      this.zoomablePhoto.attr('src', viewSrc);
      (this.zoomablePhoto as any).swinxyzoom('load', viewSrc, zoomSrc);
      this.mainPhoto.hide();
      this.zoomableContainer.show();
      this.hoverOverMessage.show();
    } else {
      this.mainPhoto.attr('src', viewSrc);
      this.mainPhoto.show();
      this.zoomableContainer.hide();
      this.hoverOverMessage.hide();
    }
  }

  public cleanup(): void {
    /* Since Turbolinks does not interfere with browser local events Zoom needs to be cleaned up manually */
    this.zoomablePhoto.detach();
    this.zoomablePhoto.appendTo('.product-details-td-gallery-image');
    $('.sxy-zoom-container').remove();
  }
}
