import { Injectable } from 'injection-js';

@Injectable()
export class FormDataBuilder {
  public formData: FormData;
  constructor() {
    this.formData = new FormData();
    // Add auth token
    let param_name = $('meta[name="csrf-param"]').attr('content');
    let auth_token = $('meta[name="csrf-token"]').attr('content');

    this.formData.append(param_name, auth_token);
  }

  public append_param(key: string, attribute: string, value: any, include_undefined = false): void {
    if (!value && include_undefined) {
      value = '';
    }

    if (typeof value != typeof undefined) {
      let key_attr = attribute;
      if (key) {
        key_attr = `${key}[${key_attr}]`;
      }

      this.formData.append(key_attr, value);
    }
  }
}
