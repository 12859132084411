import { Module } from 'shared/core';
import { Tabs } from 'shared/lib';
import { Injectable } from 'injection-js';
import { FlexTable } from 'shared/lib';

@Injectable()
export class InvoiceModule extends Module {
  private tabs: Tabs;

  constructor() {
    super();
  }

  public init(): void {
    new FlexTable('sm');

    this.tabs = new Tabs({
      id: 'invoice-list',
      remote: false,
    });
  }
}
