import { Http, Module } from 'shared/core';
import { Injectable } from 'injection-js';
import { AjaxForm } from 'shared/lib';

@Injectable()
export class ForgottenModule extends Module {
  private isInit = false;
  constructor(
    private http: Http
  ) {
    super();
  }

  public init(): void {
    this.isInit = true;

    AjaxForm.createGeneral('form.expected_item_form', (response, form) => {
      if (response.success) {
        form.find('.add_to_basket').addClass('button_success').prop('disabled', true).text('Added!');
        form.find('.dd-container').prepend('<div class="disabled-container-spinner"></div>');
        form.find('.dd-select').addClass('disabled');

        $('.next-basket').removeClass('hidden');
        $('.next-nametags').addClass('hidden');
      } else {
        form.find('.add_to_basket').prop('disabled', false);
      }
    }, (form) => {
      let subSchoolItemId= form.find('input.sub_school_item_id').val() as string;

      if (subSchoolItemId.length == 0) {
        return false;
      }

      form.find('.add_to_basket').prop('disabled', true);
      return true;
    });

    $('.size-select select').each((i, el) => {
      ($(el) as any).sbsizing({
        maxHeight: 320,
        onSelected: async (data) => {
          await this.changeSize(data);
        },
      });
    });

    this.isInit = false;
  }

  private async changeSize(data): Promise<void> {
    let row = data.original.closest('.expected_item_row');
    row.find('input.sub_school_item_id').val(data.selectedData.value);

    if (data.selectedData.value && !this.isInit) {
      let childId = row.data('childId');
      let priceContainer = row.find('.price_container');

      const response = await this.http.getHtml('sub_school_products/price', {
        child_id: childId,
        sub_school_item_id: data.selectedData.value,
      });

      if (response['success']) {
        priceContainer.html(response['product_price']);
      }
    }
  }
}
