import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from 'injection-js';
import { Action, IState, InitialState } from 'shared/models';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Injectable()
export class State {
  public state$: BehaviorSubject<IState> = new BehaviorSubject(InitialState);
  public actions$: Subject<Action> = new Subject();

  public register(state: IState): void {
    this.state$.next({
      ...this.state$.value,
      ...state,
    });
  }

  public get get(): Observable<IState> {
    return this.state$.asObservable().pipe(distinctUntilChanged(), shareReplay());
  }

  public dispatch(action: Action): void {
    this.actions$.next(action);
  }
}
