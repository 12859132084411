import { Http, Module, State, TurboLinks } from 'shared/core';
import { Injectable } from 'injection-js';
import { Flash, FlexTable } from 'shared/lib';
import { Webshop } from 'apps/webshop';
import { Actions } from 'shared/state';
import { FlashElement } from 'shared/elements';

@Injectable()
export class PersonalisationsModule extends Module {
  private selectedPersonalisationForm;
  private allPersonalisationForms: JQuery = $('.personalisations form');
  private personalisationSelect: JQuery = $('#personalisation_type_select');
  private personalisationContent: JQuery = $('div.order_item_personalisation_content input');
  private saveChangesButton: JQuery = $('#savePersonalisationChangesBtn');
  private buyPersonalisationButtons: JQuery = $('.buy-personalisation');
  private deletePersonalisationButton: JQuery = $('.deleteOrderItemPersonalisation');

  constructor(
    private state: State,
    private http: Http,
    private turbolinks: TurboLinks) {
    super();
  }

  public init(): void {
    this.personalisationSelect.on('click', (e) => this.personalisationSelection($(e.target)));
    this.personalisationContent.on('input', (e) => this.personalisationContentChange($(e.target)));
    new FlexTable('sm');
    this.saveChangesButton.on('click', e => this.confirmPersonalisationChanges(e));
    this.buyPersonalisationButtons.on('click', e => this.confirmBuyPersonalisation(e));
    this.deletePersonalisationButton.on('click', e => this.deletePersonalisation(e));
  }

  private async deletePersonalisation(e) {
    e.preventDefault();
    let button = $(e.target).closest('a');

    let formData = window.App.newFormData();
    formData.append('_method', 'delete');
    let response = await this.http.postFormData<any>(button.attr('href'), formData);

    if (response.success == true) {
      window.App.register({flash: {
        alert: response['flash']['alert'] as string|string[], notice: response['flash']['notice'] as string|string[]
      }});

      this.turbolinks.visit(button.data('backTo'));
    } else {
      Flash.fromAjaxRequest(response);
    }
  }

  private confirmPersonalisationChanges(e) {
    e.preventDefault();
    const form = $(e.target).closest('form');

    if (!this.validateForm(form)) {
      return;
    }

    const changedContent = <string> form.find('.order_item_personalisation_content input').val();
    const prevContent = <string> form.find('#order_item_personalisation_prev_content').val();

    if (changedContent === prevContent) {
      form.trigger('submit');
    } else {
      this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'personalisationContentConfirmModal' });
      $('#newContent').html(<string> form.find('.order_item_personalisation_content input').val());
      $('#personalisationContentConfirmModal .continue').on('click', () => {
        form.trigger('submit');
      });
    }
  }

  private confirmBuyPersonalisation(e) {
    e.preventDefault();
    const form = $(e.target).closest('form');

    if (!this.validateForm(form)) {
      return;
    }

    this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'personalisationContentConfirmModal' });
    $('#confirmMessage').html("Are you sure you'd like the personalisation details to be:");
    $('#newContent').html(<string> form.find('.order_item_personalisation_content input').val());
    $('#personalisationContentConfirmModal .continue').on('click', () => {
      form.trigger('submit');
    });
  }

  private personalisationSelection(select: JQuery): void {
    this.selectedPersonalisationForm = null;

    if (select.val()) {
      this.selectPersonalisationForm();
    }

    this.showPersonalisationForm();
  }

  private selectPersonalisationForm(): void {
    if (this.allPersonalisationForms.length == 1) {
      this.selectedPersonalisationForm = this.allPersonalisationForms.first();
    }
    else if (this.personalisationSelect.val() !== null){
      this.selectedPersonalisationForm =  $('#form' + this.personalisationSelect.val());
    }
  }

  private showPersonalisationForm(): void {
    Webshop.display.hideElements(this.allPersonalisationForms);
    if (this.selectedPersonalisationForm !== null) {
      Webshop.display.showElement(this.selectedPersonalisationForm);
    }
  }

  private personalisationContentChange(input: JQuery): void {
    const form = input.closest('form');
    const maxCharacters = form.find('.max_characters_num');

    if (input.length > parseInt(maxCharacters.html())) {
      input.css('border-color', 'red');
    } else {
      input.css('border-color', '');
      this.showPersonalisationPrice(input, form);
    }
  }

  private showPersonalisationPrice(input: JQuery, form: JQuery): void {
    const orderItemQuantity = parseInt(form.find('#order_item_quantity').html());
    const unitPrice = parseFloat(form.find('#unit_price').html());
    const currencySymbol = form.find('#currency_symbol').html();
    const contentLength = input.val().toString().replace(/\s+/g, '').length;
    let price = 0;
    let formattedPrice = '';

    const pricingUnit = form.find('#pricing_unit');
    const personalisationPrice = form.find('#personalisation_price');

    if (pricingUnit.html() === 'Each') {
      price = orderItemQuantity * unitPrice;
    } else {
      price = orderItemQuantity * unitPrice * contentLength;
    }

    if (price > 0) {
      formattedPrice = currencySymbol + price.toFixed(2);
      personalisationPrice.html(formattedPrice);
      personalisationPrice.show();
    } else {
      personalisationPrice.hide();
    }
  }

  private validateForm(form: JQuery): boolean {
    let form_elem = form[0] as HTMLFormElement;
    let valid = true;
    if (typeof form_elem.checkValidity === typeof Function && !form_elem.checkValidity()) {
      valid = false;
      if (form_elem.reportValidity) {
        form_elem.reportValidity();
      }
    }
    return valid;
  }
}
