import 'password-strength-meter';

export class PasswordStrengthMeter {
  constructor(element: string) {
    ($(element) as any).password({
      showPercent: false,
      showText: false,
      animate: true,
      animateSpeed: 'medium',
      username: false,
      usernamePartialMatch: false,
      minimumLength: 6,
    });
  }
}
