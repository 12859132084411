import { Injectable } from 'injection-js';
import { Region } from 'shared/models';
import { State } from './state';
import axios, { AxiosResponse, Method } from 'axios';
import queryString from 'query-string';
import { filter } from 'rxjs/operators';
import { App } from './app';

@Injectable()
export class Http {
  private region: Region;

  constructor(
    private state: State,
  ) {
    axios.interceptors.response.use( response => {
      // No error here
      return response;
    },  error => {
      // Server-side error
      App.showError(error);
      return Promise.reject(error);
    });

    this.state.get.pipe(filter(e => e.currentRegion !== null)).subscribe(e => {
      this.region = e.currentRegion;
    });
  }

  public async get<T>(url: string, params?: Record<string, unknown>): Promise<T> {
    let response = await axios.get(this.parseUrl(url), {
      headers: {
        'Accept': 'application/json, text/html',
        'Content-Type': 'application/json',
      },
      params
    });

    return await Http.handleResponse(response);
  }

  public async getHtml(url: string, params?: Record<string, unknown>): Promise<Document> {
    let response = await axios.get<Document>(this.parseUrl(url), {
      headers: {
        'Content-Type': 'text/html',
      },
      params
    });

    return await Http.handleResponse(response);
  }

  public async post<T>(url: string, payload: unknown): Promise<T> {
    let response = await axios.post(this.parseUrl(url), payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });

    return await Http.handleResponse(response);
  }

  public async postHtml(url: string, payload: unknown): Promise<Document> {
    let response = await axios.post<Document>(this.parseUrl(url), payload, {
      headers: {
        'Accept': 'text/html',
        'Content-Type': 'application/json'
      }
    });

    return await Http.handleResponse(response);
  }

  public async put<T>(url: string, payload: unknown): Promise<T> {
    let response = await axios.put(this.parseUrl(url), payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });

    return await Http.handleResponse(response);
  }

  public async putHtml(url: string, payload: unknown): Promise<Document> {
    let response = await axios.put<Document>(this.parseUrl(url), payload, {
      headers: {
        'Accept': 'text/html',
        'Content-Type': 'application/json'
      }
    });

    return await Http.handleResponse(response);
  }

  /**
   * @deprecated use postFormData instead
   */
  public async postAsString<T>(url: string, payload: unknown): Promise<T> {
    const parsed = queryString.stringify(payload);
    return await axios.post(this.parseUrl(url), parsed, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept' : 'application/json, text/javascript, application/javascript, application/ecmascript, application/x-ecmascript'
      }
    });
  }

  public async postFormData<T>(url: string, payload: FormData): Promise<T> {
    let response = await axios.post(this.parseUrl(url), payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept' : 'application/json, text/html, text/plain'
      }
    });

    return await Http.handleResponse(response);
  }

  /**
   * @deprecated use postFormData instead
   */
  public async putAsString<T>(url: string, payload: unknown): Promise<T> {
    const parsed = queryString.stringify(payload);
    let response = await axios.put(this.parseUrl(url), parsed, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return await Http.handleResponse(response);
  }

  public async deleteHtml(url: string, params?: Record<string, unknown>): Promise<Document> {
    let response = await axios.delete<Document>(this.parseUrl(url), {
      headers: {
        'Content-Type': 'text/html',
      },
      params
    });

    return await Http.handleResponse(response);
  }

  public async delete<T>(url: string, params?: Record<string, unknown>): Promise<T> {
    let response = await axios.delete<Document>(this.parseUrl(url), {
      headers: {
        'Accept': 'application/json'
      },
      params
    });

    return await Http.handleResponse(response);
  }

  public async deleteFormData<T>(url: string, payload: FormData): Promise<T> {
    let response = await axios.request({
      method: 'delete',
      url: this.parseUrl(url),
      data: payload,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
          'Accept' : 'application/json, text/html, text/plain'
      }
    });

    return await Http.handleResponse(response);
  }

  public async getAudio(url: string): Promise<ArrayBuffer> {
    let response = await axios.get(this.parseUrl(url), {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'audio/mpeg, audio/wav',
      }
    });

    return await Http.handleResponse(response);
  }

  public async request<T>(method: string, url: string, params?: unknown): Promise<T> {
    let response = await axios.request({
      method: method as Method,
      url: url,
      data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept' : 'application/json, text/html, text/plain'
      }
    });

    return await Http.handleResponse(response);
  }

  private parseUrl(url) {
    if (url.startsWith('http') || !window.App.regionAware) {
      return url;
    } else {
      if (url.startsWith(`/${this.region}`)) {
        return url;
      } else {
        return `/${this.region}/${url}`;
      }
    }
  }

  private static handleResponse<T>(response: AxiosResponse): T {
    if (typeof response === typeof undefined) {
      return undefined;
    } else {
      return response.data;
    }
  }
}
