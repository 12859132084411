import { Injectable } from 'injection-js';
import { Element, State, TurboLinks } from 'shared/core';
import { TypeAheadList } from 'shared/lib';
import { filter, takeUntil } from 'rxjs/operators';
import { Events } from 'shared/state';

@Injectable()
export class TypeAheadListElement extends Element {
  constructor(
    private state: State,
    private turbolinks: TurboLinks
  ) {
    super();
  }

  public init(): void {
    // Ensure any previous instantiated Select2 dropdowns are removed
    TypeAheadList.cleanContainer($('body'));

    TypeAheadListElement.forContainer($('body'));

    this.state.actions$.pipe(
      filter(e => e.name === Events.MODAL_OPENED || e.name === Events.ELEMENT_SHOWN),
      takeUntil(this.turbolinks.visit$)
    ).subscribe(action => {
      TypeAheadListElement.forContainer(action.payload);
    });
  }

  public static forContainer(container: string|JQuery): void {
    let new_container: JQuery;

    if (typeof container == 'string') {
      new_container = $(`#${container}`);
    } else {
      new_container = container as JQuery;
    }

    if (typeof new_container === typeof undefined) {
      return;
    }

    new_container.find('select.autocomplete').each((i, htmlElem) => {
      let elem = $(htmlElem);
      TypeAheadList.forElement(elem);
    });
  }
}
