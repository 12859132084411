import { Injectable } from 'injection-js';
import { Element, TurboLinks } from 'shared/core';
import { MobileData } from 'shared/models';
import { takeUntil } from 'rxjs/operators';
import { Webshop } from 'apps/webshop';
import { fromEvent } from 'rxjs';

const menuMap = {
  'contact_us': '11',
  'faq': '2',
  'Privacy_Policy': '17',
  'how-to-shop': '3',
};

@Injectable()
export class MenuElement extends Element {
  private element: JQuery = $('#sidebarMenu');
  private elementStick: JQuery = $('.sidebar-menu-stick');
  private trigger: JQuery = $('#sidebarMenuTrigger');
  private secondaryMenu: JQuery = $('#secondaryMenu');
  private secondaryDock: JQuery = $('#secondaryDock');
  private static openClass = 'open-left';
  private static closedClass = 'closed';
  private visible = false;

  constructor(
    private turbolinks: TurboLinks,
  ) {
    super();
  }

  public init(): void {
    // Toggle on click
    this.trigger.on('click', () => this.toggleMenu());
    Webshop.display.isMobile$.pipe(takeUntil(this.turbolinks.visit$)).subscribe(e => this.dockSecondary(e));
    Webshop.display.resize$.pipe(takeUntil(this.turbolinks.visit$)).subscribe(() => this.desktopHeightCheck());

    fromEvent(window, 'load')
      .pipe(takeUntil(this.turbolinks.visit$))
      .subscribe(() => this.desktopHeightCheck());

    this.setActiveOnSecondary();

    const nav_images = this.elementStick.find('img');
    let loaded_images_count = 0;

    nav_images.on('load',() => {
      loaded_images_count++;

      if (nav_images.length == loaded_images_count) {
        this.desktopHeightCheck();
      }
    });
  }

  public toggleMenu(): void {
    if (Webshop.display.isMobile$.value.resolution) {
      this.visible ? this.hideMenu() : this.showMenu();
    }
  }

  private dockSecondary(isMobile: MobileData): void {
    if (isMobile.resolution) {
      this.secondaryMenu.detach().appendTo(this.element);

      if (!this.element.hasClass(MenuElement.openClass)) {
        this.element.addClass(MenuElement.closedClass);
      }
    } else {
      this.secondaryMenu.detach().appendTo(this.secondaryDock);
      this.element.removeClass([MenuElement.closedClass, MenuElement.openClass]);
      jQuery('body').css('overflow', 'initial');
    }
  }

  private showMenu(): void {
    this.element.addClass(MenuElement.openClass).removeClass(MenuElement.closedClass);
    jQuery('body').css('overflow', 'hidden');
    this.visible = true;
  }

  private hideMenu(): void {
    this.element.removeClass(MenuElement.openClass).addClass(MenuElement.closedClass);
    jQuery('body').css('overflow', 'initial');
    this.visible = false;
  }

  private setActiveOnSecondary(): void {
    const hash = window.location.pathname.slice(1);
    const match = menuMap[hash];

    if (match) {
      this.element.find('.active').removeClass('active');
      this.secondaryMenu.find(`[data-id=${match}]`).parent().addClass('active');
    }
  }

  private desktopHeightCheck(): void {
    let minimumAcceptableHeight = $('.header').outerHeight() + this.elementStick.height();

    if ($(window).height() < minimumAcceptableHeight) {
      this.elementStick.css('position', 'static');
    } else {
      this.elementStick.css('position', 'fixed');
    }
  }
}
