import { Module, Http, State } from 'shared/core';
import { Injectable } from 'injection-js';
import { Flash } from 'shared/lib';
import { Actions, Events } from 'shared/state';

export interface NameTagResponse {
  child: number;
  nametag: string;
  errors: string;
}

export interface NameTagQuantityResponse {
  order_total: string;
  price: string;
}

@Injectable()
export class NameTagsModule extends Module {
  private nameTagValue: JQuery = $('.nameTagValue');
  private nameTagUserValue: JQuery = $('.nameTagUserValue');
  private nameTagUserValueOptional: JQuery = $('.nameTagUserValueOptional');
  private nameTagUserSubmit: JQuery = $('.nameTagUserSubmit');
  private nameTagUserForm: JQuery = $('.edit_name_tag');
  private nameTagPrice: JQuery = $('.nameTagPrice');
  private nameTagPreview: JQuery = $('.nameTagPreview');
  private nameTagErrors: JQuery = $('#nameTagErrors');
  private nameTagClass = '.nameTagQuantity';
  private nameTagText = '';

  constructor(
    protected http: Http,
    protected state: State,
  ) {
    super();
  }

  public init(): void {
    this.nameTagUserForm.on('submit', e => this.setValue(e));
    this.nameTagUserSubmit.on('click', e => this.setValue(e));
    this.nameTagUserValue.on('keyup', () => this.checkNameTagsChange());
    this.nameTagUserValueOptional.on('keyup', () => this.checkNameTagsChange());
    this.bindNameTagSelect();
  }

  private bindNameTagSelect(): void {
    $(`${this.nameTagClass} select`).off('change')
      .on('change', (e: JQuery.ChangeEvent) => this.changeQuantity(e));
  }

  private async setValue(e): Promise<void> {
    e.preventDefault();
    let child: number;

    this.state.dispatch( { name: Actions.DISABLE_CONTAINER, payload: $('.modalContent') });

    if ($(e.target).is('form')) {
      child = $(e.target).data('child');
    } else {
      child = $(e.target).closest('button').data('child');
    }

    let line1 = (this.nameTagUserValue.filter(`[data-child="${child}"]`).val() as string).trim();
    let line2 = '';

    if (this.nameTagUserValueOptional.length > 0) {
      line2 = (this.nameTagUserValueOptional.filter(`[data-child="${child}"]`).val() as string).trim();
    }

    const values = await this.http.putAsString<NameTagResponse>(`/order/child_name_tag_text/${child}`, {
      name_tag_text: line2 == '' ? line1 : `${line1}\t${line2}`,
    });

    if (values.errors) {
      this.state.dispatch( { name: Actions.ENABLE_CONTAINER, payload: $('.modalContent') });
      this.nameTagErrors.html(values.errors);
      return;
    }

    this.state.dispatch( { name: Actions.CLOSE_MODAL });
    this.state.dispatch( { name: Actions.ENABLE_CONTAINER, payload: $('.modalContent') });
    this.renderNameTags(values);
  }

  private renderNameTags(values: NameTagResponse): void {
    if (values.errors) {
      this.nameTagErrors.html(values.errors);
    }

    let splitNameTagText = values.nametag.split(/\t/);
    let previewNameTagValue = values.nametag;

    if (splitNameTagText.length > 1) {
      this.nameTagUserValue.filter(`[data-child="${values.child}"]`).val(splitNameTagText[0]);
      this.nameTagUserValueOptional.filter(`[data-child="${values.child}"]`).val(splitNameTagText[1]);
      previewNameTagValue = values.nametag.replace(/\t/, '<br/>');
    } else {
      this.nameTagUserValue.filter(`[data-child="${values.child}"]`).val(values.nametag);
    }

    this.nameTagValue.filter(`[data-child="${values.child}"]`).val(values.nametag);
    this.nameTagPreview.filter(`[data-child="${values.child}"]`).find('span').html(previewNameTagValue);

    this.checkNameTagsChange();
  }

  private async changeQuantity(e: JQuery.ChangeEvent): Promise<void> {
    const elem = $(e.target);
    const quantity: number = elem.val() as number;
    const nametag: number = parseInt(elem.parents(this.nameTagClass).attr('data-nametag-id'));

    this.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: elem.closest('form') });
    const response = await this.http.put<NameTagQuantityResponse>(`order/order_name_tag_item/${nametag}`, {
      order_name_tag_item: {
        customer_quantity: quantity,
      }
    });

    Flash.fromAjaxRequest(response);

    this.renderQuantity(response, nametag);
    this.state.dispatch({ name: Events.TOTAL_CHANGED, payload: response.order_total });

    // Using .then instead of await to allow asynchronous calls to server
    const orderSummaryResponse = this.http.getHtml('order/order_subtotals', { include_name_tags: true });
    orderSummaryResponse.then((result) => $('.subtotals').replaceWith(result));

    const orderQuantitySelect = this.http.getHtml(`order/order_name_tag_item/quantity_select/${nametag}`);
    orderQuantitySelect.then((result) => {
      const form = $(this.nameTagClass).filter(`[data-nametag-id="${nametag}"]`).closest('form');
      form.replaceWith(result);
      this.bindNameTagSelect();
    });

    this.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: elem.closest('form') });
  }

  private renderQuantity(response: NameTagQuantityResponse, nametag:number): void {
    this.nameTagPrice.filter(`[data-nametag-id="${nametag}"]`).html(response.price);
  }

  private checkNameTagsChange(): void {
    this.nameTagValue.each((index, item) => {
      const child = $(item).data('child');
      const optionalValue = this.nameTagUserValueOptional.filter(`[data-child="${child}"]`).val();
      const persistedValue = $(item).val();
      const submit = this.nameTagUserSubmit.filter(`[data-child="${child}"]`);
      let userValue = this.nameTagUserValue.filter(`[data-child="${child}"]`).val();

      if (optionalValue != '') {
        userValue = `${userValue}\t${optionalValue}`;
      }

      // If the field is empty, disable select and consider the name tag as "not changing value".
      if (userValue !== persistedValue && userValue.toString().trim().length != 0) {
        submit.prop('disabled', false);
      } else {
        // Can't submit name tag with no changes
        submit.prop('disabled', true);
      }
    });
  }
}
