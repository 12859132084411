export class FlexTable {
  public breakPoints: Array<string> = [ 'xs','sm','md','lg','xl' ];
  public breakPointsForShow: Array<string>;
  public breakPointsForHide: Array<string>;

  constructor(breakPoint: string) {
    // The breakpoint passed in MUST have the same column definition in the scss file.
    // This mean if you are breaking on "sm" then your column definition needs to look like the below:
    //    &-content {
    //      @include make-cols(md 2);
    //      @include media-breakpoint-down(sm) {
    //        border-bottom: 1px solid colour(border);
    //      }
    //    }

    // Get the breakpoints that we need inorder to break the page up.
    this.breakPointsForShow = this.getBreakpoints(breakPoint, 'down');
    this.breakPointsForHide = this.getBreakpoints(breakPoint, 'up');

    // Get all the resizable flex tables in the page that haven't already been processed
    let tables: any = $('.flex-table.resizable:not(.resizable-completed)');

    // Go through each of the resizable tables and process
    for (let table of tables) {
      this.processTable($(table));
    }
  }

  public processTable(table: any): void {
    // For this to work properly the table can only have one header row
    let header = table.find('.flex-thr').first();
    this.addHeaderClass(header);

    let rows = table.find('.flex-tr:not(.resizable-exclude)');
    this.addHeaderToData(header, rows);

    // Mark the table as processed
    table.addClass('resizable-completed');
  }

  public addHeaderClass(header: any): void {
    // Add the classes for the header row in order for it to be hidden dynamically
    for (let x of this.breakPointsForShow) {
      header.addClass('flex-hide-for-' + x);
    }
  }

  public addHeaderToData(header: any, rows: any): void {
    let span_class = '';

    // Add classes for the breakpoints to show
    for (let x of this.breakPointsForShow) {
      span_class += ' flex-show-for-' + x;
    }

    // Add classes for the breakpoints to hide
    for (let x of this.breakPointsForHide) {
      span_class += ' flex-hide-for-' + x;
    }

    // Get the columns which are the children of the header row
    let columns = Array.prototype.slice.call(header.children());

    // Go through each of the columns with their index (index is used to identify the row column)
    columns.forEach((column: any, index: any) => {
      // Generate the span to be added to the row
      let span = `<span class="${span_class} flex-float-left flex-inline-header"><b>${column.innerHTML}:</b></span>`;

      // Go through each row and add the span to the requried column
      for (let row of rows) {
        $(row.children[index]).prepend(span);
      }
    });
  }

  public getBreakpoints(currentBreakPoint: string, direction: string): Array<string> {
    // Get the index of the supplied breakpoint
    let index = this.breakPoints.indexOf(currentBreakPoint.toLowerCase());

    if (direction == 'down') {
      // Get all the breakpoints smaller than the current one (including it)
      return this.breakPoints.splice(0, index + 1);
    } else {
      // Get all the breakpoints larger than the current one
      return this.breakPoints.splice(index + 1, this.breakPoints.length);
    }
  }
}
