import { Injectable } from 'injection-js';
import { Http, Module, State, TurboLinks } from 'shared/core';
import { Actions, Events } from 'shared/state';
import { ReturnActions, ReturnReasons, ReturnSizes, Quantity } from 'webshop/models';
import { FlexTable, Select, TypeAheadList } from 'shared/lib';
import { OptionData } from 'shared/models';
import { filter, takeUntil } from 'rxjs/operators';
import { RoyalMailQrCode } from 'shared/elements';

@Injectable()
export class OnlineReturnsModule extends Module {
  private submitReturnClass = '.submitReturn';
  private returnQuantityClassName = '.returnQuantity';
  private returnReasonsClassName = '.returnReasons';
  private customerCommentsClassName = '.customerComment';
  private returnActionsClassName = '.returnActions';
  private overridePriceClassName = '.overridePrice';
  private shopItemsClassName = '.shopItems';
  private rowClassName = '.returnRow';
  private rowItemClassName = '.returnItemRow';
  private overideInventoryItemClassName = '.overrideInventoryItem';
  private overrideInventoryItemIds = '[id^=overrideInventoryItem]';
  private subSchoolItemId = '#sub_school_item_id';
  private returnOrderItemId = '#return_order_item_id';
  private closeEmailRequestId = '.closeEmailRequest';

  private submitReturn: JQuery = $(this.submitReturnClass);
  private returnQuantity: JQuery = $(this.returnQuantityClassName);
  private returnReasons: JQuery = $(this.returnReasonsClassName);
  private faultyComments: JQuery = $(this.customerCommentsClassName);
  private returnActions: JQuery = $(this.returnActionsClassName);
  private returnNewSize: JQuery = $(this.shopItemsClassName);
  private subSchoolItemIdRequested: JQuery = $(this.subSchoolItemId);
  private returnOrderItem: JQuery = $(this.returnOrderItemId);
  private deleteReturnButton: JQuery = $('.deleteReturn');
  private itemRows: JQuery = $('.returnItemRow');
  private continueConfirm: JQuery = $('.continueConfirm');
  private zeroConfirmed = false;
  private submitted = false;

  private confirmEmailRequest = '.confirm-email-request';
  private requestEmailModal: JQuery = $(this.confirmEmailRequest);
  private disableBackOrders = false;

  constructor(
    private http: Http,
    private state: State,
    private turbolinks: TurboLinks
  ) {
    super();
  }

  public init(): void {
    this.checkAddAdditional();
    this.returnQuantity.on('change', e => this.changeQuantity($(e.target)));
    this.returnReasons.on('change', e => this.changeReason($(e.target)));
    this.faultyComments.on('keyup', e => this.changeCustomerComment($(e.target)));
    this.returnActions.on('change', e => this.changeAction($(e.target)));
    this.returnNewSize.on('change', e => this.changeSize($(e.target)));
    this.deleteReturnButton.on('click', e => this.deleteReturn(e));
    $('body').on('change', this.overrideInventoryItemIds, () => this.validateReturn());
    $('body').on('keyup', '.override_price', () => this.validateReturn());
    $('body').on('click', '.deleteItem', e => this.deleteReturns($(e.target)));
    this.checkReasons();
    this.validateReturn();

    new FlexTable('sm');

    this.submitReturn.on('click', e => this.submitReturnClicked(e));
    this.continueConfirm.on('click', e => this.continueConfirmClicked(e));

    // Disable backorders for TOS items according to TJ.
    this.disableBackOrders = this.requestEmailModal.length > 0;
    if(this.disableBackOrders) {
      $('body').on('click', this.closeEmailRequestId, e => this.resetReturns());
    }

    this.state.actions$.pipe(
      filter(e => e.name === Events.STOCK_REQUEST_SUBMITTED),
      takeUntil(this.turbolinks.visit$)
    ).subscribe(() => this.reloadShopItems());

    new RoyalMailQrCode(this.http, this.state);
  }

  protected submitReturnClicked(event: JQuery.Event): void {
    let hasZero = false;

    this.itemRows.each((index, itemRow) => {
      const reasonCode = this.getSelectedReasonOption($(itemRow)).data('code');
      const action = this.getSelectedActionOption($(itemRow)).data('action');

      if (reasonCode == ReturnReasons.OUT_OF_STOCK && action == 'swap') {
        let override_price = $('.override_price').val();

        if (override_price && Number(override_price) === 0) {
          hasZero = true;
        }
      }
    });

    if (hasZero && !this.zeroConfirmed) {
      event.preventDefault();
      this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'zeroPriceWarning' });
    } else {
      if (!this.submitted) {
        this.submitted = true;
      } else {
        event.preventDefault();
      }
    }
  }

  protected continueConfirmClicked(event: JQuery.Event): void{
    event.preventDefault();
    this.zeroConfirmed = true;
    this.submitReturn.trigger('click');
  }

  private async changeQuantity(select: JQuery): Promise<void> {
    const itemRow = this.getItemRow(select);
    const reasonSelect = this.getReasonSelect($(itemRow));

    if (select.val() && select.val() !== Quantity.DEFAULT) {
      // if item quantity selected
      reasonSelect.show();
      await this.changeReason(reasonSelect);

      // if action is swap reset shop item sizes for quantity selected
      const actionOption = this.getSelectedActionOption(itemRow);
      if (actionOption.data('action') == 'swap') {
        const row = select.parents(this.rowClassName);
        const orderItemId = row.data('order-item-id');
        const quantity = this.getSelectedQuantity(itemRow);
        const reason = this.getSelectedReason(itemRow);
        const action = this.getSelectedAction(itemRow);
        await this.fetchShopItems(itemRow, action, orderItemId, reason, quantity);
      }
    } else {
      reasonSelect.hide();
      reasonSelect.val(null);
      await this.changeReason(reasonSelect);
    }

    this.checkAddAdditional();
    this.validateReturn();
  }

  private async deleteReturns(target: JQuery): Promise<void> {
    let row = this.getItemRow($(target));
    row.find('.delete_item').val('true');
  }

  // Delete entire return (only available if Seraph user is controlling...)
  private async deleteReturn(e) {
    e.preventDefault();
    let button = $(e.target).closest('button');
    let formData = window.App.newFormData();
    formData.append('_method', 'delete');
    let response = await this.http.postFormData<any>(button.data('url'), formData);
    this.turbolinks.visit(response.redirect_to);
  }

  private async changeReason(select: JQuery): Promise<void> {
    const reason = select.val() as string;
    const itemRow = this.getItemRow(select);
    const actionSelect = this.getActionSelect(itemRow);

    actionSelect.find('option').eq(0).attr('selected', 'selected');

    if (reason && reason !== ReturnReasons.DEFAULT) {
      await this.fetchReturnActions(reason, itemRow);
    } else {
      actionSelect.hide();
    }

    this.showHideCustomerActionNotification(itemRow);
    this.showHideOverridePrice(itemRow);
    this.showHideOverrideInventoryItem(itemRow);
    await this.changeAction(actionSelect);
  }

  private changeCustomerComment(input: JQuery): void {
    const comment = input.val() as string;
    const altId = input.attr('id').includes('Small') ? '#customerCommentLarge' : '#customerCommentSmall';
    const itemRow = this.getItemRow(input);
    itemRow.find(altId).val(comment);
    this.validateReturn();
  }

  private async changeAction(select: JQuery): Promise<void> {
    const itemRow = this.getItemRow(select);
    const selectedAction = this.getSelectedOption(select).data('action');

    if (selectedAction === 'swap') {
      const orderItem = this.getOrderItemId(itemRow);
      const action = select.val();
      const reason = this.getSelectedReason(itemRow);
      const quantity = this.getSelectedQuantity(itemRow);
      await this.fetchShopItems(itemRow, action, orderItem, reason, quantity);
      this.validateReturn();
    } else {
      const sizeSelect = this.getSizeSelect(itemRow);
      sizeSelect.hide();
      sizeSelect.find('option').eq(0).attr('selected', 'selected');
      this.validateReturn();
    }

    this.showHideCustomerActionNotification(itemRow);
    this.showHideOverridePrice(itemRow);
    this.showHideOverrideInventoryItem(itemRow);
  }

  private changeSize(select: JQuery): void {
    const selectedSizeOption = this.getSelectedOption(select);
    const itemRow = this.getItemRow(select);
    const id = selectedSizeOption.val();
    const roi_id = select.data('id');
    const quantityInStock = selectedSizeOption.data('quantity-in-stock-for-shop');

    if (this.isBackOrder(selectedSizeOption)) {
      if (this.emailRequested(selectedSizeOption)) {
        this.returnOrderItem.val(roi_id);
        this.resetReturns();
      } else {
        let orderItemId = this.getOrderItemId(itemRow);
        this.showEmailModal(id, roi_id, orderItemId);
      }
    } else if (quantityInStock < this.getSelectedQuantity(itemRow)) {
      select.val(null);
      $('#itemQuantityInStock').html(quantityInStock);
      this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'quantityWarningModal' });
    }

    itemRow.find('.suggested').val(selectedSizeOption.data('suggested'));
    this.showHideOverrideInventoryItem(itemRow);
    this.validateReturn();
  }

  private showEmailModal(id: any, roi_id: any, orderItemId: any): void {
    this.subSchoolItemIdRequested.val(id);
    this.returnOrderItem.val(roi_id);
    this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: `confirmEmailModal_${orderItemId}` });
  }

  private resetReturns(): void {
    const itemRow = $(`#roi_row_${this.returnOrderItem.val()}`);
    let select = itemRow.find(this.shopItemsClassName);
    select.prop('selectedIndex', 0);
    this.validateReturn();
  }

  private validateReturn(): void {
    let disabled = false;

    this.itemRows.each((index, itemRow) => {
      const rowValid = this.validateRow($(itemRow));
      if (!rowValid) {
        disabled = true;
      }
    });

    this.submitReturn.prop('disabled', disabled);
  }

  private async fetchShopItems(row, actionId, orderItemId, reasonId, quantity): Promise<void> {
    let url = this.submitReturn.closest('form').data('itemsUrl');
    const results = await this.http.get<OptionData[]>(url, {
      return_action_id: actionId,
      order_item_id: orderItemId,
      reason_id: reasonId,
      quantity: quantity,
    });

    this.renderShopItems(row, results);
    this.validateReturn();
  }

  private renderShopItems(row, shopItems: OptionData[]): void {
    const select = row.find(this.shopItemsClassName);
    select.show();

    let selectObj = new Select(select);

    if (shopItems.length === 0) {
      selectObj.clearAllOptions();
      let selectedReason = this.getSelectedReasonOption(this.getItemRow(select)).data('code');
      let noSizesText = ReturnSizes[selectedReason];

      if (noSizesText) {
        selectObj.addSimpleOption('', noSizesText);
        return;
      }
    } else {
      selectObj.replaceOptionsWith(shopItems, ReturnSizes.DEFAULT, true);
    }
  }

  private async fetchReturnActions(reasonId: string, row: JQuery): Promise<void> {
    let url = this.submitReturn.closest('form').data('actionsUrl');
    const results = await this.http.get(url, {
      return_reason_id: reasonId,
      cancel_only: $(row).data('cancelOnly'),
      quantity: this.getSelectedQuantity(row),
      order_item_id: this.getOrderItemId(row)
    });

    this.rebuildActionOptions(row, results);
    this.validateReturn();
  }

  private rebuildActionOptions(row: JQuery, actions): void {
    const actionSelect = this.getActionSelect(row);
    const select = new Select(actionSelect);
    let customerMessage = row.find('.customerMessage');
    select.replaceOptionsWith(actions.items, ReturnActions.DEFAULT, true);

    if (actions.items.length === 1) {
      actionSelect.find('option:selected').removeAttr('selected');
      actionSelect.find('option').eq(1).prop('selected', true);
    }

    actionSelect.show();
    customerMessage.text(actions.message);
    customerMessage.toggle(actions.message != null);
  }

  private validateRow(row: JQuery): boolean {
    const selectedReason = this.getSelectedReasonOption(row);
    if (selectedReason.text() === ReturnReasons.DEFAULT) {
      return false;
    }

    const customerComment = <string> this.getCustomerCommentInput(row).val();
    if (selectedReason.data('requiresComment') && customerComment.trim() == '') {
      return false;
    }

    const actionText = this.getSelectedActionOption(row).text();
    if (actionText === ReturnActions.DEFAULT) {
      return false;
    }

    if (actionText === ReturnActions.EXCHANGE_ITEM) {
      const shopItemOption = this.getSelectedSizeOption(row);
      const shopItemValue = shopItemOption.text();
      const overrideInventoryItem = this.getSelectedOption(row.find(this.overrideInventoryItemIds)).text();
      const shopItemSelected = shopItemValue && !(<any>Object).values(ReturnSizes).includes(shopItemValue);

      if (!overrideInventoryItem && !shopItemSelected) {
        return false;
      }

      if (overrideInventoryItem && !shopItemSelected && !row.find('.override_price').val()) {
        return false;
      }

      if (this.isBackOrder(shopItemOption)) {
        return false;
      }
    }

    return true;
  }

  private calcTotalQuantity(row: JQuery): number {
    const selects = $(row).find(this.returnQuantityClassName);
    let result = 0;

    $.each(selects, (index, element) => {
      let select = $(element);
      if (select.val() && select.val() !== Quantity.DEFAULT) {
        result += +select.val();
      }
    });

    return result;
  }

  private checkAddAdditional(): void {
    const rows = $(this.rowClassName);

    $.each(rows, (i, row) => {
      const returnableQty: number = $(row).data('returnable-qty');
      const totalSelectedQty: number = this.calcTotalQuantity($(row));
      const itemRows = $(row).find(this.rowItemClassName);
      let hasUnselectedQuantity = false;

      $.each(itemRows, (i, itemRow) => {
        const select = this.getQuantitySelect($(itemRow));
        if (!select.val() || select.val() === Quantity.DEFAULT) {
          hasUnselectedQuantity = true;
        }
        this.manipulateQuantitySelect(select, returnableQty, totalSelectedQty);
      });

      this.checkAddAdditionalLines($(row), returnableQty, totalSelectedQty, hasUnselectedQuantity);
    });
  }

  private checkAddAdditionalLines(row: JQuery, returnableQty: number, totalSelectedQty: number, hasUnselectedQuantity: boolean): void {
    row.find('.returnAddItem').parent().toggle((totalSelectedQty < returnableQty) && !hasUnselectedQuantity);
  }

  private manipulateQuantitySelect(select: JQuery, returnableQty: number, totalSelectedQty: number): void {
    let selectedQty = 0;
    if (select.val() && select.val() !== Quantity.DEFAULT) {
      selectedQty = parseInt(select.val() as string);
    }
    const maxQty: number = (returnableQty - totalSelectedQty + selectedQty);

    select.find('option').remove();
    Select.for(select).addSimpleOption(null, Quantity.DEFAULT);

    for (let i = 1; i <= maxQty; i++) {
      Select.for(select).addSimpleOption(i, i.toString(), selectedQty == i);
    }
  }

  private getParentRow(element: JQuery) : JQuery {
    return element.parents(this.rowClassName);
  }

  private getItemRow(element: JQuery) : JQuery {
    return element.parents(this.rowItemClassName);
  }

  private getOrderItemId(element: JQuery) : JQuery {
    return this.getParentRow(element).data('order-item-id');
  }

  private getElement(row: JQuery, className: string) : JQuery {
    return row.find(className);
  }

  private getSelectedOption(select: JQuery) : JQuery {
    return select.find('option:selected');
  }

  private getQuantitySelect(row: JQuery) : JQuery {
    return this.getElement(row, this.returnQuantityClassName);
  }

  private getReasonSelect(row: JQuery) : JQuery {
    return this.getElement(row, this.returnReasonsClassName);
  }

  private getCustomerCommentInput(row: JQuery) : JQuery {
    return this.getElement(row, this.customerCommentsClassName);
  }

  private getActionSelect(row: JQuery) : JQuery {
    return this.getElement(row, this.returnActionsClassName);
  }

  private getSizeSelect(row: JQuery) : JQuery {
    return this.getElement(row, this.shopItemsClassName);
  }

  private getSelectedQuantity(row: JQuery) : string {
    return this.getQuantitySelect(row).val() as string;
  }

  private getSelectedReason(row: JQuery) : string {
    return this.getReasonSelect(row).val() as string;
  }

  private getSelectedAction(row: JQuery) : string {
    return this.getActionSelect(row).val() as string;
  }

  private getSelectedReasonOption(row: JQuery) : JQuery {
    return this.getSelectedOption(this.getReasonSelect(row));
  }

  private getSelectedActionOption(row: JQuery) : JQuery {
    return this.getSelectedOption(this.getActionSelect(row));
  }

  private getSelectedSizeOption(row: JQuery) : JQuery {
    return this.getSelectedOption(this.getSizeSelect(row));
  }

  private isBackOrder(option: JQuery) : boolean {
    if (this.disableBackOrders) {
      return option.data('quantity-in-stock-for-shop') == '0';
    }
    return false;
  }

  private emailRequested(option: JQuery) : boolean {
    if (this.disableBackOrders) {
      return option.data('email-requested');
    }

    return false;
  }

  private showHideOverridePrice(row: JQuery) : void {
    const reasonCode = this.getSelectedReasonOption(row).data('code');
    const action = this.getSelectedActionOption(row).data('action');

    if (reasonCode == ReturnReasons.OUT_OF_STOCK && action == 'swap') {
      row.find(this.overridePriceClassName).show();
    } else {
      row.find(this.overridePriceClassName).hide();
    }
  }

  private showHideOverrideInventoryItem(row: JQuery) : void {
    const reasonCode = this.getSelectedReasonOption(row).data('code');
    const action = this.getSelectedActionOption(row).data('action');
    const shopItemOption = this.getSelectedSizeOption(row).text();

    if (reasonCode == ReturnReasons.OUT_OF_STOCK && action == 'swap' &&
      (<any>Object).values(ReturnSizes).includes(shopItemOption)) {
      row.find(this.overideInventoryItemClassName).show();

      $(this.overrideInventoryItemIds).each( (_, el) => {
        let url = this.submitReturn.closest('form').data('itemsBySkuUrl');
        TypeAheadList.withAjaxSource(jQuery(el), url);
      } );

    } else {
      row.find(this.overideInventoryItemClassName).hide();
    }
  }

  private showHideCustomerActionNotification(row: JQuery) : void {
    const reasonCode = this.getSelectedReasonOption(row).data('code');
    const requiresComment = this.getSelectedReasonOption(row).data('requiresComment');
    const commentLabel = this.getSelectedReasonOption(row).data('label');
    const warning = this.getSelectedReasonOption(row).data('warning');
    const action = this.getSelectedActionOption(row).data('action');

    row.find(`${this.customerCommentsClassName}Label`).html(commentLabel);
    row.find('.customerReplacementWarning').html(warning);

    if (requiresComment) {
      row.find(`${this.customerCommentsClassName}Container`).show();
    } else {
      row.find(`${this.customerCommentsClassName}Container`).hide();
    }

    if (warning) {
      row.find('.customerReplacementWarning').show();
    } else {
      row.find('.customerReplacementWarning').hide();
    }
  }

  private checkReasons() : void {
    const reasons = jQuery(this.returnReasonsClassName);
    reasons.each((i, e) => {
      const select = $(e);
      const itemRow = this.getItemRow(select);
      const quantity = this.getSelectedQuantity(itemRow);
      const action = this.getSelectedActionOption(itemRow);

      this.showHideCustomerActionNotification(itemRow);
      this.showHideOverridePrice(itemRow);
      this.showHideOverrideInventoryItem(itemRow);

      if (quantity == Quantity.DEFAULT) {
        select.hide();
        this.changeReason(select);
      } else if (action.data('action') !== 'swap') {
        this.getSizeSelect(itemRow).hide();
      }
    });
  }

  private async reloadShopItems() : Promise<void>  {
    const itemRow = $(`#roi_row_${this.returnOrderItem.val()}`);
    const orderItem = this.getOrderItemId(itemRow);
    const action = this.getSelectedAction(itemRow);
    const reason = this.getSelectedReason(itemRow);
    const quantity = this.getSelectedQuantity(itemRow);
    await this.fetchShopItems(itemRow, action, orderItem, reason, quantity);
    this.resetReturns();
    this.validateReturn();
  }
}
