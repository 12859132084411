import { DoubleClickProtectionElement } from 'shared/elements';
import { filter } from 'rxjs/operators';
import { Events } from 'shared/state';
import { Http, State } from 'shared/core';

export class RoyalMailQrCode {
  private attempts = 0;
  private maxAttempts = 12;
  private interval = 5000;
  private timer: any;
  private notFound: JQuery<HTMLElement>;
  private container: JQuery<HTMLElement>;
  private qrCode: JQuery<HTMLElement>;
  private attemptMessage: JQuery<HTMLElement>;
  private download: JQuery<HTMLElement>;

  constructor(
    public http: Http,
    public state: State,
  ) {
    this.init();
  }

  public async init(): Promise<void> {
    this.state.actions$.pipe(
      filter((e) => e.name === Events.MODAL_OPENED && e.payload == 'royal_mail_qr_code_modal')
    ).subscribe(() => {
      this.startChecking();
    });

    this.state.actions$.pipe(
      filter((e) => e.name === Events.MODAL_OPENED && e.payload == 'return_complete')
    ).subscribe(() => {
      this.startChecking();
    });

    if ($('#qr_code_container').length == 1) {
      await this.startChecking();
    }
  }

  private async startChecking(): Promise<void> {
    this.container = $('#qr_code_container');

    if (this.container.length == 1 && !this.container.data('found')) {
      this.qrCode = $('#qr_code');
      this.notFound = $('#not_found');
      this.attemptMessage = $('#attempt_message');
      this.download = $('#download_qr_code');

      this.notFound.hide();
      this.container.show();
      this.attempts = 0;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setInterval(() => this.fetch(), this.interval);

      await this.fetch();
    }
  }

  private async fetch(): Promise<void> {
    if (this.timer && this.attempts < this.maxAttempts) {
      this.attemptMessage.html(`<i>Retrieving QR Code.<br/>Attempt ${this.attempts + 1} of ${this.maxAttempts}.<br/>Please wait...</i>`);

      let url = this.qrCode.data('url');
      let response: any = await this.http.get(url);

      if (response.success) {
        this.qrCode.attr('src', response.src);
        this.download.removeClass('hidden');
        this.container.data('found', true);
        this.attemptMessage.hide();
        this.stopTimer();
      }

      this.attempts++;
    } else {
      this.stopTimer();
      let alt_url = this.qrCode.data('alt-url');

      if (alt_url.length > 0) {
        this.notFound.html(`<li class="red"><b><i><a href="${alt_url}" target="_blank">We are unable to retrieve the QR Code from Royal Mail at this time. Please try again later here</a>.</i></b></li>`);
      } else {
        this.notFound.html('<li class="red"><b><i>We are unable to retrieve the QR Code from Royal Mail at this time. Please try again later.</i></b></li>');
      }

      this.notFound.show();
      this.container.hide();
    }
  }

  private stopTimer(): void {
    DoubleClickProtectionElement.enableContainer(this.container);
    clearTimeout(this.timer);
    this.timer = null;
  }
}
