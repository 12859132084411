import 'select2';
import 'select2/dist/css/select2.css';
import { Options, QueryOptions } from 'select2';
import { State } from 'shared/core';
import { Events } from 'shared/state';

export class TypeAheadList {
  public processAjaxResults: (data: {id, text}[], params?: QueryOptions) => {id, text}[];
  public onSelect: (data: {id, text, data?}, element?: any) => void;
  private state: State;

  public constructor(public element: JQuery) {
    this.state = window.App.getProvider(State);
  }

  public initWithAjaxSource(data_url: string, withClear = false, allowHtml = false) : void {
    let options: Options = {
      minimumInputLength: 3,          // Don't search prematurely
      selectOnClose: true,            // Always make a selection when dropdown is closed
      width: '100%',
      ajax: {
        delay: 250,                   // Wait a bit before searching - otherwise we're getting loads of unnecessary searches
        url: data_url,                // Search URL - don't need to provide anything else as long as the response is: { results: [{ id:, text: }]}
        processResults: (data: any, params: QueryOptions) => {
          if (typeof this.processAjaxResults === typeof Function) {
            data.results = this.processAjaxResults(data.results, params);
          }
          this.state.dispatch({ name: Events.TYPE_AHEAD_DATA_LOADED, payload: data.results });
          return data;
        }
      },
      templateResult: this.formatOption,
    };

    if (allowHtml) {
      options = { ...options, ...{templateResult: d => $(d.text), templateSelection: d => $(d.text)} };
    }

    if (this.element.is(':visible')) {
      if (withClear) {
        let clearButton = $('<a href="#" class="right">Clear selection</a>');
        clearButton.on('click', event => {
          event.preventDefault();
          this.element.val('').trigger('change');
        });

        // Try find label
        let id = this.element.attr('id');
        let label = $(`label[for=${id}]`);

        if (label.length === 1) {
          clearButton.appendTo(label);
        } else {
          // No label so insert after element
          clearButton.insertAfter(this.element);
        }
      }

      // Select2 doesn't like initialising if it's invisible...?
      this.element.select2(options);

      this.element.on('select2:select', event => {
        if (typeof this.onSelect == typeof Function) {
          this.onSelect(event.params.data, this.element);
        }

        this.element[0].dispatchEvent(new Event('change'));
      });
    }

    // Set a variable to indicate a multi-select option has been deselected
    let deselecting = false;

    // Set variable to true when an option has been deselected
    this.element.on(
      'select2:unselecting',
      () => {
        deselecting = true;
      }
    );

    // Prevent menu from opening if something has been deselected and reset variable, otherwise open the menu
    this.element.on(
      'select2:opening',
      () => {
        if (deselecting) {
          deselecting = false;
          return false;
        } else {
          return true;
        }
      }
    );
  }

  public formatOption (option): JQuery {
    if (!option.id || !(option['data'] && option['data']['image_url'])) {
      return option.text;
    }

    let $option = $(`<span><img src="${option['data']['image_small_url']}" />${option.text}</span>`);

    return $option;
  };

  public initWithNoSource(): void {
    const options: Options = {
      placeholder: this.element.attr('placeholder'),
      minimumInputLength: 0,
      selectOnClose: true,
      width: '100%',
    };

    // Set a variable to indicate a multi-select option has been deselected
    let deselecting = false;

    // Set variable to true when an option has been deselected
    this.element.on(
      'select2:unselecting',
      () => {
        deselecting = true;
      }
    );

    // Prevent menu from opening if something has been deselected and reset variable, otherwise open the menu
    this.element.on(
      'select2:opening',
      () => {
        if (deselecting) {
          deselecting = false;
          return false;
        } else {
          return true;
        }
      }
    );

    if (this.element.is(':visible')) {
      // Select2 doesn't like initialising if it's invisible...?
      this.element.select2(options);

      this.element.on('select2:select', event => {
        if (typeof this.onSelect == typeof Function) {
          this.onSelect(event.params.data);
        }

        this.element[0].dispatchEvent(new Event('change'));
      });
    }
  }

  public static withAjaxSource(element: JQuery, data_url: string, withClear = false, allowHtml = false): TypeAheadList {
    let typeAheadList = new TypeAheadList(element);
    typeAheadList.initWithAjaxSource(data_url, withClear, allowHtml);
    return typeAheadList;
  }

  public static withNoSource(element: JQuery): TypeAheadList {
    let typeAheadList = new TypeAheadList(element);

    element.find('option').each((i, v:HTMLOptionElement) => {
      if (v.innerHTML == '')
        v.innerHTML = '&nbsp;';

      if (v.value == '' && !element.hasClass('required'))
        v.innerText = 'None';
    });

    typeAheadList.initWithNoSource();
    return typeAheadList;
  }

  public static forElement(element: JQuery): TypeAheadList {
    TypeAheadList.cleanContainer(element);
    if (typeof element.data('url') != typeof undefined) {
      return this.withAjaxSource(element, element.data('url'), !!element.data('allowClear'), !!element.data('allowHtml'));
    } else {
      return this.withNoSource(element);
    }
  }

  public static cleanContainer(element: JQuery): void {
    element.find('.select2-container').remove();
    let id = element.attr('id');
    if (id) {
      let label = $(`label[for=${id}]`);
      label.find('a').remove();
    }
  }

  public close (): void {
    this.element.select2('close');
  }

  public open () : void {
    this.element.select2('open')
  }
}
