import { Injectable } from 'injection-js';
import { EmailValidationResponse, EmailValidation, CardDetails, CardValidation } from 'webshop/models';
import { Config } from 'webshop/config';
import { Checksum, Http } from 'shared/core';

@Injectable()
export class Validators {
  constructor(
    private http: Http,
  ) {}

  public async email(email: string): Promise<EmailValidation> {
    const response: EmailValidationResponse = await this.http.postAsString<EmailValidationResponse>(Config.emailValidation.apiUrl, {
      EmailAddress: email,
      APIKey: Config.emailValidation.apiKey,
    });

    let result_code = 0;
    let result = 'retry';
    let message = '';

    if (typeof(response.data.status) !== typeof undefined) {
      result_code = response.data.status;

      if (typeof(response.data.info) !== typeof undefined) {
        message = response.data.info;
      }

      if (result_code >= 200 && result_code < 300) {
        result = 'valid_email';
      } else if (result_code >= 400) {
        result = 'invalid_email';
      }
    }

    return { result: result, message: message };
  }

  // Validates credit card details
  // - note that the CardValidation type has an array of messages
  public static creditCard(cardDetails: CardDetails): CardValidation {
    let messages: string[] = [];
    let result = true;

    // Valid cardholder name
    if (!cardDetails.name || cardDetails.name.length == 0) {
      messages.push("Please enter the cardholder's name.");
      result = false;
    } else {
      if (cardDetails.name.length > 50) {
        result = false;
        messages.push("Cardholder's name must be 50 characters or less.");
      }

      const regexp = new RegExp(/^[a-zA-Z0-9\s]*$/);

      if (regexp.test(cardDetails.name) != true) {
        messages.push("Cardholder's name must contain numbers, letters and spaces only.");
        result = false;
      }

      if (cardDetails.name.replace(/[^A-Z]/gi, '').length == 0) {
        messages.push("Cardholder's name must contain at least one letter.");
        result = false;
      }
    }
    // Valid card number
    if (!Checksum.luhnChecksum(cardDetails.card_number)) {
      messages.push('Please enter a valid card number.');
      result = false;
    }

    // Valid expiry date
    const today = new Date();

    if (!cardDetails.expiry_month || !cardDetails.expiry_year) {
      messages.push('You must give an expiry date.');
      result = false;
    } else {
      const expiry_date = new Date(cardDetails.expiry_year, cardDetails.expiry_month, 0);

      if ( expiry_date <= today) {
        messages.push('Expiry date cannot be in the past.');
        result = false;
      }

      const month = today.getUTCMonth() + 1; //months from 1-12
      const year = today.getUTCFullYear();
      const endOfMonth = new Date(today.getTime());
      endOfMonth.setMonth(month);
      endOfMonth.setDate(0);
      const daysTillExpired = endOfMonth.getDate() > today.getDate() ? endOfMonth.getDate() - today.getDate() : 0;

      if (cardDetails.expiry_year == year && cardDetails.expiry_month == month && daysTillExpired < 14) {
        messages.push('Payment card has to be valid for at least 2 weeks - please try with another payment card');
        result = false;
      }
    }

    // Valid start date - only if supplied
    if (cardDetails.valid_from_month && cardDetails.valid_from_year) {
      const start_date = new Date(cardDetails.valid_from_year, (cardDetails.valid_from_month - 1), 1);

      if ( start_date > today) {
        messages.push('Start date cannot be in the future.');
        result = false;
      }
    }

    // Valid CSC
    if (!cardDetails.csc || cardDetails.csc.length != 3) {
      messages.push('You must specify a valid Card Security Code');
      result = false;
    }

    // Valid issue number
    const issueRegexp = new RegExp(/^[0-9]*$/);

    if (issueRegexp.test(cardDetails.issue_number.toString()) != true) {
      messages.push('Issue number can only contain digits');
      result = false;
    } else if (cardDetails.issue_number > 99) {
      messages.push('Issue number can be a maximum of 2 digits');
      result = false;
    }

    // Terms and conditions
    if (!cardDetails.terms_agreed) {
      messages.push('You must agree to our terms and conditions');
      result = false;
    }

    return { result: result, messages: messages };
  }
}
