import { Element } from 'shared/core';
import { Injectable } from 'injection-js';

@Injectable()
export class BreadcrumbsElement extends Element {
  public steps: JQuery = $('.checkoutBreadcrumbsItem');

  public init(): void {
    if (this.steps.length > 0) {
      this.markActive();
    }
  }

  private markActive(): void {
    let path = window.location.pathname.split('/').pop();

    if (window.location.search === '?finished=true') {
      path = 'confirm';
    }

    let active = $(`*[data-pages~="${path}"]`);

    const activeIndex = active.index();
    active.addClass('active');

    this.steps.each((index, element) => {
      if (index < activeIndex) {
        $(element).addClass('completed');
      }
    });
  }
}
