import { Flash } from 'shared/lib';
import { Http } from 'shared/core';
import { Actions } from 'shared/state';
import { AjaxFormErrorCode } from 'shared/misc/ajax_form_error_codes';
import SubmitEvent = JQuery.SubmitEvent;

/**
 * Ajax Forms!
 *
 * Handles double-click prevention, exposes callbacks, flashes errors and fully async ready!
 *
 * Callback event order:
 * * beforeSubmit - before the form it submitted
 * * beforeRequest - before the request is made, allowing modification of FormData
 * * submitted - after the request successful. The form param will be undefined if there is no form.
 * * failed - after the request has failed.  The form param will be undefined if there is no form.
 */
export class AjaxForm {
  /**
   * Flash errors from a JSON AJAX requests are displayed at the top of the form by default.  Setting this to true
   * will result in flash errors being displayed at the top of the page.  Useful for forms in modals, where the modal
   * closes even when there was an error.
   */
  public loadFlashToPage = false;

  /**
   * Check the HTML5 form validity before submitting the form.  On failure, does not result in any callbacks or error
   * conditions, simply prevents the form submitting.
   */
  public checkFormValidity = true;

  /**
   * Called before submission.  If this is set and returns false, the submit will be cancelled.  If this is false,
   * neither submitted or failed will be called.
   *
   * Throw an exception or return false to cancel the request.
   *
   * You are required to handle Flash/Toast alerts.
   */
  public beforeSubmit?: (form?: JQuery<HTMLFormElement>) => boolean | Promise<boolean>;

  /**
   * Called just before the request is made to allow modification to the FormData.
   *
   * Throw an exception to cancel the request.  If an exception is raised, the page is re-enabled.  You are required
   * to handle Flash/Toast alerts.
   */
  public beforeRequest?: (formData: FormData) => FormData | Promise<FormData>;

  /**
   * Called when the form submit fails, either due to a server-side error (anything other than 200-OK or redirect),
   * network issue or when there is a flash alert and flashAlertsAsFailures is true.  Exceptions are not caught and
   * will keep containers disabled if they occur.
   */
  public failed?: (reasonCode: AjaxFormErrorCode, form?: JQuery<HTMLFormElement>, requestResponse?: any, exception?: any) => void | Promise<void>;

  /**
   * The submitted method is called, if set, after any successful AJAX form submission
   * performed through this class.  Exception are not caught and will keep containers disabled if they occur.
   */
  public submitted?: (responseData: any, form?: JQuery<HTMLFormElement>) => void | Promise<void>;

  /**
   * Handle flash alerts as failures.  Rather than assuming a "success", any alert will call the failed callback before
   * re-enabling containers.
   */
  public flashAlertsAsFailures: boolean = true;

  /**
   * Accessible by callbacks, set when an event is fired from the createSpecific and createGeneral methods.
   */
  public submitEvent?: SubmitEvent;

  /**
   * Disable containers during submission
   */
  public disableContainers: boolean = true;

  /**
   * Used internally.  When used externally, no event binding takes place.
   *
   * @param submitted {Function}
   * @param beforeSubmit {Function} If set and returns false, prevents saving and further callback execution
   * @param failed {Function}
   */
  constructor(
    submitted?: (responseData: any, form?: JQuery<HTMLFormElement>) => void|Promise<void>,
    beforeSubmit?: (form?: JQuery<HTMLFormElement>) => boolean|Promise<boolean>,
    failed?: (reasonCode: AjaxFormErrorCode, form?: JQuery<HTMLFormElement>) => void|Promise<void>
  ) {
    this.failed = failed;
    this.beforeSubmit = beforeSubmit;
    this.submitted = submitted;
  }

  /**
   * Create an ajax form and binds a submit handler to a specific JQuery object.  Accepts a JQuery object with any
   * number of forms, but the JQuery object must be of a Form Element(s).
   *
   * If at all possible, it is better to use createGeneral and use a selector.  This prevents the need
   * to rebind if the page changes.
   *
   * @param form {JQuery<HTMLFormElement>}
   * @param submitted {Function} optional
   * @param beforeSubmit {Function} optional. If set and returns false, prevents saving and submitted callback
   */
  public static createSpecific(
    form: JQuery<HTMLFormElement>,
    submitted?: (responseData: any) => void|Promise<void>,
    beforeSubmit?: (form?: JQuery<HTMLFormElement>) => boolean|Promise<boolean>
  ): AjaxForm {
    let ajaxForm = new AjaxForm(submitted, beforeSubmit);

    // Bind to specific element(s) using the JQuery object
    form.off('submit').on('submit', async event => {
      event.preventDefault();
      ajaxForm.submitEvent = event;
      await ajaxForm.submitForm($(event.target).closest('form') as JQuery<HTMLFormElement>, true);
    });

    return ajaxForm;
  }

  /**
   * Create an ajax form using a selector that doesn't need to be rebound when the page elements are changed.  Accepts
   * a selector that will pull back any number of HTMLFormElements.  The selector should be of a form  for this to
   * work correctly.
   *
   * This is the recommended usage.
   *
   * @param selector {string} The selector for the form(s)
   * @param submitted {Function} optional
   * @param beforeSubmit {Function} optional. If set and returns false, prevents saving and submitted callback
   */
  public static createGeneral(
    selector: string,
    submitted?: (responseData: any, form?: JQuery<HTMLFormElement>) => void|Promise<void>,
    beforeSubmit?: (form?: JQuery<HTMLFormElement>) => boolean|Promise<boolean>
  ): AjaxForm {
    let ajaxForm = new AjaxForm(submitted, beforeSubmit);

    // Bind to body using the selector
    $('body').on('submit', selector, async event => {
      event.preventDefault();
      ajaxForm.submitEvent = event;
      let form = $(event.target).closest('form') as JQuery<HTMLFormElement>;
      await ajaxForm.submitForm(form, true);
    });

    return ajaxForm;
  }

  /**
   * Helper to create an "anonymous" AjaxForm object, with no event bindings.  Designed for screens that need to
   * submit FormData without a form.
   *
   * Example Usage:
   * ```
   *   let myAjaxForm = AjaxForm.createAnonymous(formData => { myMethod });
   *   myAjaxForm.failed = () => { ... };
   *
   *   $('body').on('click', 'a.myLink', event => {
   *     event.preventDefault();
   *     myAjaxForm.submitAnonymous(url, method);
   *   }
   * ```
   *
   * Using an anonymous AjaxForm, the form data is modified to include the necessary CSRF tokens.
   *
   * @param beforeRequest {Function} - inject and modify form data
   * @return AjaxForm
   */
  public static createAnonymous(
    beforeRequest?: (FormData) => FormData
  ): AjaxForm {
    let ajaxForm = new AjaxForm();

    ajaxForm.loadFlashToPage = true;

    ajaxForm.beforeRequest = (formData: FormData) => {
      let param_name = $('meta[name="csrf-param"]').attr('content');
      let auth_token = $('meta[name="csrf-token"]').attr('content');

      formData.append(param_name, auth_token);

      if (typeof beforeRequest != typeof undefined && beforeRequest != null) {
        formData = beforeRequest(formData);
      }

      return formData;
    };

    return ajaxForm;
  }

  /**
   * Submits the form via AJAX (does not trigger the form submit event). Used internally after the submit event is
   * triggered.
   *
   * If the AjaxForm object has the submitted property set, this will call the submitted property before enabling
   * the container.  By default, if the catchErrors param is not defined then any error will only be handled IF
   * there is a "failed" callback defined.
   *
   * Form is submitted using FormData, so all inputs including the hidden _method are sent.  Cannot currently
   * handle file uploads!
   *
   * @see submit
   *
   * @param form {JQuery<HTMLFormElement>} The SINGLE form to submit
   * @param catchErrors All exceptions will be rethown if this is false, for edge-case scenarios
   */
  public async submitForm(form: JQuery<HTMLFormElement>, catchErrors?: boolean): Promise<any> {
    if (form.length > 1) {
      // Can't submit more than one form
      throw 'Cannot submit multiple forms at one';
    } else if (form.length == 0) {
      // Do nothing if there is no form to submit
      return;
    }

    // By default, if CatchErrors is undefined, only catch errors IF a failed callback is
    // defined, otherwise expect the caller of submitForm to handle the thrown errors.
    if (catchErrors == undefined) {
      catchErrors = typeof this.failed === typeof Function;
    }

    if (this.checkFormValidity) {
      // Check the HTML5 form validation, providing the browser supports it.  If the form is not valid, then assume
      // that the browser is handling the form validation.
      if (typeof form[0].checkValidity === typeof Function && !form[0].checkValidity()) {
        if (typeof form[0].reportValidity === typeof Function) {
          form[0].reportValidity();
        }

        if (typeof this.failed === typeof Function) {
          this.failed(AjaxFormErrorCode.ValidationFailure, form);
        }

        return;
      }
    }

    // Get the relevant details from the form
    let url = form.attr('action');
    let method = form.attr('method');
    let formData = new FormData(form[0]);

    // Submit and return the response
    return await this.submit(url, method, formData, form, catchErrors);
  }

  /**
   * Submit an "anonymous" form.  Allows for highly customisable interactions with form data and dynamic data
   * submissions.
   *
   * Will always throw errors on failures, even if there is a failed callback defined.
   *
   * @param url {string}
   * @param method {string}
   */
  public async submitAnonymous(url: string, method: string): Promise<any> {
    let formData = new FormData();
    return await this.submit(url, method, formData, undefined, true);
  }

  /**
   * Internal implementation called by anonymous and form submissions.
   *
   * @param url
   * @param method
   * @param formData
   * @param form
   * @param catchErrors
   * @private
   */
  private async submit(url: string, method: string, formData: FormData, form?: JQuery<HTMLFormElement>, catchErrors?: boolean): Promise<any> {
    let response = null;
    let containerToDisable = form || $('body');

    if (this.disableContainers) {
      // Disable the container before doing anymore processing in attempt to prevent double-clicks
      window.App.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: containerToDisable });
    }

    if (typeof this.beforeSubmit === typeof Function) {
      // If this.beforeSubmit is a Function and it returns false or throws an exception, the container is re-enabled
      // and the submission cancelled.
      try {
        if (!await this.beforeSubmit(form)) {
          if (this.disableContainers) {
            window.App.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: containerToDisable });
          }

          return;
        }
      } catch(e) {
        if (this.disableContainers) {
          window.App.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: containerToDisable });
        }

        if (typeof this.failed === typeof Function) {
          this.failed(AjaxFormErrorCode.GeneralFailure, form, undefined, e);
        }

        return;
      }
    }

    // If this.beforeRequest is a Function it returns a FormData or throws an exception.  In the event an exception
    // is thrown, the container is re-enabled and the submission cancelled.
    try {
      if (typeof this.beforeRequest === typeof Function) {
        formData = await this.beforeRequest(formData);
      }
    } catch(e) {
      if (this.disableContainers) {
        window.App.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: containerToDisable });
      }

      if (typeof this.failed === typeof Function) {
        this.failed(AjaxFormErrorCode.GeneralFailure, form, undefined, e);
      }

      return;
    }

    // The request is made using the Http provider. Exception, such as server-side errors or connection errors,
    // are caught and handled before.
    try {
      response = await window.App.getProvider(Http).request(method, url, formData);
    } catch(e) {
      // The AJAX request has failed due to either server-side issue or connection issue, in both cases
      // this results in an UnhandledPromiseRejectionWarning.
      if (typeof this.failed === typeof Function) {
        await this.failed(AjaxFormErrorCode.HttpFailure, form, undefined, e);
      }

      if (this.disableContainers) {
        // Only re-enable after failed is called and complete
        window.App.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: containerToDisable });
      }

      // If catchErrors is false, we throw the error out.  This is for when submitForm is called directly.
      // The Http class handles displaying errors to the user, so this does not need to happen here.
      if (!catchErrors) {
        throw e;
      }

      return;
    }

    // Handle user errors, such as validation messages.  It is assumed that there is a JSON result.  In the event this
    // is not correct, errors from Flash are silently ignored.
    let request_state = { notices: false, alerts: false, stay_alerts: false };

    try {
      if (this.loadFlashToPage || form == undefined) {
        request_state = Flash.fromAjaxRequest(response);
      } else {
        request_state = Flash.fromAjaxRequestForForm(response, form);
      }
    } catch(e) {
      // The response may not be JSON, so always catch errors silently.
    }

    // Check if the page requires a reload.  In this case, we halt here without re-enabling containers and reload.
    try {
      if(response.requireReload) {
        window.location.reload();
        return;
      }
    } catch(e) {
      // The response may not be JSON, so always catch errors silently.
    }

    // Check if the page requires navigation elsewhere.  In this case, we halt here and navigate without re-enabling
    // containers. Expects a relative path.
    try {
      if(response.forceNavigationTo) {
        window.location.pathname = response.forceNavigationTo;
        return;
      }
    } catch(e) {
      // The response may not be JSON, so always catch errors silently.
    }

    // If we handle alerts as failures, any alerts are considered failures.
    if (this.flashAlertsAsFailures && (request_state.alerts || request_state.stay_alerts)) {

      if (typeof this.failed === typeof Function) {
        await this.failed(AjaxFormErrorCode.ResponseFailure, form, request_state);
      }

      if (this.disableContainers) {
        window.App.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: containerToDisable });
      }

      if (!catchErrors) {
        throw new Error('An unhandled error occurred');
      }
    } else {
      // This is the submitted callback
      if (typeof this.submitted === typeof Function) {
        await this.submitted(response, form);
      }
    }

    if (this.disableContainers) {
      window.App.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: containerToDisable });
    }

    return response;
  }
}
