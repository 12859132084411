import { Injectable } from 'injection-js';
import { Forms, Module, State } from 'shared/core';
import { Validators } from 'webshop/core';
import { EmailValidation } from 'webshop/models';
import { PasswordStrengthMeter } from 'shared/lib';
import { Actions } from 'shared/state';

@Injectable()
export class AccountModule extends Module {
  /* Create Fields */
  private accountUpsertEmailValidation: JQuery = $('#account-upsert-email-validation');
  private accountUpsertEmailValidationMessage: JQuery = $('#account-upsert-email-validation_message');
  private accountUpsertEmail: JQuery = $('#account-upsert-email');

  /* Close Fields */
  private accountCloseUnlock: JQuery = $('#account-close-unlock');
  private accountCloseSubmit: JQuery = $('#account-close-submit');

  private signUpSelect: JQuery = $('#parent_subscribed');
  private signUpButton: JQuery = $('#newsletterSignUp');

  constructor(
    private validators: Validators,
    private state: State,
  ) {
    super();
  }

  public init(): void {
    this.accountUpsertEmail.on('blur', (event: JQuery.TriggeredEvent) => this.validateEmail(event.target.value));
    this.accountCloseUnlock.on('change', () => this.toggleClose());
    this.signUpSelect.on('change', () => this.showCommsWarning());
    this.signUpButton.on('click', () => this.newsletterSignUp());
    new PasswordStrengthMeter('.password_strength');
  }

  private toggleClose(): void {
    Forms.toggleDisable(this.accountCloseSubmit, !this.accountCloseUnlock.is(':checked'));
  }

  private showCommsWarning(): void {
    if (this.signUpSelect.val() === 'false') {
      this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'comms_warning_modal' });
    }
  }

  private async validateEmail(email: string): Promise<void> {
    const result: EmailValidation = await this.validators.email(email);
    this.accountUpsertEmailValidation.val(result.result);
    this.accountUpsertEmailValidationMessage.val(result.message);
  }

  /**
   * Sets the newsletter sign up to true
   * @private
   */
  private newsletterSignUp(): void {
    this.signUpSelect.val('true');
  }
}

