import { filter } from 'rxjs/operators';
import { Datepicker, FlexTable } from 'shared/lib';
import { Events } from 'shared/state';
import { Http, State } from 'shared/core';

export interface Position {
  lat: number;
  lng: number;
}

export interface UserPoint {
  position: Position;
  label: string;
  address: string;
  countryCode: string;
  countryName: string;
}

export interface ReturnCountry {
  country_code: string;
  returns_allowed: boolean;
  effective_country_code: string;
}

const BASE_COORDINATES = {
  lat: 51.509865,
  lng: -0.118092
};

export class DpdReturns  {
  private confirmReturnOpen: JQuery = $('.confirmReturnOpen');
  private contactName: JQuery = $('#return_order_delivery_contact_name');
  private contactNumber: JQuery = $('#return_order_delivery_contact_number');
  private userPoint: UserPoint;
  private currentPoint: Position;
  private returnCountry: ReturnCountry;

  private geocoder: google.maps.Geocoder;
  private map: any;

  private pickupMarkers = [];
  private pickupPoints = [];
  private selectedPickupPoint = null;

  constructor(
    private state: State,
    private http: Http,
  ) {
    this.init();
  }

  public init(): void {
    this.state.actions$.pipe(
      filter(action => action.name === Events.GOOGLE_MAP_LOADED)
    ).subscribe(action => this.initMap(action.payload));

    this.contactName.on('input', () => this.checkValidity());
    this.contactNumber.on('input', () => this.checkValidity());

    $('body').on('click', '[data-point]', e => this.setDPDPoint($(e.target)));

    $('#date').on('change', () => this.checkValidity());

    if (jQuery('.datepickable').length > 0) {
      new Datepicker('.datepickable', { startDate: new Date() });
    }

    if ($('#dpdPoints').length > 0) {
      this.state.dispatch({ name: Events.RETURN_CONFIRM_VALIDATED, payload: false });
    }

    if (jQuery('#map').length == 1) {
      this.loadGoogleMaps();
    }
  }

  private checkValidity(): void {
    const date = ($('#date').val() as string);
    let name_valid = true;
    let number_valid = true;

    if (this.contactName.length > 0) {
      name_valid = this.contactName.val().toString().trim().length > 0;
    }

    if (this.contactNumber.length > 0) {
      const provided_number = this.contactNumber.val().toString().trim();

      if (provided_number.length == 0) {
        number_valid = false;
      } else {
        const regexp = new RegExp(/^[- +()0-9]{4}[- +()0-9]*$/);
        number_valid = regexp.test(provided_number);
      }
    }

    if (!name_valid) {
      this.contactName.addClass('error');
    } else {
      this.contactName.removeClass('error');
    }

    if (!number_valid) {
      this.contactNumber.addClass('error');
    } else {
      this.contactNumber.removeClass('error');
    }

    this.state.dispatch({ name: Events.RETURN_CONFIRM_VALIDATED, payload: this.selectedPickupPoint !== undefined && date.length > 0 && name_valid && number_valid });
  }

  private renderDPDPoints(points): void {
    // Reset points in DOM
    $('#dpdPoints').html('');
    // Apply new results
    $(points).each(function (index, item) {
      const name = item.name || '';
      $(
        '<div class="dpdPoint dpd-point">'
        + '<h3>' + name + '</h3>'
        + '<p>' + item.address.street + ', '
        + item.address.postcode + ' ' + item.address.town + '</p>'
        + '<a class="button-default button-select" data-point="' + index + '">Select</a>' +
        '</div>'
      ).prependTo('#dpdPoints');
    });
  }

  private selectPickupPoint(index: number): void {
    this.selectedPickupPoint = this.pickupPoints[index];
    $('#country_code').val(this.selectedPickupPoint.address.countryCode);
    $('#address').val(JSON.stringify(this.selectedPickupPoint.address));
  }

  private setDPDPoint(point: JQuery): void {
    const index = point.attr('data-point');
    $('.dpdPoint').removeClass('active');
    point.parents('.dpdPoint').addClass('active');
    this.selectPickupPoint(+index);
    this.checkValidity();
  }

  private async loadGoogleMaps(): Promise<void> {
    let response: any = await this.http.get<any>('dpd/maps_api_key');
    await $.getScript(`https://maps.googleapis.com/maps/api/js?key=${response.key}&callback=initMap`);
  }

  private initMap(initialData): void {
    this.geocoder = new window.google.maps.Geocoder();
    this.map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 10
    });

    this.userPoint = {
      label: 'Your location',
      position: null,
      ...initialData,
    };

    this.decodeAddress();

    // Load New Pickup Points On DragEnd
    this.map.addListener('dragend', () => {
      this.currentPoint = {
        lat: this.map.center.lat(),
        lng: this.map.center.lng()
      };
      this.getDPDPoints();
    });
  }

  private decodeAddress(): void {
    this.geocoder.geocode({ 'address': this.userPoint.address }, (results, status) => {
      if (status === 'OK') {
        this.currentPoint = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        };

        this.geoCodeCallback();
      } else {
        $('#error').html('We could not process your address. Please find your pickup point.');

        this.geocoder.geocode({ 'address': this.userPoint.countryName }, (innerResult, innerStatus) => {
          if (innerStatus === 'OK') {
            this.currentPoint = {
              lat: innerResult[0].geometry.location.lat(),
              lng: innerResult[0].geometry.location.lng()
            };
          } else {
            this.currentPoint = BASE_COORDINATES;
          }

          this.geoCodeCallback();
        });
      }
    });
  }

  private geoCodeCallback(): void {
    this.userPoint.position = this.currentPoint;
    this.map.setCenter(this.userPoint.position);
    this.addMarker(this.userPoint, false);
    this.getDPDPoints();
  }

  private async getDPDPoints(): Promise<void> {
    let url = $('.returns-confirm').data('checkReturnCountryUrl');
    this.returnCountry = await this.http.get<ReturnCountry>(url, {
      country_code: this.userPoint.countryCode
    });

    const results: any[] = await this.http.get('dpd/get_pickup_locations', {
      latitude: this.currentPoint.lat,
      longitude: this.currentPoint.lng,
      country_code: this.returnCountry.effective_country_code,
      radius: 10,
    });

    if (!results) {
      return;
    }

    this.pickupPoints = [];

    results.forEach(result => {
      this.pickupPoints.push(result);
    });

    if (this.pickupPoints.length > 0) {
      this.addDPDMarkers(this.pickupPoints);
      this.renderDPDPoints(this.pickupPoints);
    }
  }

  private addMarker(point, add: boolean): void {
    const marker = new google.maps.Marker({
      map: this.map,
      position: point.position,
      label: point.label
    });

    if (add === true) {
      this.pickupMarkers.push(marker);
    }
  }

  private addDPDMarkers(points): void {
    // Clean up old markers
    this.pickupMarkers.forEach(i => i.setMap(null));

    // Add new markers
    points.forEach(point => {
      point.position = {
        lat: point.latitude,
        lng: point.longitude
      };

      this.addMarker(point, true);
    });
  }
}
