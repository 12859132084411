import { Injectable } from 'injection-js';
import { Element } from 'shared/core';
import { Toasts } from 'shared/lib';

@Injectable()
export class CopyToClipboardElement extends Element {
  constructor() {
    super();
  }

  public init(): void {
    $('body').on('click', '.copy-to-clipboard', event => this.copyToClipboard(event));
  }

  private async copyToClipboard(event: JQuery.ClickEvent): Promise<void> {
    event.preventDefault();
    let copyText = document.createElement('textarea');
    copyText.value = $(event.target).closest('.copy-to-clipboard').data('value');
    document.body.appendChild(copyText);
    copyText.select();
    document.execCommand('copy');
    document.body.removeChild(copyText);
    Toasts.addToast('Copied to Clipboard', 'notice');
  }
}
