import { Injectable } from 'injection-js';
import { Module } from 'shared/core';
import { AjaxForm } from 'shared/lib';
import { Actions } from 'shared/state';
import ClickEvent = JQuery.ClickEvent;

@Injectable()
export class NewsModule extends Module {

  public init(): void {
    AjaxForm.createGeneral(`.new_dismissed_news_item`, (response, form) => {
      if(response.success) {
        form.closest('.news-item').remove();
        $('.dismissed-news').removeClass('hidden');
        $('.dismissed-news-items').prepend(response.html);
        $('#show-dismissed').text(response.button_text);
        window.App.state.dispatch({ name: Actions.STACK_BUTTONS, payload: $('.buttons') });
      }
    });

    $('#show-dismissed').on('click', (e:ClickEvent) => {
      e.preventDefault();
      $(e.target).toggleClass('button-expand').toggleClass('button-collapse');
      $('.dismissed-news-items').toggleClass('hidden');
      window.App.state.dispatch({ name: Actions.STACK_BUTTONS, payload: $('.buttons') });
    });
  }

}
