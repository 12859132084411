import { Injectable } from 'injection-js';
import { Element, Display, State, TurboLinks, Http } from 'shared/core';
import { Actions, Events } from 'shared/state';
import { filter } from 'rxjs/operators';
import { Action } from 'shared/models';
import { DoubleClickProtectionElement } from 'shared/elements/double_click_protection';
import { ContainerNode, render as pRender } from 'preact';

@Injectable()
export class ModalElement extends Element {
  public modalsContainer: JQuery = $('#modalsContainer');
  private modalCloseClass = '.closeModal';
  private modalOpenClass = '.openModal';

  constructor(
    private state: State,
    private turbolinks: TurboLinks,
    private display: Display,
    private http: Http
  ) {
    super();

    this.state.actions$.pipe(
      filter(e => e.name === Actions.OPEN_STATIC_MODAL)
    ).subscribe(action => this.openStaticModal(action.payload));

    this.state.actions$.pipe(
      filter(e => e.name === Actions.CLOSE_MODAL)
    ).subscribe(action => this.closeModal(action.payload));

    this.state.actions$.pipe(
      filter(e => e.name === Actions.OPEN_DYNAMIC_MODAL)
    ).subscribe(action => this.openDynamicModal(action.payload['url'], action.payload['after']));

    this.state.actions$.pipe(
      filter(e => e.name == Actions.OPEN_NEW_MODAL)
    ).subscribe(action => this.openModalHtml(action.payload['modal'], action.payload['after']));

    this.state.actions$.pipe(
      filter(e => e.name == Actions.OPEN_NEW_MODAL_FROM_CONTENT)
    ).subscribe(action => this.openNewModalFromHtmlContent(action.payload['id'], action.payload['title'], action.payload['content'], action.payload['sizeClass'], action.payload['after'], action.payload['useVnodes']));

    this.state.actions$.pipe(
      filter(e => e.name === Actions.OPEN_CONFIRMATION_MODAL)
    ).subscribe(action => this.confirmationModalEvent(action));

    this.state.actions$.pipe(
      filter(e => e.name === Actions.OPEN_ALERT_MODAL)
    ).subscribe(action => this.alertModalEvent(action));


    $('body').on('click', this.modalOpenClass, async (e) => {
      e.preventDefault();
      // e.stopImmediatePropagation();
      let clicked = $(e.target).closest(this.modalOpenClass);

      if (clicked.hasClass('disabled')) {
        return;
      }

      DoubleClickProtectionElement.disableContainer($('body'));

      let modalUrl = clicked.data('ajax');

      if (modalUrl) {
        await this.openDynamicModal(modalUrl);
      } else {
        this.openStaticModal($(e.target).closest(this.modalOpenClass).data('modal'));
      }
    });

    $('body').on('click', this.modalCloseClass, e => {
      e.preventDefault();
      let payload = $(e.target).parents('.modal').first();
      this.closeModal(payload[0].id);
    });
  }

  public init(): void {
  }

  public openStaticModal(id: string): void {
    DoubleClickProtectionElement.disableContainer($('body'));

    let modal = $(`#${id}`);
    let zindex = this.getMaxIndex();
    modal.css('z-index', zindex + 1);
    modal.addClass('show');

    this.state.dispatch({ name: Actions.STACK_BUTTONS, payload: modal.find($('.stackable-buttons')) });
    this.state.dispatch({ name: Events.MODAL_OPENED, payload: id });

    //stop the background scrolling when the modal first modal is opened
    if (!$('body').hasClass('hide-overflow')) {
      $('body').addClass('hide-overflow');
    }

    DoubleClickProtectionElement.enableContainer($('body'));
  }

  private getMaxIndex() : number {
    let index = 50000;
    this.currentOpenModals().forEach(i => {
      let currentIndex = parseInt($(`#${i}`).css('z-index'));
      if (currentIndex > index) {
        index = currentIndex;
      }
    });

    return index;
  }

  private currentOpenModals(): Array<string> {
    let openModals: Array<string> = [];

    $('.modal.show').each((index, modal) => {
      openModals.push(modal.id);
    });

    return openModals;
  }

  private closeModal(payload?: string): void {
    let openModals = this.currentOpenModals();
    let modalsToClose: Array<string> = [];

    if (typeof payload != typeof undefined) {
      modalsToClose.push(payload);
    } else {
      for (let modalId of openModals) {
        if (!modalId) {
          continue;
        }

        if (document.getElementById(modalId).getAttribute('data-keep-open') == null) {
          modalsToClose.push(modalId);
        }
      }
    }

    for (let modalId of modalsToClose) {
      let modal = $(`#${modalId}`);
      modal.find('video').trigger('pause');
      if (modal.data('ajax')) {
        modal.remove();
      } else {
        modal.removeClass('show');
      }
      this.state.dispatch({ name: Events.MODAL_CLOSED, payload: modalId });
    }

    // if there are no modals left to close
    if (this.currentOpenModals().length === 0) {
      if ($('body').hasClass('hide-overflow')) {
        $('body').removeClass('hide-overflow');
      }
    }
  }

  private confirmationModalEvent(action: Action): void {
    let options = action.payload['options'];

    let modalElement = this.openConfirmationModal(options);
    modalElement.on('click', '.proceed', async event => {
      DoubleClickProtectionElement.disableContainer(modalElement.find('.modal-dialog'));

      if (typeof action.payload['confirmed'] === typeof Function) {
        await action.payload['confirmed'](event);
      }

      if (options['keep_open'] !== true) {
        modalElement.remove();
        this.closeModal('confirmationModal');
      }
    });

    modalElement.on('click', '.closeModal', async event => {
      DoubleClickProtectionElement.disableContainer(modalElement.find('.modal-dialog'));

      if (typeof action.payload['cancelled'] === typeof Function) {
        await action.payload['cancelled'](event);
      }

      DoubleClickProtectionElement.enableContainer(modalElement.find('.modal-dialog'));
    });
  }

  private alertModalEvent(action: Action): void {
    let options = action.payload['options'];

    let defaults: Record<string, string> = {
      title: 'Alert',
      ok: 'Ok',
      body: ''
    };

    const settings = {
      ...defaults,
      ...options
    };

    let modalElement = this.openConfirmationModal(settings, false);
    modalElement.on('click', '.proceed, .close-modal', event => {
      if (typeof action.payload['confirmed'] === typeof Function) {
        action.payload['confirmed'](event);
      }

      modalElement.remove();
      this.closeModal();
    });

    if (typeof action.payload['after'] === typeof Function) {
      action.payload['after'](modalElement);
    }
  }

  private openConfirmationModal(options: Record<string, string>, withCancel= true): JQuery {
    let defaults: Record<string, string> = {
      title: 'Are you sure?',
      body: 'This action cannot be undone.',
      ok: 'Confirm',
      cancel: 'Cancel',
    };

    const settings = {
      ...defaults,
      ...options
    };

    let body = `
      <p>${settings['body']}</p>
      <div class="buttons stackable-buttons">
        <div class="buttons-left">`;

    if(withCancel) {
      body += `<button class="button-plain button-cancel closeModal">${settings['cancel']}</button>`;
    }

    body += `</div>
        <div class="buttons-right">
          <button class="button-plain button-danger proceed">${settings['ok']}</button>
        </div>
      </div>`;

    let modal = this.createModal('confirmationModal', settings['title'], body);
    this.openStaticModal('confirmationModal');
    return modal;
  }

  private openNewModalFromHtmlContent(id: string, title: string, content: string, sizeClass: string = 'medium', func: (modal: JQuery) => void, useVnodes: boolean): void {
    if (typeof sizeClass === typeof undefined) {
      sizeClass = 'medium';
    }

    let modal = this.createModal(id, title, content, sizeClass, useVnodes);
    this.openModalHtml(modal);

    if (typeof func !== typeof undefined && func !== null) {
      func(modal);
    }
  }

  private async openDynamicModal(url: string, func?: (modal: JQuery) => void): Promise<void> {
    DoubleClickProtectionElement.disableContainer($('body'));
    let html = await this.http.getHtml(url);
    let modal = $(html as any);
    modal.attr('data-ajax', '1');
    this.openModalHtml(modal);

    if (typeof func !== typeof undefined && func !== null) {
      func(modal);
    }
  }

  private openModalHtml(html: JQuery|string, func?: (modal: JQuery) => void): void {
    let modal = null;

    if (!(html instanceof jQuery)) {
      modal = $(html as any);
    } else {
      modal = html;
    }

    let id = modal.attr('id');
    if (typeof id == typeof undefined || id == '' || id == null) {
      let datetime = new Date();
      id = `modal_${datetime.getTime()}`;
      modal.attr('id', id);
    }

    this.modalsContainer.find(`#${id}`).remove();
    modal.prependTo(this.modalsContainer);
    this.openStaticModal(id);
    this.display.showElement(modal);
    DoubleClickProtectionElement.enableContainer($('body'));

    if (typeof func !== typeof undefined && func !== null) {
      func(modal);
    }
  }

  private createModal(id: string, title: string, body: string|Node|Node[], sizeClass: string = '', useVnodes = false): JQuery {
    this.modalsContainer.find(`#${id}`).remove();
    
    let modal = jQuery(`
      <div id="${id}" class="modal">
        <div class="modal-dialog">
          <div class="modal-content modalContent ${sizeClass}">
            <div class="modal-header">
              <h5 class="modal-title">${title}</h5>
              <div class="modal-close closeModal">X</div>
            </div>
            <div class="modal-body">
            </div>
          </div>
        </div>
      </div>
    `);

    if (useVnodes) {
      pRender(body, modal.find('.modal-body').get(0));
    } else if (Array.isArray(body) || body instanceof Node) {
      window.Jsx._appendChild(modal.find('.modal-body')[0], body);
    } else {
      modal.find('.modal-body').html(body);
    }

    modal.prependTo(this.modalsContainer);
    return modal;
  }

  public static showConfirmModal(title: string, body: string, confirmed: () => void, cancelled?: () => void): void {
    let payload = {
      options: {
        title: title,
        body: body
      },
      confirmed: () => {
        confirmed();
      },
      cancelled: () => {
        cancelled();
      }
    };

    window.App.state.dispatch({ name: Actions.OPEN_CONFIRMATION_MODAL, payload: payload });
  }
}
