import { Module, State } from 'shared/core';
import { Actions } from 'shared/state';
import { Injectable } from 'injection-js';
import { filter } from 'rxjs/operators';
import { MeasurementGuide } from 'webshop/lib';
import { MeasurementGuideValidator } from 'webshop/lib/measurement_guide_validator';

@Injectable()
export class ChildrenMeasurementsModule extends Module {
  private form: JQuery = $('#child_form');
  private childId: string = this.form.data('child-id');
  private continue: JQuery = $('#softLimit .continue, #hardLimit .continue');
  private continueConfirm: JQuery = $('.continueConfirm');
  private submitted = false;
  private softLimitWarned = false;
  private selectBoxes: JQuery = $('.measurement_select');

  private measurementGuide: MeasurementGuide;

  constructor(
    private state: State,
  ){
    super();
  }

  public init(): void {
    this.state.actions$.pipe(filter(action => action.name === Actions.MEASUREMENTS_VALIDATION)).subscribe(action => {
      const { payload } = action;
      const type: string = (payload.type === 'open') ? Actions.OPEN_STATIC_MODAL : Actions.CLOSE_MODAL;
      this.state.dispatch({ name: type, payload: payload.name });

      this.softLimitWarned = false;

      if (payload.name == 'softLimit' && this.hasItemsInBasket() && this.measurementHaveChanged()) {
        this.softLimitWarned = true;
        if ($('#softLimit').find('.measurement_change_cart_warning').length == 0) {
          $('p.warning_note').after($('#measurementWarning .modal-body').html());
        }
      }
    });

    this.state.actions$.pipe(
      filter(action => action.name === Actions.MEASUREMENTS_FORM_SUBMIT)
    ).subscribe(() => this.formSubmitCheck());

    this.continueConfirm.on('click', e => {
      e.preventDefault();
      this.formSubmit();
    });

    this.continue.on('click', e => {
      e.preventDefault();
      this.formSubmitCheck();
    });

    this.selectBoxes.on('focus', (e) => {
      let element: JQuery = $(e.target);

      if (typeof element.data('original') === typeof undefined || element.data('original') == null) {
        element.data('original', element.val());
      }
    });

    this.measurementGuide = new MeasurementGuide('#measurement-guide', new MeasurementGuideValidator());
  }

  private formSubmitCheck(): void {
    if (!this.softLimitWarned && this.hasItemsInBasket() && this.measurementHaveChanged()) {
      this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'measurementWarning' });
    } else {
      this.formSubmit();
    }
  }

  private hasItemsInBasket(): boolean {
    return $('.basket-element').find(`[data-child-id=${this.childId}]`).length > 0;
  }

  private formSubmit(): void {
    if (!this.submitted) {
      this.submitted = true;
      this.form.trigger('submit');
    }
  }

  private measurementHaveChanged(): boolean {
    let changes = false;

    this.selectBoxes.each((i, v) => {
      let element: JQuery = $(v);

      if (typeof element.data('original') !== typeof undefined
        && element.data('original') != null
        && element.val() != element.data('original')) {
        changes = true;
      }
    });

    return changes;
  }
}
