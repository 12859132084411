import { Element } from 'shared/core';

export class ExpandElement extends Element {
  public expandTrigger = $('.expandTrigger');
  public expandParentClassName = '.expandContent';
  public expandClassName = 'expanded';
  
  public init(): void {
    this.expandTrigger.on('click', e => this.openExpand(e));
  }

  private openExpand(e: JQuery.ClickEvent): void {
    e.preventDefault();

    const button = $(e.target);
    button.parents(this.expandParentClassName).addClass(this.expandClassName);
    button.removeClass('button-expand').addClass('button-collapse');
    button.text('Read less');
    button.off('click').on('click', e => this.closeExpand(e));
  }

  private closeExpand(e: JQuery.ClickEvent) {
    e.preventDefault();

    const button = $(e.target);
    button.parents(this.expandParentClassName).removeClass(this.expandClassName);
    button.removeClass('button-collapse').addClass('button-expand');
    button.text('Read more');
    button.off('click').on('click', e => this.openExpand(e));
  }
}
