(function(f,n){function c(b,d){var c;return!(c=f.data(b,a))?f.data(b,a,new e(b,d)):c}function e(a,c){function e(a,b){f(document).on("touchstart",A);h.hasFocus=!0;p="sxy-focus";a&&a(b);p="sxy-hover"}function r(){f(document).off("touchstart",A);h.hasFocus=!1;p="sxy-blur";h.el.triggerHandler(p);p="sxy-hover"}function l(a){v=a;u.x=a.pageX;u.y=a.pageY;h.pt=[u];h.trigger(p)}function m(a){v=a;a=a.originalEvent.targetTouches;for(var b=h.pt=[],d=0,c=a.length;d<c;++d)b.push({x:a[d].pageX,y:a[d].pageY,type:1,
down:!0});h.trigger(p)}function k(a,b,c,e){D.push(a={e:a,hnd:c,l:0,el:e||h.el});c=0;for(e=b.length;c<e;++c)n[d[b[c]]].push(a)}var h=this,p="sxy-hover",u={x:0,y:0,down:!1,type:0},D=[],n={},v;this.options=c=f.extend({},b,f.isPlainObject(c)?c:{});this.el=f(a);this.pt=[];this.g={};this.h={};this.hasFocus=!1;for(var z=0;z<d.length;++z)n[d[z]]=[];g||(k("mouseenter",[0,2,4],function(a){h.hasFocus||e(l,a);p="sxy-hover";l(a)}),k("mouseleave",[1,2,5],function(a){u.down?p="sxy-up":p="sxy-hover";u.down=!1;l(a);
r(l,a)}),k("mousedown",[3,4],function(a){p="sxy-down";u.down=!0;l(a);p="sxy-move"}),k("mouseup",[0,1,2,3,4,5],function(a){p="sxy-up";u.down=!1;l(a);p="sxy-hover"}),k("mousemove",[2,4],l));k("touchstart",[0,1,2,3,4,5],function(a){p="sxy-down";h.hasFocus||e(m,a);m(a);p="sxy-move"});k("touchend",[0,1,2,3,4,5],function(a){p="sxy-up";v=a;var b=a.originalEvent.targetTouches;a=a.originalEvent.changedTouches;for(var d=h.pt=[],c=0,e=b.length;c<e;++c)d.push({x:b[c].pageX,y:b[c].pageY,type:1,down:!0});c=0;for(e=
a.length;c<e;++c)d.push({x:a[c].pageX,y:a[c].pageY,type:1,down:!1});h.trigger(p);p="sxy-hover"});k("touchmove",[0,1,2,3,4,5],m);var A=function(a){for(var b=!0,c=h.pt,d=0,e=c.length;d<e;++d)if(!0==c[d].down){b=!1;break}b&&r(m,a)},E=f.Event;this.trigger=function(a,b){this.options.preventDefault&&v.preventDefault();b=b||{};b.pointers=h.pt;b.originalEvent=v;h.el.trigger(E(a,b))};this.eventEnabler=function(a,b){for(var c,d=n[a],e=b?1:-1,l=0,r=d.length;l<r;++l)switch((c=d[l]).l+=e){case 0:c.el.off(c.e,
c.hnd);break;case 1:c.el.on(c.e,c.hnd)}};h.focus=e;h.blur=r}for(var k=navigator.userAgent.toLowerCase(),g=-1<k.indexOf("android")&&-1<k.indexOf("mobile"),a="swinxytouch",b={preventDefault:!0,stopPropagation:!1},d="sxy-focus sxy-blur sxy-hover sxy-down sxy-move sxy-up".split(" "),k=function(a){return function(){c(this).eventEnabler(a,!0)}},m=function(a){return function(){c(this).eventEnabler(a,!1)}},h=0,l=d.length;h<l;++h){var r=d[h];f.event.special[r]={setup:k(r),teardown:m(r)}}f.fn[a]=function(a){return this.each(function(){var b=
c(this,a);a&&b[a]&&b[a].apply(b,Array.prototype.slice.call(arguments,1))})};f.fn[a].g=function(a,b,d){f.event.special[a]={setup:function(){var e=c(this),l;l=e.g[a]=new b(e,e.options[a]?f.extend({},d,e.options[a]):d);f.each(l,function(a,b){if("sxy-"==a.substr(0,4))e.el.on(a,l[a])})},teardown:function(){var b=c(this),d=b.g[a];f.each(d,function(a,c){"sxy-"==a.substr(0,4)&&b.el.off(a,d[a])});b.g[a]=null}}};f.fn[a].d=function(a,b){var c=a.x-b.x,d=a.y-b.y;return Math.sqrt(c*c+d*d)};f.fn[a].a=function(a,
b){var c;return 0<(c=Math.atan2(a.y-b.y,a.x-b.x))?c*(180/Math.PI):(2*Math.PI+c)*(180/Math.PI)};f.fn[a].h=function(a,b){e.prototype[a]=function(){this.h[a]=new b(this)}};f.fn[a].m=function(a,b){return{x:(a.x+b.x)/2,y:(a.y+b.y)/2}}})(jQuery);
(function(f,n){f.fn.swinxytouch.g("sxy-doubletap",function(c,e){var f=Math.abs,g,a;this["sxy-tap"]=function(b){b=b.position;null!=g&&(new Date).getTime()-g<e.maxDelay&&f(a.x-b.x)<e.maxMove&&f(a.y-b.y)<e.maxMove?(c.trigger("sxy-doubletap",{position:a}),g=null):(g=(new Date).getTime(),a={x:b.x,y:b.y})}},{maxDelay:300,maxMove:4})})(jQuery);
(function(f,n){var c="sxy-drag";f.fn.swinxytouch.g(c,function(e){function f(b,d){var g=b.pointers;a.state=d;0<g.length&&(a.position.x=g[0].x,a.position.y=g[0].y);e.trigger(c,a)}var g,a={position:{}};this["sxy-up"]=function(a){g&&f(a,3);g=!1};this["sxy-move"]=function(a){1==a.pointers.length&&(g?f(a,2):(g=!0,f(a,1)))}})})(jQuery);
(function(f,n){f.fn.swinxytouch.g("sxy-longpress",function(c,e){var f,g={},a=function(){var a=c.pt,d=Math.abs;f=null;d(g.x-a[0].x)<e.maxMove&&d(g.y-a[0].y)<e.maxMove&&c.trigger("sxy-longpress",{position:g})};this["sxy-down"]=function(b){b=b.pointers;g={x:b[0].x,y:b[0].y};f&&clearTimeout(f);f=setTimeout(a,e.minDelay)};this["sxy-up"]=function(a){clearTimeout(f)}},{minDelay:1E3,maxMove:5})})(jQuery);
(function(f,n){var c="sxy-rotate";f.fn.swinxytouch.g(c,function(e,k){function g(a,d){var g,f;h.state=d;h.rotation+=180>Math.abs(g=(f=b(a.pointers[0],a.pointers[1]))-m)?g:0;m=f;e.trigger(c,h)}var a,b=f.fn.swinxytouch.a,d,m,h={};this["sxy-down"]=function(c){2==c.pointers.length&&(a=!1,d=m=b(c.pointers[0],c.pointers[1]))};this["sxy-up"]=function(b){a&&(a=!1,g(b,3))};this["sxy-move"]=function(c){var e=c.pointers;2==e.length&&(a?g(c,2):Math.abs(d-b(e[0],e[1]))>k.minRotate&&(a=!0,h.rotation=0,g(c,1)))}},
{minRotate:3})})(jQuery);
(function(f,n){var c="sxy-scale";f.fn.swinxytouch.g(c,function(e,k){function g(d,g){var f=d.pointers,k=a(f[0],f[1]);h.state=g;h.scale=k/m;h.distance=k-m;h.position=b(f[0],f[1]);e.trigger(c,h)}var a=f.fn.swinxytouch.d,b=f.fn.swinxytouch.m,d,m,h={};this["sxy-down"]=function(b){b=b.pointers;2==b.length&&(d=!1,m=a({x:b[0].x,y:b[0].y},{x:b[1].x,y:b[1].y}))};this["sxy-up"]=function(a){d&&g(a,3)};this["sxy-move"]=function(b){var c=b.pointers;2==c.length&&(d?g(b,2):Math.abs(1-a(c[0],c[1])/m)>k.minScale&&
(d=!0,g(b,1)))}},{minScale:0.2})})(jQuery);
(function(f,n){f.fn.swinxytouch.g("sxy-swipe",function(c,e){var k=f.fn.swinxytouch.d,g=f.fn.swinxytouch.a,a={},b,d;this["sxy-down"]=function(a){a=a.pointers;b=(new Date).getTime();d={x:a[0].x,y:a[0].y}};this["sxy-up"]=function(f){f=f.pointers;(new Date).getTime()-b<e.maxTime&&k(d,f[0])>e.minMove&&(a.direction=e.map[Math.round(g(d,f[0])/(360/e.map.length))%e.map.length],a.distance=Math.abs(k(d,f[0])),c.trigger("sxy-swipe",a))}},{maxTime:300,minMove:10,map:["left","up","right","down"]})})(jQuery);
(function(f,n){f.fn.swinxytouch.g("sxy-tap",function(c,e){var f=Math.abs,g,a;this["sxy-down"]=function(b){b=b.pointers;1==b.length&&(g=(new Date).getTime(),a={x:b[0].x,y:b[0].y})};this["sxy-up"]=function(b){b=b.pointers;1==b.length&&(new Date).getTime()-g<e.maxDelay&&(f(a.x-b[0].x)<e.maxMove&&f(a.y-b[0].y)<e.maxMove)&&c.trigger("sxy-tap",{position:a})}},{maxDelay:150,maxMove:2})})(jQuery);
(function(f,n){f.fn.swinxytouch.h("bound",function(c){var e=c.el.offset(),k=e.left,g=e.top,a=k+c.el.width(),b=g+c.el.height();c.el.on("sxy-hover sxy-down sxy-up sxy-move sxy-focus",function(d){var e=d.pointers[0];e.y<g||e.x>a||e.y>b||e.x<k?c.hasFocus&&c.blur():c.hasFocus||(c.focus(),c.el.trigger(f.Event("sxy-focus",{pointers:d.pointers,originalEvent:d.originalEvent})))})})})(jQuery);
(function(f,n){f.fn.swinxytouch.h("smartclick",function(c){var e=this;e.allow=!1;e._hndClick=function(c){e.allow||c.preventDefault();e.allow=!1};e._hndTap=function(c){e.allow=!0;f(c.originalEvent.target).trigger("click")};c.el.on("click",e._hndClick);c.el.on("sxy-tap",e._hndTap)})})(jQuery);(function(f,n){function c(c,a){this.e=c.get(0);this.j=c.first();a!=n&&f.extend(this,a)}function e(c,a){var b=this;b.element=f(c);b.options=f.extend({},k,a);b.driver=new f.fn.swinxyzoom.modes[b.options.mode](this);b.initialised=!1;b.enabled=!1;b.waiting=null;b.element.css({cursor:"default"});b.dmp={cX:0,tX:0,cY:0,tY:0,timer:!1};b.last=!1;b.hasFocus=!1;b._animate=function(){var a=b.dmp,c=b.si.e.style,d=b.options.damping,e=a.tX-a.cX,f=a.tY-a.cY;c.left=(a.cX+=e/d)+"px";c.top=(a.cY+=f/d)+"px";0==~~e&&
0==~~f&&(clearTimeout(a.timer),a.timer=!1)};b._moveSlider=function(a){a=a.pointers[0].y;var c=b.ct.sl.j.offset(),d=b.maxZoom/b.ct.sl.j.height();a=b.ct.sl.j.height()-(a-c.top);b.zoom(a*d,b.cursor.lastX,b.cursor.lastY)};b._focus=function(){var a=b.dp.j.offset();b.dp.ol=a.left;b.dp.ot=a.top;b.hasFocus=!0;b.options.controls&&(b.ct.j.show(),b.ct.ol.j.stop().animate({opacity:0.5},{queue:!1}),b.ct.j.show());!1!==b.last&&(b.last.w!=b.rt.j.width()||b.last.h!=b.rt.j.height())&&b.rebuild(function(){b.zoom(b.maxZoom,
b.cursor.lastX,b.cursor.lastY);b.driver.load&&b.driver.load(b.cursor.lastX,b.cursor.lastY);b.zoom(b.maxZoom,b.cursor.lastX,b.cursor.lastY)});b.last={w:b.rt.j.width(),h:b.rt.j.height()}};b._blur=function(a){b.hasFocus=!1;b.options.controls&&(b.ct.j.hide(),b.ct.ol.j.stop().animate({opacity:0},{queue:!1,complete:function(){}}))};b._mousewheel=function(a,c,d,e){a.preventDefault();b.zoom(b.level+Math.round(e*(b.maxZoom/b.options.steps)),a.pageX,a.pageY)};b._stepIn=function(a){a.preventDefault();b.zoom(b.level+
Math.round(1*(b.maxZoom/b.options.steps)),b.cursor.lastX,b.cursor.lastY)};b._stepOut=function(a){a.preventDefault();b.zoom(b.level+Math.round(-1*(b.maxZoom/b.options.steps)),b.cursor.lastX,b.cursor.lastY)};var d;b._scale=[];b._scale[1]=function(a){d=b.level;f("#info").append("start["+d+"] ")};b._scale[2]=function(a){f("#info").append("s ");f("#info").append("s["+Math.abs(d*a.scale)+","+a.position.x+"] ");b.zoom(d*a.scale,a.position.x,a.position.y)};b._scale[3]=function(a){};var e=b.element.children().first().attr("src"),
h=b.element.attr("href"),l=new Image;l.onload=function(){b.load(e,h)};l.src=e}var k={damping:8,steps:15,mode:"dock",zoom:15,controls:!0,size:"actual"};e.prototype.load=function(c,a,b){var d=this;c||(c=d.dp.tn.src);a||(a=d.si.src);var e=function(){b&&d._focus();d.dp.tn.src=c;d.si.src=a;d.dp.tn.j.attr("src")!=d.dp.tn.src&&(d.dp.tn.j.one("load",function(){"actual"==d.options.size&&d.rt.j.css({width:d.dp.tn.j.width(),height:d.dp.tn.j.height()});d.dp.tn.j.stop().animate({opacity:1},{queue:!1,complete:function(){d.rt.bg.j.attr("src",
d.dp.tn.src);d.dp.tn.j.css({opacity:0})}})}),d.dp.tn.j.attr("src",d.dp.tn.src));var e=function(a,b){var c;d.si.j.one("load",function(){d.dp.j.off("sxy-move sxy-hover",c);d.waiting=null;d.rebuild(function(){d.zoom(d.maxZoom/d.options.steps*d.options.zoom,d.cursor.lastX,d.cursor.lastY);d.driver.load&&d.driver.load(d.cursor.lastX,d.cursor.lastY);d.zoom(d.maxZoom/d.options.steps*d.options.zoom,d.cursor.lastX,d.cursor.lastY);d.rt.j.toggleClass("sxy-zoom-loading")})});d.cursor={lastX:a,lastY:b};d.dp.j.on("sxy-move sxy-hover",
c=function(a){a=a.pointers[0];d.cursor={lastX:a.x,lastY:a.y}});d.si.j.attr("src",d.si.src)};if(b)d.waiting=null,d.rt.j.toggleClass("sxy-zoom-loading"),e(0,0);else if(!d.waiting)d.rt.j.one("sxy-focus",d.waiting=function(a){d.rt.j.toggleClass("sxy-zoom-loading");e(a.pointers[0].x,a.pointers[0].y)})};!1==d.initialised?d.tearUp(e):e()};e.prototype.getNaturalSize=function(c,a){var b=new Image;b.onload=function(){a({w:b.width,h:b.height})};b.src=c};e.prototype.rebuild=function(c){var a=this,b=a.dp,d=a.vp,
e=a.si,h=a.vf;f.extend(b,{w:"actual"==a.options.size?b.tn.j.width():a.rt.j.width(),h:"actual"==a.options.size?b.tn.j.height():a.rt.j.height(),ol:b.j.offset().left,ot:b.j.offset().top,hyp:Math.round(Math.sqrt(Math.pow(b.tn.j.width(),2)+Math.pow(b.tn.j.height(),2)))});switch(a.options.size){case "actual":a.rt.j.css({width:b.w,height:b.h});break;case "src":a.rt.j.css({width:b.w,height:b.h})}f.extend(d,{w:b.w,h:b.h});a.vp.j.css({width:b.w,height:b.h});a.getNaturalSize(a.si.src,function(d){f.extend(e,
{w:0,h:0,l:0,t:0,mL:0,mT:0,oHyp:Math.round(Math.sqrt(Math.pow(d.w,2)+Math.pow(d.h,2))),oH:d.h,oW:d.w});f.extend(h,{w:0,h:0,l:0,t:0});a.ct.sl.j.css({height:a.ct.sl.h=a.ct.j.height()-(a.ct.zin.j.outerHeight()+a.ct.zout.j.outerHeight())});a.ct.sl.h-=16;clearTimeout(a.dmp.timer);a.dmp={cX:0,tX:0,cY:0,tY:0,timer:!1};a.scale=Math.atan(a.si.oH/a.si.oW);a.angle=0;a.maxZoom=e.oHyp-b.hyp;a.level=0;c()})};e.prototype.tearUp=function(e){var a=this;a.initialised=!0;a.el=new c(a.element);a.el.tn=new c(a.element.children());
a.getNaturalSize(a.el.tn.j.attr("src"),function(b){console.log("tear-up "+a.options.mode);var d,m;switch(a.options.size){case "actual":d=b.w+"px";m=b.h+"px";break;case "src":d=a.el.tn.j.attr("width")+"px";m="auto";break;default:d=a.options.size,m="auto"}var h={w:d,h:m,srcthumb:a.el.tn.j.attr("src"),mode:a.options.mode,auto:"auto"==m?'<div style="padding-top: '+100/b.w*b.h+'%;" />':""};a.el.j.wrap('<div class="sxy-zoom-container sxy-zoom-mode-{mode}" style="width: {w}; height: {h}; position: relative;" ></div>'.replace(/\{(\w+)\}/g,
function(a,b){return h[b]||a}));a.element.hide();a.rt=new c(a.el.j.parent());a.rt.j.append('<img class="sxy-zoom-bg" src="{srcthumb}" />{auto}<div class="sxy-controls"><div class="overlay"></div><a class="in" href="#"></a><div class="sxy-slider"><div class="sxy-handle" style="top: 0px;"></div></div><a class="out" href="#"></a></div><div class="sxy-zoom-dragpad"><div class="sxy-overlay"></div><div class="sxy-zoom-viewport"><img galleryimg="no" /></div><img class="inner-thumb" src="{srcthumb}" /><div class="sxy-zoom-viewfinder"></div></div><div class="sxy-loading"><span></span></div>'.replace(/\{(\w+)\}/g,
function(a,b){return h[b]||a}));a.rt.bg=new c(a.rt.j.find(".sxy-zoom-bg"));a.rt.j.on("dragstart",function(a){return!1});a.ct=new c(a.rt.j.find(".sxy-controls").hide());a.ct.ol=new c(a.ct.j.find(".overlay"));a.ct.zin=new c(a.ct.j.find(".in"));a.ct.zout=new c(a.ct.j.find(".out"));a.ct.sl=new c(a.ct.j.find(".sxy-slider").first(),{h:0});a.ct.hnd=new c(a.ct.j.find(".sxy-handle"));a.dp=new c(a.rt.j.find(".sxy-zoom-dragpad").first(),{w:0,h:0,ol:0,ot:0,hyp:0});a.dp.ovl=new c(a.dp.j.find(".sxy-overlay"));
a.dp.tn=new c(a.dp.j.find(".inner-thumb").css({opacity:0}),{src:""});a.vp=new c(a.dp.j.find(".sxy-zoom-viewport").css({opacity:0}),{w:0,h:0});a.si=new c(a.vp.j.find("img"),{w:0,h:0,l:0,t:0,mL:0,mT:0,oHyp:0,oH:0,oW:0,src:""});a.vf=new c(a.dp.j.find(".sxy-zoom-viewfinder").css({display:"none"}),{w:0,h:0,l:0,t:0,osl:0,ost:0});a.rt.j.on("sxy-focus",a._focus);a.rt.j.on("sxy-blur",a._blur);if(f.event.special.mousewheel)a.rt.j.on("mousewheel",a._mousewheel);a.dp.j.on("sxy-scale",function(b){a._scale[b.state](b)});
a.ct.zin.j.on("click",a._stepIn);a.ct.zout.j.on("click",a._stepOut);a.ct.sl.j.on("sxy-down sxy-move",a._moveSlider);e()})};e.prototype.move=function(c,a,b){var d=-1*(c/this.scale),e=-1*(a/this.scale);this.cursor.lastX=d+this.vf.w/2+this.dp.ol;this.cursor.lastY=e+this.vf.h/2+this.dp.ot;0>d&&(d=c=0);0>e&&(e=a=0);d+this.vf.w>this.dp.w&&(d=this.dp.w-this.vf.w,c=this.si.mL);e+this.vf.h>this.dp.h&&(e=this.dp.h-this.vf.h,a=this.si.mT);this.vf.l=~~d;this.vf.t=~~e;this.dmp.tX=c;this.dmp.tY=a;b&&!1!=this.options.damping?
this.dmp.timer||(this.dmp.timer=setInterval(this._animate,16)):(clearTimeout(this.dmp.timer),this.dmp.timer=!1,b=this.si.e.style,b.left=(this.dmp.tX=this.dmp.cX=c)+"px",b.top=(this.dmp.tY=this.dmp.cY=a)+"px")};e.prototype.center=function(c,a,b){this.move(-1*(c-this.vf.w/2)*this.scale,-1*(a-this.vf.h/2)*this.scale,b)};e.prototype.zoom=function(c,a,b){this.level=c;0>this.level&&(this.level=0);this.level>this.maxZoom&&(this.level=this.maxZoom);c=100*((this.si.oHyp-this.level)/this.si.oHyp);this.ct.hnd.j.css({top:this.ct.sl.h-
this.ct.sl.h/100*100*(this.level/this.maxZoom)});this.vf.w=this.vp.w/100*c;this.vf.h=this.vp.h/100*c;this.vf.osl=parseInt(this.vf.j.css("border-left-width"),10);this.vf.ost=parseInt(this.vf.j.css("border-top-width"),10);this.scale=this.si.oW/this.vp.w/(this.si.oW/100*c/this.vp.w);this.si.w=this.si.oW/(this.si.oW/100*c/this.dp.w);this.si.h=this.si.oH/(this.si.oH/100*c/this.dp.h);this.vf.j.css({width:this.vf.w,height:this.vf.h});this.si.j.css({width:this.si.w,height:this.si.h});this.si.mL=0-(this.si.w-
this.vp.w);this.si.mT=0-(this.si.h-this.vp.h);this.driver.zoom&&this.driver.zoom(a,b)};f.fn.swinxyzoom=function(c){var a=Array.prototype.slice.call(arguments,1);return this.each(function(){var b;if(b=f.data(this,"swinxyzoom")){if(b[c])return b[c].apply(b,a);f.error("Method "+c+" does not exist on jQuery.swinxyzoom")}else f.data(this,"swinxyzoom",new e(this,c))})};f.fn.swinxyzoom.modes={}})(jQuery);(function(f,n){function c(a){var b=0,c=0,e;for(e in a){++b;for(var f=a[e],g=f.variations,k=0,q=g.length;k<q;++k)if(document.createElement("div").style[g[k]]!==n){++c;f.supported=g[k];break}}return b==c}var e={position:"right"},k=!1,g={backgroundSize:{supported:!1,variations:["backgroundSize","WebkitBackgroundSize","MozBackgroundSize","OBackgroundSize","msBackgroundSize"]}};f.fn.swinxyzoom.modes.dock=function(a){function b(){r&&(r=!1,a.vf.j.hide(),a.dp.ovl.j.stop().animate({opacity:0},{queue:!1}),
a.vp.j.stop().animate({opacity:0,left:a.dp.w/2,top:a.dp.h/2,width:0,height:0},{queue:!1}))}function d(b,c){a.waiting||(r=!0,a.si.j.show(),a.vp.j.show(),a.vf.j.show(),a.dp.ovl.j.stop().animate({opacity:0.5},{queue:!1}),a.vp.j.stop().animate({opacity:1,left:s,top:t,width:a.vp.w,height:a.vp.h},{queue:!1}),q.move(b,c,!0))}function m(){a.rt.j.on("sxy-focus",function(a){a=a.pointers[0];d(a.x,a.y)});a.rt.j.on("sxy-blur",function(a){b()});a.dp.j.on("sxy-hover sxy-move sxy-down",B);if(!1==w){var c=f('<img src="" style="display: block; position: absolute;" />');
a.vf.j.append(c);a.vf.img={j:c,e:c.get(0)}}q.initialised=!0}function h(){if(q.initialised){var b=a.vf,c=a.vf.e.style;b.l!=x||b.t!=p?(c.left=(x=b.l)+"px",c.top=(p=b.t)+"px",!1==w?(c=a.vf.img.e.style,c.left="-"+(b.l+b.osl)+"px",c.top="-"+(b.t+b.ost)+"px"):c.backgroundPosition="-"+(b.l+b.osl)+"px -"+(b.t+b.ost)+"px",y=setTimeout(h,8)):y=!1}}function l(c,e,f){var g=a.dp.j.offset(),l=g.left,g=g.top,t=l+a.dp.j.width(),k=g+a.dp.j.height();e<g||c>t||e>k||c<l?b():(r||d(c,e),a.center(c-a.dp.ol,e-a.dp.ot,f),
y||h())}this.initialised=!1;k||(k=!0,g._all=c(g));var r=!1,q=this,s=0,t=0,w=g.backgroundSize.supported,B=function(a){1==a.pointers.length&&(a=a.pointers[0],l(a.x,a.y,!0))},C=f.extend({},e,a.options.dock!=n?a.options.dock:{}),y=!1,x,p;q.tearUp=m;q.load=function(b,c){switch(C.position){case "top":s=0;t=-1*(a.dp.h+10);break;case "right":s=a.dp.w+10;t=0;break;case "bottom":s=0;t=a.dp.h+10;break;case "left":s=-1*(a.dp.w+10),t=0}a.vp.j.css({width:0,height:0,left:a.dp.w/2,top:a.dp.h/2});a.dp.ovl.j.css({opacity:0});
a.vf.j.css({position:"relative",overflow:"hidden"});x=p=0;q.initialised||m();!1==w?(a.vf.img.j.attr("src",a.dp.tn.src),a.vf.img.j.css({width:a.dp.w,height:a.dp.h})):(a.vf.j.css("background-image","url("+a.dp.tn.src+")"),a.vf.j.css(w,a.dp.w+"px "+a.dp.h+"px"));a.hasFocus&&d(b,c)};q.focus=d;q.blur=b;q.move=l;q.zoom=function(a,b){l(a,b,!1)}}})(jQuery);(function(f,n){f.fn.swinxyzoom.modes.window=function(c){function e(b,d){c.waiting||(c.si.j.show(),c.vp.j.show(),c.vf.j.show(),c.vp.j.stop().animate({opacity:1},{queue:!1}),a(b,d,!0))}function f(){c.vf.j.hide();c.vp.j.stop().animate({opacity:0},{queue:!1})}function g(){var a=c.vf,b=c.vf.e.style;a.l!=m||a.t!=h?(b.left=(m=a.l)+"px",b.top=(h=a.t)+"px",d=setTimeout(g,8)):d=!1}function a(a,b,e){c.center(a-c.dp.ol,b-c.dp.ot,e);d||g()}var b=this,d=this.initialised=!1,m,h;b.tearUp=function(b,d){c.rt.j.on("sxy-focus",
function(a){e(a.pointers[0].x,a.pointers[0].y)});c.rt.j.on("sxy-blur",function(){f()});c.dp.j.on("sxy-hover sxy-move sxy-down",function(b){1==b.pointers.length&&(b=b.pointers[0],a(b.x,b.y,!0))})};b.load=function(a,d){c.vp.j.css({opacity:0,width:c.dp.w,height:c.dp.h,left:0,top:0});m=h=0;b.initialised||b.tearUp();c.hasFocus&&b.focus(a,d)};b.focus=e;b.blur=f;b.move=a;b.zoom=function(b,c){a(b,c,!1)}}})(jQuery);(function(f,n){var c={width:200,height:200};f.fn.swinxyzoom.modes.lens=function(e){function k(a,b){e.waiting||(m=!0,e.dp.j.on("sxy-hover sxy-move sxy-down",h),e.vp.j.show(),e.vp.j.stop().animate({opacity:1,width:e.vp.w,height:e.vp.h},{queue:!1}),d.move(a,b,!0))}function g(){m&&(m=!1,e.dp.j.off("sxy-hover sxy-move sxy-down",h),e.vp.j.stop().animate({opacity:0},{queue:!1,complete:function(){e.vp.j.hide()}}))}function a(){var b=e.vf,c=e.vp,d=e.vp.e.style;b.l!=q||b.t!=s?(d.left=e.cursor.lastX-e.dp.ol-
c.w/2+"px",d.top=e.cursor.lastY-e.dp.ot-c.h/2+"px",r=setTimeout(a,8)):r=!1}function b(b,c,d){e.center(b-e.dp.ol,c-e.dp.ot,d);r||a()}this.initialised=!1;var d=this,m=!0,h=function(a){1==a.pointers.length&&(a=a.pointers[0],b(a.x,a.y,!0))},l=f.extend({},c,e.options.lens!=n?e.options.lens:{}),r=!1,q,s;d.tearUp=function(a,b){e.dp.j.swinxytouch("bound");e.dp.j.on("sxy-focus",function(a){k(a.pointers[0].x,a.pointers[0].y)});e.dp.j.on("sxy-blur",function(){g()})};d.load=function(a,b){e.vp.j.css({opacity:0,
width:l.width,height:l.height,left:0,top:0});e.vp.w=l.width;e.vp.h=l.height;e.vp.j.show();e.si.j.show();e.vf.j.hide();q=s=0;d.initialised||d.tearUp();e.hasFocus&&k(a,b)};d.focus=k;d.blur=g;d.move=b;d.zoom=function(a,c){b(a,c,!1)}}})(jQuery);(function(f,n){f.fn.swinxyzoom.modes.slippy=function(c){var e,f,g,a;function b(){c.dp.j.on("sxy-focus",function(a){d(a.pointers[0].x,a.pointers[0].y)});c.dp.j.on("sxy-blur",function(a){m()});c.dp.j.on("sxy-down",function(b){c.dp.j.toggleClass("down");b=b.pointers[0];e=c.dmp.tX;f=c.dmp.tY;g=b.x-c.dp.ol;a=b.y-c.dp.ot});c.dp.j.on("sxy-up",function(a){c.dp.j.toggleClass("down")});c.dp.j.on("sxy-move",function(b){b=b.pointers[0];c.move(e+(b.x-c.dp.ol-g),f+(b.y-c.dp.ot-a),!0);n||h()})}function d(a,b){c.waiting||
(c.si.j.show(),c.vp.j.show(),c.vf.j.show(),c.vp.j.stop().animate({opacity:1},{queue:!1}))}function m(a,b){c.vf.j.hide();c.vp.j.animate({opacity:0},{queue:!1})}function h(){var a=c.vf,b=c.vf.e.style;a.l!=q||a.t!=s?(b.left=(q=a.l)+"px",b.top=(s=a.t)+"px",n=setTimeout(h,8)):n=!1}this.initialised=!1;var l=this;a=g=f=e=void 0;var n=!1,q,s;l.tearUp=b;l.load=function(h,m){c.vp.j.css({opacity:0,width:c.dp.w,height:c.dp.h,left:0,top:0});f=e=0;g=h;a=m;c.center(c.dp.w/2,c.dp.h/2,!1);q=s=0;l.initialised||b();
c.hasFocus&&d(h,m)};l.focus=d;l.blur=m;l.zoom=function(a,b){c.center(c.cursor.lastX-c.dp.ol,c.cursor.lastY-c.dp.ot,!1);c.vf.j.css({left:c.vf.l,top:c.vf.t})}}})(jQuery);
