import { Element, State } from 'shared/core';
import { Injectable } from 'injection-js';
import { filter } from 'rxjs/operators';
import { Actions } from 'shared/state';

@Injectable()
export class FormsElement extends Element {
  constructor(
    private state: State
  ) {
    super();

    this.state.actions$.pipe(
      filter(e => e.name === Actions.DISALLOW_AUTOCOMPLETE)
    ).subscribe(() => FormsElement.disallowAutocomplete());
  }

  public init(): void {
    let $body = $('body');

    /* Global Default AutoComplete */
    $body.on('change', '.autoSubmit', e => {
      let form = $(e.target).closest('form');
      if (form.hasClass('noValidateAutoSubmit') || this.validateForm(form)) {
        form.trigger('submit');
      }
    });

    $body.on('click', 'a.autoSubmit', e => {
      let form = $(e.target).closest('form');
      if (form.hasClass('noValidateAutoSubmit') || this.validateForm(form)) {
        form.trigger('submit');
      }
    });

    $body.on('submit', 'form', e => {
      $(e.target).closest('form').find('.changed').removeClass('changed');
    });

    $body.on('change', '.borderWhenChanged', e => FormsElement.inputChanged(e));
    $body.on('keyup', '.borderWhenChanged', e => FormsElement.inputChanged(e));

    /* Global Default Disallow AutoComplete */
    FormsElement.disallowAutocomplete();

    $body.on('click', 'a.validateForm', e => {
      $('form.validateForm').each((i, form) => {
        if (!this.validateForm($(form))) {
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      })
    });

    $body.find('.clear_checkbox_on_load').prop('checked', false);

    // Handler for check box check all functionality.
    //
    // Create a checkbox with the class or ID of checkBoxSelectAll
    // Specific a data-for-selector (jquery selector string) for the checkboxes to control.  If not present, this
    // defaults to .select_checkbox
    $body.on('click', 'input[type="checkbox"]#checkBoxSelectAll, input[type="checkbox"].checkBoxSelectAll', e => {
      let elem = e.target as HTMLInputElement;
      let for_selector = elem.getAttribute('data-for-selector') || '.select_checkbox';

      $(for_selector).each((i, focusElem) => {
        if (focusElem.tagName == 'INPUT' && focusElem.getAttribute('type') == 'checkbox') {
          (focusElem as HTMLInputElement).checked = elem.checked;
        }
      });
    });

    // Set the default state of "check all" check boxes, so that it is checked if all the controlled check boxes are
    // also in a checked state.
    $body.find('input[type="checkbox"]#checkBoxSelectAll').each((i, elem) => {
      let for_selector = elem.getAttribute('data-for-selector') || '.select_checkbox'
      let allChecked = true;

      $(for_selector).each((i, focusElem) => {
        if (focusElem.tagName == 'INPUT' && focusElem.getAttribute('type') == 'checkbox') {
          allChecked = allChecked && (focusElem as HTMLInputElement).checked;
        }
      });

      (elem as HTMLInputElement).checked = allChecked;
    });
  }

  private static inputChanged(e): void {
    $(e.target).closest('input, select').addClass('changed');
  }

  private validateForm(form: JQuery): boolean {
    let form_elem = form[0] as HTMLFormElement;
    let valid = true;
    if (typeof form_elem.checkValidity === typeof Function && !form_elem.checkValidity()) {
      valid = false;
      if (form_elem.reportValidity) {
        form_elem.reportValidity();
      }
    }
    return valid;
  }

  private static disallowAutocomplete(): void {
    if (navigator.userAgent.search('Chrome') > 0) {
      setTimeout(() => {
        $('.disallow_autocomplete').attr('autocomplete', Math.random().toString())
      }, 900);
    } else {
      $('.disallow_autocomplete').attr('autocomplete', 'off');
    }
  }
}
