import { Injectable } from 'injection-js';

@Injectable()
export class Checksum {
  public static stringCheck(string: string): string {
    let chk = 0x12345678;
    const len = string.length;

    for (let i = 0; i < len; i++) {
      chk += (string.charCodeAt(i) * (i + 1));
    }

    return (chk & 0xffffffff).toString(16);
  }

  public static luhnChecksum(ccNumber: string): boolean {
    ccNumber.replace(/\D/g, '');
    let ccLength = ccNumber.length;
    let parity = ccLength % 2;

    if (ccLength < 12 || ccLength > 19) {
      return false;
    }

    let sum = 0;

    for (let i=0; i<ccLength; i++) {
      let digit = ccNumber.charCodeAt(i) - 48;
      if (i % 2 == parity) {
        digit = digit * 2;
      }

      if (digit > 9) {
        digit = digit - 9;
      }
      sum = sum + digit;
    }

    return (sum % 10 == 0);
  }
}
