import { Http, Module, State } from 'shared/core';
import { Injectable } from 'injection-js';
import { FlexTable } from 'shared/lib';
import { Actions } from 'shared/state';
import { CustomerItem } from 'webshop/models';
import { TypeAheadListElement } from 'shared/elements';

@Injectable()
export class RepairsNewModule extends Module {
  private addItemModalId = 'addRepairItemModal';
  private addItemGridSelector = '#addItemGrid';
  private addItemAdditionalOptionsSelector = '#addItemAdditionalOptions';
  private addRepairSelector = '.addRepairItem';
  private addItemOptionSelectButtonSelector = '.itemOptionSelectButton';
  private removeItemSelector = '.removeRepairItem';
  private addItemSelectButtonSelector = '.itemSelectButton';
  private iiDataKey = 'iiKey';
  private oiDataKey = 'oiKey';
  private grouped_customer_items: Record<string, Array<CustomerItem>>;

  constructor(
    private http: Http,
    private state: State,
  ) {
    super();
  }

  public async init(): Promise<void> {
    // Set up customer items if not set
    // group all customer items by the inventory item - if not done already
    let url = $(this.addRepairSelector).data('url');
    let resp = await this.http.get<{customer_items: Array<CustomerItem>}>(url);
    this.grouped_customer_items = {};
    resp.customer_items.forEach((item: CustomerItem) => {
      this.grouped_customer_items[item.inventory_item_id] = this.grouped_customer_items[item.inventory_item_id] || []
      this.grouped_customer_items[item.inventory_item_id].push(item);
    });

    this.setUpAddRepairModal();

    $('body').on('click', this.addRepairSelector,  e => this.showAddRepairItemModal(e));
    $('body').on('click', this.addItemSelectButtonSelector, e => this.showAddRepairItemAdditionalOptions(e));
    $('body').on('click', this.removeItemSelector, e => this.removeRepairItem(e));

    $('body').on('click', this.addItemOptionSelectButtonSelector, e => {
      e.preventDefault();
      let target = $(e.target);
      this.selectRepairItem(target.data(this.iiDataKey), target.data(this.oiDataKey));
    });

    new FlexTable('sm');
    $(this.addRepairSelector).removeClass('hidden');
  }

  /**
   * This function will:
   * 1) populate step 1 of the add item modal with the uniq inventory items - if not done already
   * 2) hide step 2 of the add item modal and show step 1
   * 3) Open modal
   * @param e
   **/
  private showAddRepairItemModal(e: JQuery.ClickEvent): void {
    e.preventDefault();

    // Reset modal view to show step 1
    $(this.addItemGridSelector).removeClass('hidden');
    $(this.addItemAdditionalOptionsSelector).addClass('hidden');

    this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: this.addItemModalId });
  }

  /**
   * This function will:
   * 1) Reset step 2 options
   * 2) Select item if the inventory item selected only has 1 record then close modal
   * 3) Group all items by order item, if all items from the same order item then select and close modal
   * 4) If multiple order items found then populate step 2 with the options of with order item they would like to select
   * @param e
   **/
  private showAddRepairItemAdditionalOptions(e: JQuery.ClickEvent): void {
    e.preventDefault();

    // Remove all generated item options except the template
    $(this.addItemAdditionalOptionsSelector).find('.selectedItemOption').not('.template').remove();

    let ii_key = $(e.target).data(this.iiDataKey);
    let items = this.grouped_customer_items[ii_key];

    // Only one customer item so select it
    if (items.length == 1) {
      this.selectRepairItem(items[0].inventory_item_id, items[0].order_item_id);
      return;
    }

    let grouped_by_order_item = {} as Record<string, Array<CustomerItem>>;
    items.forEach((item: CustomerItem) => {
      if (this.isSelectable(item)) {
        grouped_by_order_item[item.order_item_id] = grouped_by_order_item[item.order_item_id] || []
        grouped_by_order_item[item.order_item_id].push(item);
      }
    });

    let order_item_ids = Object.keys(grouped_by_order_item);

    // All items are from the same order item
    if (order_item_ids.length == 1) {
      this.selectRepairItem(items[0].inventory_item_id, +order_item_ids[0]);
      return;
    }

    // Else show them options to pick from
    // Show step 2 in modal
    $(this.addItemGridSelector).addClass('hidden');
    $(this.addItemAdditionalOptionsSelector).removeClass('hidden');
    $('#additionalOptionItemDescription').text(`${items[0].size} ${items[0].description}`);

    let template = $('.selectedItemOption.template');

    order_item_ids.forEach((oi_id) => {
      let oi_group = grouped_by_order_item[oi_id];
      let clone = template.clone();

      clone.find('.item-options-td-child').text(oi_group[0].child_name);
      clone.find('.item-options-td-ordered-at').text(oi_group[0].order_completed_at);
      clone.find('.item-options-td-order-ref').text(oi_group[0].order_ref);

      let button = clone.find(this.addItemOptionSelectButtonSelector);
      button.data(this.iiDataKey, oi_group[0].inventory_item_id);
      button.data(this.oiDataKey, oi_id);

      clone.removeClass('hidden template');
      clone.appendTo('#additionalOptionsTable');
    });
  }

  private removeRepairItem(e: JQuery.ClickEvent): void {
    e.preventDefault();

    let roi = $(e.target).closest( '.selectedRepairOrderItem' );
    let order_item_id = roi.find('.hidden-order-item-id').val();
    let inventory_id = roi.data(this.iiDataKey);
    roi.remove();

    let ii: CustomerItem[] = this.grouped_customer_items[inventory_id];

    // Mark one customer item for that removed order item as not selected
    for(let item of ii) {
      if (item.order_item_id == order_item_id && item.selected) {
        item.selected = false;
        break;
      }
    }

    // Update state of the add repair item modal for specific inventory item
    $(`#addItem${inventory_id}`).replaceWith(this.cloneNewAddItem(ii));
  }

  /**
   * This function will:
   * 1) Go through and mark the first match of inventory item id and order item id found as selected
   * 2) re-render the item on step 1 of the add item modal
   * 3) add selected repair order item to the selected list
   * 4) enable next button & remove empty list message
   * @param inventory_id
   * @param order_item_id
   **/
  private selectRepairItem(inventory_id: number, order_item_id: number): void {
    let ii: CustomerItem[] = this.grouped_customer_items[inventory_id];
    let selectedItem: CustomerItem = null;

    // Mark one customer item for that selected order item as selected
    for(let item of ii) {
      if (item.order_item_id == order_item_id && this.isSelectable(item)) {
        item.selected = true;
        selectedItem = item;
        break;
      }
    }

    // Update state of the add repair item modal for specific inventory item
    $(`#addItem${inventory_id}`).replaceWith(this.cloneNewAddItem(ii));

    // Add selected repair item to repair order item list
    let clone = $('.selectedRepairOrderItem.template').clone();
    clone.find('.product-img').attr('src', selectedItem.img_url);
    clone.find('.repairs-item-list-td-item-details-content-description').text(selectedItem.description);
    clone.find('.repairs-item-list-td-item-details-content-size').text(`Size: ${selectedItem.size}`);
    clone.find('.repairs-item-list-td-item-details-content-child').text(`Child: ${selectedItem.child_name}`);
    clone.find('select').prop('required', true);
    clone.find('.hidden-order-item-id').val(selectedItem.order_item_id);
    clone.find('input, select, textarea').prop('disabled', false);
    clone.data(this.iiDataKey, inventory_id);

    selectedItem.available_repair_actions.forEach(repair_action => {
      clone.find('select').append($('<option>', {
        value: repair_action.id,
        text: repair_action.label
      }));
    });

    clone.removeClass('template hidden');
    clone.appendTo('#selectedRepairOrderItems');
    TypeAheadListElement.forContainer(clone);

    $('#emptySelectedRepairOrderItems').hide();
    $('.submitRepairOrder').prop('disabled', false);

    this.state.dispatch({ name: Actions.CLOSE_MODAL, payload: this.addItemModalId });
  }

  /**
   * This function will:
   * 1) Clone the template item
   * 2) populate the required data
   * @param iiGroup
   **/
  private cloneNewAddItem(iiGroup: CustomerItem[]): JQuery {
    let clone = $('.add-repairs-items-td.template').clone();
    let button = clone.find(this.addItemSelectButtonSelector);

    button.data(this.iiDataKey, iiGroup[0].inventory_item_id);
    clone.attr('id', `addItem${iiGroup[0].inventory_item_id}`)
    clone.find('.add-repairs-item-content-title').text(iiGroup[0].description);
    clone.find('.add-repairs-item-content-size').text(iiGroup[0].size);
    clone.find('.product-img').attr('src', iiGroup[0].img_url);
    clone.removeClass('hidden template');

    let unselectableReasons = [];
    let anySelected = false;
    let selectable = false;

    iiGroup.forEach((item: CustomerItem) => {
      if (this.isSelectable(item)) {
        selectable = true;
        return;
      }

      if (item.selected) {
        anySelected = true;
      }
    });

    // All selectable items have been selected
    if (anySelected && !selectable) {
      unselectableReasons.push('All available items already selected.');
    }

    if (!selectable) {
      let unselectable = clone.find('.add-repairs-item-footer-unselectable');
      button.addClass('hidden');
      unselectable.removeClass('hidden');
      unselectable.html(unselectableReasons.join('/n'));
    }

    return clone;
  }

  private setUpAddRepairModal(): void {
    // Remove all generated items except the template
    $(this.addItemGridSelector).find('.add-repairs-items-td').not('.template').remove();

    let product_groups = Object.values(this.grouped_customer_items);
    if (product_groups.length > 0) {
      // Generate new items
      product_groups.forEach((product_group) => {
        this.cloneNewAddItem(product_group).appendTo(this.addItemGridSelector);
      });
    } else {
      $('.no-repairs-items').removeClass('hidden');
    }
  }

  // Check if the customer item is selectable or not, so not already selected and has no unrepairable reasons
  private isSelectable(item: CustomerItem): boolean {
    return !item.selected;
  }
}
