import { FieldCollection } from '../fender_render/field_collection';

export class Url {
  public static getQueryString(field: string, url?: string): string {
    const href = url ? url : window.location.href;
    const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    const string = reg.exec(href);
    return string ? string[1] : null;
  }

  public static getQueryStringWithFilters(filters: FieldCollection, url?: string): string {
    let href = url ? url : window.location.href;
    let queryString = '';
    for (let i = 0; i < filters.fields.length; i++) {
      let filter = filters.fields[i];
      if (filter.value) {
        queryString += (queryString ? '&' : '?') + filter.name + '=' + filter.value;
      }
    }
    return href + queryString;
  }

  public static removeUrlParam(url: string, parameter: string): string {
    let params = url.split('?');
    if (params.length >= 2) {
      let prefix = encodeURIComponent(parameter) + '=';
      let param = params[1].split(/[&;]/g);

      // reverse iteration as may be destructive
      for (let i = param.length; i-- > 0;) {
        if (param[i].lastIndexOf(prefix, 0) !== -1) {
          param.splice(i, 1);
        }
      }

      url = params[0] + '?' + param.join('&');
    }
    return url;
  }

  public static updateUrlParam(url: string, parameter: string, value: string): string {
    const separator = url.indexOf('?') !== -1 ? '&' : '?';
    const reg = new RegExp(`([?&])${parameter}=.*?(&|$)`, 'i');
    value = encodeURIComponent(value);

    if (url.match(reg)) {
      return url.replace(reg, `$1${parameter}=${value}$2`);
    } else {
      return `${url}${separator}${parameter}=${value}`;
    }
  }
}
