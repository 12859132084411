import { Injectable } from 'injection-js';
import { Module, Url } from 'shared/core';

@Injectable()
export class FaqModule extends Module {
  private first_element = null;
  private highlighted = false;
  private search: JQuery = $('#search');
  private phrase: JQuery = $('#phrase');

  private faqBody = '.faq';
  private collapseItem = '.accordion-item';
  private collapseHead = '.accordion-head';
  private collapseBody = '.accordion-body';
  private highlightClassName = '.highlight';

  private touched = false;
  private faqError: JQuery = $('.faq-error');
  private upClass = 'fa-chevron-up';
  private downClass = 'fa-chevron-down';

  public init(): void {
    $('.accordion-head').on('click', e => {
      const target = $(e.target).parents(this.collapseItem).first();
      this.toggleVisible(target);
    });

    this.search.on('click', () => {
      const phrase = this.phrase.val();
      this.searchFaq((phrase as string));
    });

    this.phrase.on('keypress', e => {
      if (e.key == 'Enter') {
        const phrase = this.phrase.val();
        this.searchFaq((phrase as string));
      }
    });

    let search_string = Url.getQueryString('search');

    if (search_string != null) {
      search_string = search_string.replace(/[_\W]+/g, ' ');
      this.phrase.val(search_string);

      this.searchFaq(search_string);

      if (this.first_element !== null) {
        setTimeout( () => {
          $('html,body').scrollTop($(this.first_element).offset().top);
        }, 1500);
      }
    }
  }

  private searchFaq(phrase: string): void {
    this.first_element = null;
    let validate = new RegExp(/[`~!@#$%^&*()_|+\-=?;:",.<>{}[\]\\/]/, 'gi');
    let invalid = validate.test(phrase);

    if (this.highlighted === true) {
      this.cleanupHighlights();
    }

    if (invalid === true) {
      this.faqError.html('Phrase can not contain special characters').fadeIn();
      return;
    }

    if (phrase.length >= 2) {
      this.touched = true;
      this.collapseAll();
      const regexp = new RegExp('(' + phrase + ')(?![^<]*>)', 'gi');

      $('.faq-item-body, .faq-item-head').each((index, element) => {
        const content = element.innerHTML;
        const results = regexp.exec(content);

        if (results !== null) {
          this.highlighted = true;
          if (this.first_element === null) {
            this.first_element = element;
          }

          this.faqError.html('').fadeOut();
          $(element).html(content.replace(regexp, '<span class="highlight">$1</span>'));
          this.expandMultiple($(element).parents('.faq-category').first(), $(element).parents('.faq-item').first());
        }
      });

      if (!this.highlighted) {
        this.faqError.html('Nothing Found').fadeIn();
      }
    } else if (phrase.length == 0 && this.touched === true) {
      this.touched = false;
      this.cleanupHighlights();
    } else {
      this.faqError.html('Search Phrase has to have at least 2 characters').fadeIn();
    }
  }

  private cleanupHighlights(): void {
    this.highlighted = false;
    $(this.highlightClassName).each((index, element) => {
      element.outerHTML = element.innerHTML;
    });
  }

  // Collapse all accordion elements, calling .finish() to prevent animations from queuing
  private collapseAll(): void {
    $(this.faqBody).find(this.collapseItem).find('i').addClass(this.downClass).removeClass(this.upClass);
    $(this.faqBody).find(this.collapseBody).finish().slideUp(400);
  }

  private collapseSingle(target: JQuery): void {
    target.parents(this.collapseItem).children(this.collapseHead).find('i').addClass(this.downClass).removeClass(this.upClass);
    target.parents(this.collapseBody).finish().slideUp(400);
  }

  private expandSingle(target: JQuery): void {
    target.children(this.collapseHead).find('i').addClass(this.upClass).removeClass(this.downClass);
    target.children(this.collapseBody).finish().slideDown(400);
  }

  private expandMultiple(category: JQuery, faq: JQuery): void {
    this.expandSingle(category);
    this.expandSingle(faq);
  }

  private toggleVisible(target: JQuery): void {
    $(target).children(this.collapseHead).find('i').toggleClass(this.downClass).toggleClass(this.upClass);
    $(target).children(this.collapseBody).finish().slideToggle(400);
  }
}
