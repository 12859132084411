import { Injectable } from 'injection-js';
import { Element, State, TurboLinks } from 'shared/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Actions } from 'shared/state';
import { type } from 'jquery';

@Injectable()
export class DoubleClickProtectionElement extends Element {
  private formProtectionEnabled = true;

  constructor(
    private state: State,
    private turbolinks: TurboLinks
  ) {
    super();

    this.formProtectionEnabled = window.App.formProtection;

    // Move to Double Click Prevention Element
    if (this.formProtectionEnabled) {
      // At a page visit, any disabled containers need to be re-enabled
      this.turbolinks.visit$.subscribe(() => {
        $('.disabled-container').each((i, v) => {
          DoubleClickProtectionElement.autoDisableTrigger($(v), true);
        });
      });
    }
  }

  public init(): void {
    if (this.formProtectionEnabled) {
      this.state.actions$.pipe(
        filter(e => e.name === Actions.ENABLE_CONTAINER),
        takeUntil(this.turbolinks.visit$)
      ).subscribe(action => {
        DoubleClickProtectionElement.autoDisableTrigger(action.payload, true);
      });

      this.state.actions$.pipe(
        filter(e => e.name === Actions.DISABLE_CONTAINER),
        takeUntil(this.turbolinks.visit$)
      ).subscribe(action => {
        if (typeof action.payload.message != typeof undefined) {
          DoubleClickProtectionElement.autoDisableTrigger(action.payload.container, false, action.payload.message);
        } else {
          DoubleClickProtectionElement.autoDisableTrigger(action.payload);
        }
      });

      $('body').on('submit', 'form[data-auto-disable=true]', e => {
        let form = $(e.target).closest('form[data-auto-disable=true]');
        DoubleClickProtectionElement.autoDisableTrigger(form);
      }).on('click', 'a[data-auto-disable=true]', e => {
        let container = $(e.target).closest('a[data-auto-disable=true]');
        DoubleClickProtectionElement.autoDisableTrigger(container);
      });
    }
  }

  public disable(): void {
    this.formProtectionEnabled = false;
  }

  /**
   * Triggers the disabling/enabling of the container that the element is in.
   *
   * @param elementOrSelector JQuery element or string selector
   * @param enable If true, then the container will be re-enabled
   * @param message Optional message to display in the container
   * @private
   */
  private static autoDisableTrigger(elementOrSelector: JQuery|string, enable = false, message?: string): void {
    let element = null;

    if (typeof elementOrSelector != typeof $) {
      element = $(elementOrSelector as string);
    } else {
      element = elementOrSelector as JQuery;
    }

    let containerSelector = element.data('disableContainerSelector');
    let container = element;

    if (typeof containerSelector === 'string') {
      container = $(`${containerSelector}`);
    }

    if (enable) {
      DoubleClickProtectionElement.enableContainer(container);
    } else {
      DoubleClickProtectionElement.disableContainer(container, message);
    }
  }

  /**
   * Disable the "container" by putting a loading overlay and un-focusing any inputs that the user has selected.
   *
   * @param container
   */
  public static disableContainer(container: JQuery, message?: string, spinner = true): void {
    // Ensure that the user does not have the cursor in any input fields, which would allow them to press enter
    // and double submit anyway.
    document.querySelectorAll(':focus').forEach(el => (el as HTMLElement).blur());

    if (spinner) {
      let containerSpinner = container.find('> .disabled-container-spinner');

      if (containerSpinner.length === 0 && $('body.disabled-container').length == 0) {
        if (typeof message != typeof undefined) {
          container.prepend(`<div class="disabled-container-spinner"><div class="message"><i>${message}</i></div></div>`);
        } else {
          container.prepend('<div class="disabled-container-spinner"><div class="wrapper"><i class="fa fa-spinner fa-spin"></i></div></div>');
        }
      }
    }

    container.addClass('disabled-container');
  }

  public static enableContainer(container: JQuery): void {
    container.removeClass('disabled-container');
    container.find('>.disabled-container-spinner').remove();
  }

  public static async withDisable(container: JQuery, func: () => Promise<void> | void): Promise<void> {
    DoubleClickProtectionElement.disableContainer(container);
    await func();
    DoubleClickProtectionElement.enableContainer(container);
  }

  public static async withDisableNoSpinner(container: JQuery, func: () => Promise<void> | void): Promise<void> {
    DoubleClickProtectionElement.disableContainer(container, undefined, false);
    await func();
    DoubleClickProtectionElement.enableContainer(container);
  }

  public static disableBody(): void {
    this.disableContainer($('body'));
  }

  public static enableBody(): void {
    this.enableContainer($('body'));
  }
}
