export interface Personalisation {
  image_url: string;
  location: string;
  format: string;
  max_characters: string;
  price: string;
  pricing_unit: string;
}

export enum PersonalisationPricingOptions {
  perLetter = 'Per Letter',
  eachUnit = 'Each Unit',
}