import { Module } from 'shared/core';
import { Actions } from 'shared/state';

export class RedirectModule extends Module {
  init(): void {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    if (params.get('redirected') == 'true') {
      $('#user_email').val(params.get('email'));

      params.delete('redirected');
      params.delete('email');

      window.history.replaceState({}, '', window.location.pathname);

      let payload = {
        options: {
          title: 'Redirected',
          body: 'Oops ... you’re not in the right place. Do not worry; we have redirected you - please log in here, and update any bookmarks you may have.'
        }
      };


      window.App.state.dispatch({ name: Actions.OPEN_ALERT_MODAL, payload: payload });
    }
  }
}
