export enum Events {
  // Payload: String - order total value
  TOTAL_CHANGED = 'TOTAL_CHANGED',

  // Payload: Object - Initial map data
  GOOGLE_MAP_LOADED = 'GOOGLE_MAP_LOADED',

  // Payload: String - The ID of the modal opened
  MODAL_OPENED = 'MODAL_OPENED',

  // Payload: String - The ID of the modal closed
  MODAL_CLOSED = 'MODAL_CLOSED',

  // Payload: Undefined
  FONTS_LOADED = 'FONTS_LOADED',

  // Payload: Undefined
  STOCK_REQUEST_SUBMITTED = 'STOCK_REQUEST_SUBMITTED',

  // Payload: Object
  //   - form: JQuery - form element
  //   - title (optional): String - input title title
  //   - input (optional): JQuery - input element
  DEBOUNCE_SUBMIT = 'DEBOUNCE_SUBMIT',

  // Payload: Object
  // - object: {} - the object being selected
  // - selected: Boolean - is it selected
  MODEL_COMPONENT_SELECTED_CHANGE = 'MODEL_COMPONENT_SELECTED_CHANGE',

  // Payload: String - The key of the modal being opened
  OPEN_JSX_MODAL = 'OPEN_JSX_MODAL',

  // Payload: String - The key of the modal being closed
  CLOSE_JSX_MODAL = 'CLOSE_JSX_MODAL',

  // Payload: JQuery - the changed element
  ACCORDION_STATE_CHANGE = 'ACCORDION_STATE_CHANGE',

  // Payload: Undefined
  ORDER_MANAGEMENT_FILTER_CHANGED = 'ORDER_MANAGEMENT_FILTER_CHANGED',

  // Payload: undefined
  MODEL_COMPONENT_ELEMENT_VISIBILITY_CHANGED = 'MODEL_COMPONENT_ELEMENT_VISIBILITY_CHANGED',

  // Payload:
  // - object: Object - the sender
  // - type: String - an identifier for the senders type
  MODEL_COMPONENT_DISPOSE = 'MODEL_COMPONENT_DISPOSE',

  // Payload: JQuery - the element that is now visible
  ELEMENT_SHOWN = 'ELEMENT_SHOWN',

  // Payload: undefined
  ORDER_CHANGED = 'ORDER_CHANGED',

  // Payload: undefined
  ORDER_ITEM_CHANGED = 'ORDER_ITEM_CHANGED',

  // Payload: Object
  // - order_item_id: Integer - Order item id for filtering
  // - return_item: {} - The return item
  RETURN_QUANTITY_CHANGED = 'RETURN_QUANTITY_CHANGED',

  // Payload: boolean
  RETURN_CONFIRM_VALIDATED = 'RETURN_CONFIRM_VALIDATED',

  // Payload: return_item_id
  RETURN_ITEM_CHANGED = 'RETURN_ITEM_CHANGED',

  PO_UPDATED = 'PO_UPDATED',

  // Payload: HTMLElement|HTMLElement[]
  JSX_RENDERED = 'JSX_RENDERED',

  // Payload: ?? - the ajax response
  TYPE_AHEAD_DATA_LOADED = 'TYPE_AHEAD_DATA_LOADED',

  // Payload: PoShipment
  PO_SHIPMENT_UPDATED = 'PO_SHIPMENT_UPDATED',

  // Payload: PoShipmentItem[]
  PO_SHIPMENT_ITEMS_ADDED = 'PO_SHIPMENT_ITEMS_ADDED',

  // Payload: undefined
  STOCK_DEMANDS_CANCELLED = 'STOCK_DEMANDS_CANCELLED',

  // Payload: Object[]
  // - id: number
  // - our_reference: string
  // - supplier_id: number
  // - effective_id: string
  // - item_count: number
  // - supplier: string
  // - job_running: boolean
  // - job_error: string
  STOCK_DEMAND_POS_UPDATED = 'STOCK_DEMAND_POS_UPDATED',

  // Payload: undefined
  STOCK_DEMANDS_FULFILLED = 'STOCK_DEMANDS_FULFILLED',

  // Payload: Fabric Ledger Movement Object
  FABRIC_LEDGER_MOVEMENT_UPDATED = 'FABRIC_LEDGER_MOVEMENT_UPDATED',

  // Payload: Object
  // - full_sku_code: string
  PRODUCT_NODE_CLICKED = 'PRODUCT_NODE_CLICKED',
}
