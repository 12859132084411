import 'popper.js';
import 'bootstrap/js/dist/tooltip';
import { Injectable } from 'injection-js';
import { Element } from 'shared/core';

@Injectable()
export class TooltipElement extends Element {
  constructor() {
    super();
  }

  private elements: JQuery = $('[title]:not(area)');

  public init(): void {
    this.elements.tooltip({
      sanitize: false
    });
  }
}
