import { Action } from 'shared/models';
import { State, Url } from 'shared/core';
import { Actions } from 'shared/state';

export interface TabsConfig {

  id: string;

  /* Are tabs fetched from the server dynamically? */
  remote: boolean;

  /* Pass GET method for tab */
  get?: (id: number) => Promise<Document>;

  /* Use it to set active tab based on url */
  queryParam?: string;
  effects?: Action[];
}

export class Tabs {
  public tabs: JQuery;
  public container: JQuery;
  public contents: JQuery;
  private state: State;

  constructor(
    private config: TabsConfig
  ) {
    this.state = window.App.getProvider(State);
    this.tabs = $(`.${config.id}-tab`);
    this.container = $(`.${config.id}-container`);
    this.contents = $(`.${config.id}-content`);
    this.attachHandlers();
    const id = Url.getQueryString(config.queryParam || 'id');

    if (id) {
      this.setTab(+id);
    } else {
      // Find the first tab that has an active class that is not disabled
      let item = this.tabs.not('.disabled').filter('.active').first().data('tab');

      // Find the first tab is not disabled if an active one is not found
      if (!item) {
        item = this.tabs.not('.disabled').first().data('tab');
      }

      if (item) {
        this.setTab(item);
      }
    }
  }

  public getActiveTab(): JQuery<HTMLElement> {
    return this.tabs.filter('.active').first();
  }

  public getActiveTabContents(): JQuery<HTMLElement> {
    return this.contents.filter('.active').first();
  }

  private async setTab(id: number): Promise<void> {
    if (this.config.remote) {
      if (!await this.fetch(id)) {
        return;
      }
    } else {
      this.contents.removeClass('active');
      this.contents.filter(`[data-tab="${id}"]`).addClass('active');
      window.App.display.showElement(this.contents.filter(`[data-tab="${id}"]`));
    }

    this.tabs.removeClass('active');
    this.tabs.filter(`[data-tab="${id}"]`).addClass('active');
  }

  private render(results: Document): void {
    this.container.html(results);
    if (this.config.effects) {
      this.config.effects.forEach(action => {
        this.state.dispatch(action);
      });
    }

    let buttons = this.container.find('.stackable-buttons');
    this.state.dispatch({ name: Actions.STACK_BUTTONS, payload: buttons });
  }

  private attachHandlers(): void {
    this.tabs.on('click', (e) => {
      const elem = $(e.target).closest('.tabs-menu-item');

      if (!elem.is('.disabled')) {
        this.setTab(elem.data('tab'));
      }
    });
  }

  private async fetch(id: number): Promise<boolean> {
    const results = await this.config.get(id);

    if (typeof results !== typeof undefined) {
      this.render(results);
      return true;
    } else {
      return false;
    }
  }

}
