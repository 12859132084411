import { Module } from 'shared/core';
import { Injectable } from 'injection-js';

@Injectable()
export class PaymentAuthenticationModule extends Module {
  private authenticationForm: JQuery = $('#authentication-form');

  public init(): void {
    this.authenticationForm.trigger('submit');
  }
}
