import { Injectable } from 'injection-js';
import { Module, Http, State } from 'shared/core';
import { Flash } from 'shared/lib';
import { Actions } from 'shared/state';

@Injectable()
export class DeliveryOptionsModule extends Module {
  private deliveryOptionsFormClass = '.delivery_options';
  private saveDeliveryOptionsButtonClass = '.save-delivery-option';

  constructor(
    protected http: Http,
    protected state: State,
  ) {
    super();
  }

  public init(): void {
    $('body').on('click', this.saveDeliveryOptionsButtonClass, e => {
      e.preventDefault();

      let deliveryOptionsForm = $(this.deliveryOptionsFormClass);
      if (typeof deliveryOptionsForm === typeof undefined || deliveryOptionsForm.length == 0) {
        this.state.dispatch({ name: Actions.CLOSE_MODAL });
        return;
      }

      this.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: $('.checkout') });
      let selectedValue = $('[name="delivery_options[courier_product_country_id]"]:checked').val();

      if (typeof selectedValue === typeof undefined || selectedValue == '') {
        Flash.genericAlert('Please select delivery option');
        this.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: $('.checkout') });
        this.state.dispatch({ name: Actions.CLOSE_MODAL });
        return;
      }

      deliveryOptionsForm.trigger('submit');
    });

    $('body').on('click', '.delivery-option', (e) => {
      $('.options li').removeClass('active');
      $(e.target).closest('li').addClass('active');
      $(e.target).find('input[type="radio"]').prop('checked', true);
    });
  }
}
