import { Element, State, Http, Module } from 'shared/core';
import { Injectable } from 'injection-js';
import { filter } from 'rxjs/operators';
import { Actions } from 'shared/state';
import { Flash } from 'shared/lib';

@Injectable()
export class AjaxActionLinkElement extends Element {
  constructor(
    private state: State,
    private http: Http
  ) {
    super();
  }

  public init(): void {
    $('body').on('click', 'a.ajaxActionLink', event => this.linkClicked(event));
  }

  private async linkClicked(event: JQuery.ClickEvent): Promise<void> {
    event.preventDefault();
    let url = $(event.target).prop('href');
    const response = await this.http.post(url, window.App.newFormData());
    Flash.fromAjaxRequest(response);
  }
}
