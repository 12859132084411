import { Injectable } from 'injection-js';
import { Module } from 'shared/core';
import { TypeAheadList } from 'shared/lib';

@Injectable()
export class ContactUsModule extends Module {
  private schoolSelect: JQuery = jQuery('#custom_enquiry_school');

  public init(): void {
    TypeAheadList.withAjaxSource(this.schoolSelect, '/custom_enquiries/schools');
  }

  public cleanup(): void {
    $('.select2-container').remove();
  }
}
