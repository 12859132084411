import { Http, Module, State } from 'shared/core';
import { Validators } from 'webshop/core';
import { Tabs } from 'shared/lib';
import { CardDetails, CardType, CardValidation, PayPalValidation } from 'webshop/models';
import { Actions } from 'shared/state';
import { Injectable } from 'injection-js';

@Injectable()
export class PaymentModule extends Module {
  private submit:              JQuery = $('.submit-payment-form');
  private cardForm:            JQuery = $('#card_details_form');
  private cardName:            JQuery = $('#card_details_form #payment_name');
  private cardNumber:          JQuery = $('#card_details_form #payment_card_number');
  private cardCSC:             JQuery = $('#card_details_form #payment_card_security_code');
  private cardIssueNumber:     JQuery = $('#card_details_form #payment_issue_number');
  private cardExpiryMonth:     JQuery = $('#card_details_form #payment_expiry_date_month');
  private cardExpiryYear:      JQuery = $('#card_details_form #payment_expiry_date_year');
  private cardValidFromMonth:  JQuery = $('#card_details_form #payment_start_date_month');
  private cardValidFromYear:   JQuery = $('#card_details_form #payment_start_date_year');
  private agreeTerms:          JQuery = $('#card_details_form #terms_and_conditions');
  private payPalForm:          JQuery = $('#paypal_form');
  private terms                = '.terms_and_conditions';
  private validationContent:   JQuery = $('ul#payment-validation-messages');
  private tabs:                Tabs;
  private paymentRequired:     boolean = $('.checkout-payment-td-details').data('payment-status') == 1;

  constructor(
    private state: State,
    private http: Http
  ) {
    super();
  }

  public init(): void {
    this.tabs = new Tabs({
      id: 'payment-forms',
      remote: false,
    });

    this.submit.on('click', () => this.submitForm());
  }

  private submitForm(): void {
    let tab = this.tabs.getActiveTab().data('tab');
    let result;

    if (!this.paymentRequired) {
      result = this.validateNoPaymentForm();
    } else if (tab == 'payment-forms-card') {
      // Validate the card form
      result = this.validateCardForm();
    } else if (tab == 'payment-forms-smartpay') {
      // Validate the card form
      result = this.validateSmartPayForm();
    } else {
      // Validate the form
      result = this.validatePayPalForm();
    }

    this.handleValidationResult(result, tab);
  }

  private handleValidationResult(result, tab): void {
    if (!result.result) {
      this.validationContent.empty();
      this.validationContent.append(
          result.messages.map(message => $('<li>').append(message))
      );

      this.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: $('.checkout') });
      this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'payment_validation' });
    } else {
      this.submit.prop('disabled', true);
      if (tab == 'payment-forms-paypal') {
        this.payPalForm.trigger('submit');
      } else {
        this.cardForm.trigger('submit');
      }
    }
  }

  private validateCardForm(): CardValidation {
    const result = Validators.creditCard(this.cardDetails());
    return result;
  }

  private validatePayPalForm(): PayPalValidation {
    const messages: string[] = [];

    if (this.payPalForm.length == 0) {
      messages.push('Please contact customer services for a refund.');
    } else {
      this.validateTerms(this.payPalForm, messages);
    }

    return { result: messages.length == 0, messages: messages };
  }

  private validateNoPaymentForm(): PayPalValidation {
    const messages: string[] = [];
    this.validateTerms(this.cardForm, messages);
    return { result: messages.length == 0, messages: messages };
  }

  private validateSmartPayForm(): PayPalValidation {
    const messages: string[] = [];
    this.validateTerms(this.cardForm, messages);

    return { result: messages.length == 0, messages: messages };
  }

  private validateTerms(form: JQuery, messages: string[]): void {
    if (!form.find(this.terms).is(':checked')) {
      messages.push('You must accept our Terms and Conditions');
    }
  }

  // Method for turning the card payment form into a CardDetails object
  private cardDetails(): CardDetails {
    return {
      name: <string>this.cardName.val(),
      card_number: <string>this.cardNumber.val(),
      expiry_month: <number>this.cardExpiryMonth.val(),
      expiry_year: <number>this.cardExpiryYear.val(),
      valid_from_month: <number>this.cardValidFromMonth.val(),
      valid_from_year: <number>this.cardValidFromYear.val(),
      csc: <string>this.cardCSC.val(),
      terms_agreed: <boolean>this.agreeTerms.is(':checked'),
      issue_number: <number>this.cardIssueNumber.val(),
    };
  }

}
