import { Injectable } from 'injection-js';
import { Element } from 'shared/core';
import { Flash } from 'shared/lib';

@Injectable()
/**
 * This element only instantiates a flash component for the page, which causes any .flash container to come under
 * the control of a Flash Component, which will auto-show, hide then remove the flash messages for a period of time.
 */
export class FlashElement extends Element {
  constructor(){
    super();
  }

  public init(): void {
    Flash.forContainer();
    try {
      Flash.fromAjaxRequest(window.App.state.state$.value);
      window.App.register({ flash: null });
    } catch(e) {}
  }
}
