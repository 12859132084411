export enum ReturnReasons {
  DEFAULT = 'select reason',
  FAULTY = 'FLTY',
  OUT_OF_STOCK = 'OOS'
}

export enum Quantity {
  DEFAULT = 'select quantity',
}

export enum ReturnActions {
  DEFAULT = 'select action',
  EXCHANGE_ITEM = 'Exchange Item',
  REPLACE_ITEM = 'Replace Item'
}

export enum ReturnSizes {
  DEFAULT = 'select size',
  WSTS = 'no larger sizes available',
  WSTT = 'no larger sizes available',
  WSTL = 'no smaller sizes available',
  WSTG = 'no smaller sizes available',
  OOS = 'no sizes available',
}
