import 'ddslick/dist/jquery.ddslick.min';

export interface FancySelectConfig {
  height?: number,
  width?: number,
  background?: string,
  onSelected?: (e) => void,
}

export class FancySelect {
  public element: JQuery;
  private newId: string;
  private clonedElement: JQuery;
  private options: FancySelectConfig = {
    height: 280,
    width: 320,
  };

  constructor(
    element: JQuery,
    options?: FancySelectConfig,
  ){
    this.element = element;
    this.options = { ...this.options, ...options };
    this.init();
  }

  private init(): void {
    // Just adding .ddslick(this.options) to a element will replace it completely so to enable back navigation through the browser
    //  we need to clone the select element and attach the ddslick to this and hide the original.

    // Get the id from the select
    this.newId = this.element.find('select').attr('id') + '_ddslick';

    // Remove any existing ddslick objects attached to this element
    $('#' + this.newId).remove();

    // Clone the element
    this.clonedElement = this.element.clone();

    // Add the id so that we can remove it if needed
    this.clonedElement.attr('id', this.newId);

    // Append it to the parent
    this.clonedElement.appendTo(this.element.parent());

    // Attach the ddslick
    (this.clonedElement as any).ddslick(this.options);

    // Hide the origional select element
    this.element.hide();
  }

}
