import { fromEvent, BehaviorSubject } from 'rxjs';
import { share, debounceTime, tap, filter } from 'rxjs/operators';
import { Injectable } from 'injection-js';
import { MobileData } from 'shared/models';
import { Actions, Events } from 'shared/state';
import { State } from 'shared/core/state';

const MOBILE_BREAKPOINT = 992;

@Injectable()
export class Display {
  private readonly isMobileAgent: boolean = /iPhone|iPad|iPod|Android|iOS/i.test(navigator.userAgent);

  public readonly resize$ = fromEvent(window, 'resize').pipe(
    debounceTime(200),
    tap(() => this.checkMobile()),
    share(),
  );

  constructor(private state: State) {
    this.resize$.subscribe();

    this.state.actions$.pipe(filter(action => action.name === Actions.BODY_PREVENT_SCROLL)).subscribe(() => {
      if (this.isMobile$.value.resolution)
        jQuery('body, .main, .main .content').css('overflow', 'hidden');
    });

    this.state.actions$.pipe(filter(action => action.name === Actions.BODY_ALLOW_SCROLL)).subscribe(() => {
      jQuery('body, .main, .main > .content').css('overflow', 'initial');
    });

    this.resize$.pipe().subscribe(() => {
      if (!this.isMobile$.value.resolution)
        jQuery('body, .main, .main > .content').css('overflow', '');
    });
  }

  public readonly isMobile$: BehaviorSubject<MobileData> = new BehaviorSubject({
    agent: this.isMobileAgent,
    resolution: window.innerWidth < MOBILE_BREAKPOINT,
  });

  private checkMobile(): void {
    this.isMobile$.next({
      agent: this.isMobileAgent,
      resolution: window.innerWidth < MOBILE_BREAKPOINT,
    });
  }

  public showElements(elements: JQuery): void {
    elements.each((index, element) => this.showElement($(element)));
  }

  public hideElements(elements: JQuery): void {
    elements.each((index, element) => this.hideElement($(element)));
  }

  public showElement(element: JQuery): void {
    element.show();
    this.state.dispatch({ name: Actions.STACK_BUTTONS, payload: element.find('.stackable-buttons') });
    this.state.dispatch({ name: Events.ELEMENT_SHOWN, payload: element });

    element[0].querySelectorAll('[event-onrender]').forEach((elem) => {
      const renderEvent = new CustomEvent('render', { detail: { element: elem } });
      elem.dispatchEvent(renderEvent);
    });
  }

  public hideElement(element: JQuery): void {
    element.hide();
  }
}
