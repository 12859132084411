export class Toasts {
  public static addToast(message: string, type: 'message'|'alert'|'stay_alert'|'notice' = 'message'): void {
    let toast = $(`<div class="toast-${type}">${message}<div class="toast-close">x</div></div>`);
    if (type == 'stay_alert') {
      toast.addClass('stay_alert');
    }

    toast.on('click', '.toast-close', e => {
      toast.remove();
    });

    $('.toast-alerts').append(toast);

    let timeout = Toasts.timeoutForToast(toast);

    toast.on('mouseover', () => {
      clearTimeout(timeout);
    });

    toast.on('mouseout', () => {
      timeout = Toasts.timeoutForToast(toast);
    });
  }

  private static timeoutForToast(toast): any {
    return setTimeout(() => {
      toast.remove();
    }, 7500);
  }

  public static fromAjaxRequest(response: unknown): { notices: boolean, alerts: boolean, stay_alerts: boolean } {
    let ret = { notices: false, alerts: false, stay_alerts: false };

    if (typeof response['flash'] !== 'object') {
      return ret;
    }

    let notices = Toasts.cleanMessage(response['flash']['notice']);
    let alerts = Toasts.cleanMessage(response['flash']['alert']);
    let stay_alerts = Toasts.cleanMessage(response['flash']['stay_alert']);

    for(let alert of alerts) {
      Toasts.addToast(alert, 'alert');
    }

    for(let alert of stay_alerts) {
      Toasts.addToast(alert, 'stay_alert');
    }

    for(let notice of notices) {
      Toasts.addToast(notice, 'notice');
    }

    return { notices: notices?.length > 0, alerts: alerts?.length > 0, stay_alerts: stay_alerts?.length > 0 };
  }

  private static cleanMessage(response: string|string[]|null): string[] {
    if (typeof response === typeof undefined || response == null) {
      return [];
    }

    if (typeof response === 'string') {
      response = (response as string).trim();

      if (response.length > 0) {
        return [response];
      } else {
        return [];
      }
    }

    // At this point, we can only assume the message is an array of strings.  If it is not, this will likely
    // result in undefined behaviour.
    let cleaned: string[] = Array<string>();

    for(let message of response) {
      if (typeof message === 'string') {
        message = message.trim();

        if (message.length > 0) {
          cleaned.push(message);
        }
      }
    }

    return cleaned;
  }
}
