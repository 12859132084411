import { ReflectiveInjector, Type } from 'injection-js';
import { ReflectiveDependency, ResolvedReflectiveProvider, ResolvedReflectiveFactory } from 'injection-js/reflective_provider';
import { Route, Router } from './router';
import { Element } from './element';
import { Action, IState } from 'shared/models';
import { State } from './state';
import { TurboLinks } from './turbolinks';
import { Flash } from 'shared/lib';
import { Display } from 'shared/core';
import { RenderableView } from 'shared/fender_render';

export interface AppConfig {
  providers: Type[];
  elements: Type<Element>[];
  routes: Route[];
  useTurbolinks?: boolean;
  preferToasts?: boolean;
  notifyOnSlowLoads?: boolean;
}

export class App {
  public injector: ReflectiveInjector;
  public router: Router;
  public state: State;

  private readonly routes: Route[];
  private elementsToResolve: Type<Element>[];
  private elementsToDisable: Type<Element>[] = [];
  private resolvedElements: Element[];
  public disabledElements: Element[];
  private turbolinks: TurboLinks = null;

  public get display(): Display {
    return this.getProvider(Display);
  }

  public regionAware = true;
  public useTurbolinks = false;
  public formProtection = true;
  public preferToasts = false;
  public notifyOnSlowLoads = false;

  constructor(config: AppConfig) {
    this.createAppInjector(config.providers);
    this.routes = config.routes;
    this.elementsToResolve = config.elements;
    this.turbolinks = this.injector.get(TurboLinks);

    if (config.useTurbolinks !== false) {
      this.useTurbolinks = true;
      this.turbolinks.createWithTurbolinks();
    } else {
      this.turbolinks.createWithoutTurbolinks();
    }

    this.preferToasts = !!config.preferToasts;
    this.notifyOnSlowLoads = !!config.notifyOnSlowLoads;
  }

  public run(): void {
    this.createRouter();
  }

  public makeClass<T>(klass: Type<T>): T {
    const dependencies: ReflectiveDependency[] = ReflectiveInjector.resolve([klass])
      .reduce((a, x: ResolvedReflectiveProvider) => a.concat(x.resolvedFactories), [])
      .reduce((a, r: ResolvedReflectiveFactory) => a.concat(r.dependencies), []);

    const deps = dependencies.map(d => this.injector.get(d.key.token));
    return Reflect.construct(klass, deps);
  }

  public register(state: IState): void {
    this.state.register(state);
  }

  public dispatch(action: Action): void {
    this.state.dispatch(action);
  }

  private createAppInjector(providers): void {
    this.injector = ReflectiveInjector.resolveAndCreate(providers);
    this.state = this.injector.get(State);
  }

  private createRouter(): void {
    this.router = this.injector.get(Router);
    this.router.create(this.routes, this.useTurbolinks);
  }

  public async createElements(disabledElements: Type<Element>[]): Promise<void> {
    let start = Date.now();

    this.resolvedElements = [];
    this.disabledElements = [];
    this.elementsToDisable = disabledElements;
    let promises = [];

    for (const element of this.elementsToResolve) {
      const module = this.makeClass(element);

      if (this.elementsToDisable?.includes(element)) {
        promises.push(module.disable());
        this.disabledElements.push(module);
      } else {
        promises.push(module.init());
        this.resolvedElements.push(module);
      }
    }

    await Promise.all(promises);
    let bodySpinners = document.querySelectorAll('body>.loading-container-spinner');

    if (bodySpinners.length >= 1) {
      bodySpinners.item(0).remove();
    }

    let end = Date.now();
    let diff = end - start;

    if (diff > 2500 && this.notifyOnSlowLoads) {
      // Intentional console.log
      console.log(`Slow page init, took ${diff}ms`);
    }
  }

  public getProvider<T>(klass: Type<T>) : T {
    return this.injector.get(klass);
  }

  public newFormData() : FormData {
    let formData = new FormData();

    // Add auth token
    let param_name = $('meta[name="csrf-param"]').attr('content');
    let auth_token = $('meta[name="csrf-token"]').attr('content');

    formData.append(param_name, auth_token);

    return formData;
  }

  // Global error function - tell the user <something> has gone wrong
  // - call it with:
  // App.catchError(error);
  public static showError(e: unknown, message?: string): void {
    // Tell the user about the error
    Flash.genericAlert(message || 'Sorry, we encountered a problem with that request. Please refresh and try again');
    // Tell the devs about the error
    console.log(e);
  }
}
