import { Module, State } from 'shared/core';
import { Injectable } from 'injection-js';
import { Actions } from 'shared/state';
import { AjaxForm, Flash } from 'shared/lib';

@Injectable()
export class StockRequestModule extends Module {
  constructor(
    private state: State
  ) {
    super();
  }

  public init(): void {
    let form = AjaxForm.createGeneral('#confirm_email_request_form', response => this.formSubmitted(response));
    form.loadFlashToPage = true;
  }

  private formSubmitted(response: any): void {
    window.App.register({flash: {
      alert: response['flash']['alert'] as string|string[], notice: response['flash']['notice'] as string|string[]
    }});

    this.state.dispatch({ name: Actions.CLOSE_MODAL, payload: 'confirmEmailModal' });
    this.state.dispatch({ name: Actions.STOCK_REQUEST_SUBMITTED });
  }
}
