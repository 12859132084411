import { Injectable } from 'injection-js';
import { Module, Http, State, TurboLinks } from 'shared/core';
import { FlexTable } from 'shared/lib';
import { Actions } from 'shared/state';

@Injectable()
export class RepairsConfirmModule extends Module {

  constructor(
    public http: Http,
    public state: State,
    public turbolinks: TurboLinks,
  ) {
    super();
  }

  public init(): void {
    new FlexTable('sm');

    $('body').on('change', '#acceptTerms', (e: JQuery.ChangeEvent) => {
      $('#confirmRepairSubmit').prop('disabled', !e.target.checked);
    });

    $('body').on('click', '.confirmRepairOpen', (e: JQuery.ClickEvent) => {

      let form_elem = $('#repair-data')[0] as HTMLFormElement;

      let valid = true;
      if (typeof form_elem.checkValidity === typeof Function && !form_elem.checkValidity()) {

        valid = false;
        if (form_elem.reportValidity) {
          valid = form_elem.reportValidity();
        }
      }

      if (valid) {
        this.state.dispatch({ name: Actions.OPEN_DYNAMIC_MODAL, payload: { url: e.target.dataset.ajax } });
      }
    });



    $('body').on('submit', '#confirm_repair_form', e => {
      e.preventDefault();
      $('#repair-data').trigger('submit');
      this.state.dispatch({ name: Actions.CLOSE_MODAL });
    });
  }
}
