import { Injectable } from 'injection-js';
import { Module, Http, State, TurboLinks } from 'shared/core';
import { FlexTable } from 'shared/lib';
import { Actions, Events } from 'shared/state';
import { DhlReturns, DpdReturns } from 'shared/lib';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class OnlineReturnsConfirmModule extends Module {
  private suppressEmailsCheckbox: JQuery = $('#suppressEmails');
  private suppressEmailsInput: JQuery = $('#suppress_emails');
  private notExpectedBack: JQuery = $('#notExpectedBack');
  private notExpectedBackInput: JQuery = $('#not_expected_back');
  private chargeBalanceTooltip: JQuery = $('.chargeBalanceTooltip');
  private confirmReturnOpen: JQuery = $('.confirmReturnOpen');
  private contactName: JQuery = $('#return_order_delivery_contact_name')

  constructor(
    public http: Http,
    public state: State,
    public turbolinks: TurboLinks,
  ) {
    super();
  }

  public init(): void {
    new FlexTable('sm');

    this.handleContactName();

    this.state.actions$.pipe(
        filter(e => e.name === Events.RETURN_CONFIRM_VALIDATED),
        takeUntil(this.turbolinks.visit$)
    ).subscribe(action => this.toggleOpen(action.payload));

    const courier_name = $('#courier_name').val();

    if (courier_name == 'DHL') {
      this.initDHL();
    } else if (courier_name == 'DPD') {
      this.initDPD();
    }

    $('body').on('change', '#acceptTerms', (e: JQuery.ChangeEvent) => this.toggleConfirm(e.target.checked));
    $('body').on('change', '.address-select', e => this.changeAddress(e));
    $('body').on('submit', '#confirm_return_form', e => this.confirmReturnFormSubmit(e));
    this.chargeBalanceTooltip.tooltip();
  }

  private enterContactName(e): void {
    if (e.target.value.length > 0) {
      this.toggleOpen(true);
    } else {
      this.toggleOpen(false);
    }
  }

  private initDHL(): void {
    new DhlReturns(this.state, this.http);
  }

  private initDPD(): void {
    new DpdReturns(this.state, this.http);
  }

  private toggleConfirm(valid: boolean): void {
    $('#confirmReturnSubmit').prop('disabled', !valid);
  }

  public toggleOpen(valid: boolean): void {
    this.confirmReturnOpen.prop('disabled', !valid);
  }

  private handleContactName(): void {
    if (this.contactName.length > 0) {
      let input = this.contactName[0] as HTMLInputElement;
      this.toggleOpen(input.value.length > 0);
      this.contactName.keyup((e) => {
        this.enterContactName(e);
      });
    }
  }

  private confirmReturnFormSubmit(e) {
    e.preventDefault();
    this.suppressEmailsInput.val($(this.suppressEmailsCheckbox).is(':checked').toString());
    this.notExpectedBackInput.val($(this.notExpectedBack).is(':checked').toString());
    $('#return-data').trigger('submit');
    this.state.dispatch({ name: Actions.CLOSE_MODAL });
  }

  private async changeAddress(e): Promise<void> {
    e.preventDefault();

    let select = $('.address-select');
    let address = select.val().toString();

    let formData = new FormData();

    if (address.length > 0) {
      formData.append('address', address);

      const token = $("#return-data input[name*='authenticity_token']").val();
      formData.append('authenticity_token', token.toString());

      window.App.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: $('#pick_up') });

      const results: any = await this.http.post(select.data('url'), formData);

      $('.pick-up-address').replaceWith(results);

      let buttons = $('.pick-up-address .stackable-buttons');
      this.state.dispatch({ name: Actions.STACK_BUTTONS, payload: buttons });

      window.App.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: $('#pick_up') });
      window.App.state.dispatch({ name: Actions.PICK_UP_ADDRESS_CHANGED });
    }
  }
}
