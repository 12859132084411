import { Module } from 'shared/core';
import { Tabs } from 'shared/lib';
import { Injectable } from 'injection-js';

@Injectable()
export class CheckoutModule extends Module {

  public init(): void {
    new Tabs({
      id: 'child-details',
      remote: false,
    });
  }
}
