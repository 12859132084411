import { Injectable } from 'injection-js';
import { Http, Module, State } from 'shared/core';
import { FlexTable } from 'shared/lib';
import { RoyalMailQrCode } from 'shared/elements';

@Injectable()
export class RepairsModule extends Module {
  constructor(
    private http: Http,
    private state: State
  ) {
    super();
  }

  public init(): void {
    new FlexTable('sm');
    new RoyalMailQrCode(this.http, this.state);
  }
}
