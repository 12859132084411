import { Injectable } from 'injection-js';
import { Http, Module, State, Url, TurboLinks } from 'shared/core';
import { Actions, Events } from 'shared/state';
import { filter } from 'rxjs/operators';

@Injectable()
export class ChangeAddressModule extends Module {
  private addressBar = '.shop-address-bar';
  private addressBarContent = '.address-bar-content';
  private addressConfirmOkButton = '.ok-confirm-changed';
  private addressSelectSubmit = '.addressSelectSubmit';

  constructor(private state: State, private http: Http, private turboLinks: TurboLinks) {
    super();
  }

  public init(): void {
    $('body').on('click', this.addressBar, e => {
      this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'addressBarModal' });
    });

    this.state.actions$.pipe(
      filter(e => e.name === Events.MODAL_OPENED && e.payload == 'addressBarModal')
    ).subscribe(() => {
      $('#change_shipping_address_back_to').val(window.location.href);
    });

    $('body').on('click', this.addressBarContent, e => {
      e.preventDefault();
    });

    $('body').on('click', this.addressConfirmOkButton, e => {
      this.remove_tax_jurisdiction_changed_param();
    });

    $('body').on('change', '#change_shipping_address_shipping_address', async e => {
      let address = $(e.target).val();
      let context = $('#change_shipping_address_context').val();
      let back_to = $('#change_shipping_address_back_to').val();
      let html = await this.http.getHtml('/order/shop_change_address', { address: address, context: context, back_to: back_to });

      $('.address-change-form').replaceWith(html);
      window.App.display.showElement($('.address-change-form'));
    });

    if($('.checkout-change-address').length > 0) {
      this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'confirmChangeAddress' });
    }

    $('body').on('click', this.addressSelectSubmit, e => {
      if ($('#display_basket_warning').val() == 'true') {
        e.preventDefault();
        this.confirmChangeAddress(e.target);
      }
    });
  }

  private confirmChangeAddress(element: JQuery): void {
    let payload = {
      options: { title: 'Change address?', body: 'Changing the shipping address to another country may cause items in your basket to be removed. Would you like to continue?', keep_open: true },
      confirmed: () => {
        $(element).closest('form').trigger('submit');
      }
    };

    this.state.dispatch({ name: Actions.OPEN_CONFIRMATION_MODAL, payload: payload });
  }

  private remove_tax_jurisdiction_changed_param(): void {
    this.turboLinks.visit(Url.removeUrlParam(window.location.href, 'shipping_tax_jurisdiction_changed'));
  }
}
