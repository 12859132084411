export class StringHelper {
  public static takeFromOccurrence(str: string, seg: string, n: number): string {
      let i = 0;

      while (i < n) {
       str = str.substr(str.indexOf(seg) + 1);
       i++;
      }

      return str;
  }
}
