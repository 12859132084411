import { Element, Http, State, TurboLinks } from 'shared/core';
import { Injectable } from 'injection-js';
import { StringHelper } from 'webshop/helpers';
import { filter, takeUntil } from 'rxjs/operators';
import { Actions } from 'shared/state';
import { AjaxForm } from 'shared/lib';

@Injectable()
export class BasketElement extends Element {
  private basket: JQuery = $('#basket');
  private basketContent: JQuery = $('#basket-content');
  private basketTrigger: JQuery = $('#basket-trigger');
  private basketItems: JQuery = $('#basket-items');
  private basketTotal: JQuery = $('#basket-total');
  private closeBasketTriggerId = '#basketClose';
  private static openClass = 'open-right';
  private renderBasket = true;

  constructor(
    private http: Http,
    private state: State,
    private turbolinks: TurboLinks,
  ) {
    super();

    if($('body').hasClass('ignore_basket')) {
      this.renderBasket = false;
    } else {
      const body = $('body');
      body.on('click', this.closeBasketTriggerId, () => this.closeBasket());
      body.on('click', '.removeAll', e => this.confirmRemoveAllOrderItemsForChild($(e.target)));
      body.on('click', '#basket-trigger', () => this.toggleBasket());

      AjaxForm.createGeneral('.removeOrderItem', results => {
        this.render(results);
      });

      AjaxForm.createGeneral('.orderItemQuantity', results => {
        this.render(results);
      });
    }
  }

  public async init(): Promise<void> {
    if (!this.renderBasket) {
      return;
    }

    this.state.get.pipe(
      filter(e => e.basketData !== null),
      takeUntil(this.turbolinks.visit$),
    ).subscribe(({ basketData }) => {
      this.updateTile(basketData);
    });

    await this.fetch();
  }

  private async fetch(): Promise<void> {
    const results = await this.http.getHtml('order', {
      partial_only: true,
    });

    this.render(results);
  }

  private confirmRemoveAllOrderItemsForChild(element: JQuery): void {
    let childName = element.data('childName');
    let message = element.data('message');
    let url = element.data('url');

    let payload = {
      options: {
        title: `Remove all items for ${childName}`,
        body: message,
        ok: `Yes, remove all items for ${childName}`
      },
      confirmed: () => {
        this.removeAllOrderItemsForChild(url);
      }
    };

    this.state.dispatch({ name: Actions.OPEN_CONFIRMATION_MODAL, payload: payload });
  }

  private async removeAllOrderItemsForChild(url: string): Promise<void> {
    this.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: this.basket });
    const parsedUrl = StringHelper.takeFromOccurrence(url, '/', 2);
    const results = await this.http.postHtml(parsedUrl, {
      partial_only: true,
    });

    this.render(results);
    this.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: this.basket });
  }

  private render(results: Document): void {
    this.basketContent.html(results);
    this.updateButtons();
  }

  private toggleBasket(open: boolean = null): void {
    if (open === null) {
      open = !this.basket.hasClass(BasketElement.openClass);
    }

    open ? this.openBasket() : this.closeBasket();
  }

  private closeBasket(): void {
    this.basket.removeClass(BasketElement.openClass).addClass('closed');
    this.state.dispatch({ name: Actions.BODY_ALLOW_SCROLL });
  }

  private openBasket(): void {
    this.basket.removeClass('closed').addClass(BasketElement.openClass);
    this.updateButtons();
    this.state.dispatch({ name: Actions.BODY_PREVENT_SCROLL });
  }

  private updateTile(data): void {
    this.basketItems.html(data.items);
    this.basketTotal.html(data.price);
  }

  private updateButtons(): void {
    let buttons = this.basketContent.find($('.stackable-buttons'));
    this.state.dispatch({ name: Actions.STACK_BUTTONS, payload: buttons });
  }

  public disable(): void {
    this.basket.remove();
    this.basketTrigger.remove();
  }
}
