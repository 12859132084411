import { Region } from './regions';

export interface IState {
  panelData?: {
    notifications: number;
  };
  basketData?: {
    price: string;
    items: number;
  };
  addresses?: {
    bfpoErrors?: boolean;
    showBack?: boolean;
  }
  location?: {
    countryName: string;
    countryCode: string;
  }
  currentRegion?: Region;
  currentParent?: number;
  flash?: {
    alert?: string|string[];
    notice?: string|string[];
  },
  [id: string]: any,
}

export const InitialState: IState = {
  currentRegion: null,
  basketData: {
    price: '0',
    items: 0,
  },
  addresses: null,
  location: null,
  panelData: {
    notifications: 0,
  },
};
