import { Element, State } from 'shared/core';
import { Injectable } from 'injection-js';
import { debounceTime, filter } from 'rxjs/operators';
import { Actions } from 'shared/state';

@Injectable()
export class LayoutElement extends Element {
  constructor(private state: State) {
    super();

    this.state.actions$.pipe(
      filter(e => e.name === Actions.TABLE_CHANGED),
    ).subscribe(_ => {
      this.adjustTables();
    });
  }

  public init(): void {
    this.adjustTables();

    try {
      // @ts-ignore: support for different browsers
      MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

      let observer = new MutationObserver(() => {
        this.state.dispatch({ name: Actions.TABLE_CHANGED});
      });

      observer.observe(document, {
        subtree: true,
        childList: true,
      });
    } catch(e) {
      // continue regardless of error
    }
  }

  public adjustTables(): void {
    // Find the maximum row length for all tables..
    $('table').each((i, htmlElem) => {
      let table = $(htmlElem);
      let rows = table.find('>tr:visible, >thead:visible>tr:visible, >tbody:visible>tr:visible');
      let perfectRows = rows.has('td:not([colspan]), td:not([data-colspan-zero])');

      let absoluteColspan = 0;

      perfectRows.slice(0, 10).each((i, rowElem) => {
        absoluteColspan = Math.max(absoluteColspan, $(rowElem).find('>td:visible, >th:visible').length);
      });

      // If perfectRows wasn't good enough, we have to hope for the best now...
      rows.slice(0, 10).each((i, rowElem) => {
        absoluteColspan = Math.max(absoluteColspan, $(rowElem).find('>td:visible, >th:visible').length);
      });

      table.data('currentRows', absoluteColspan);
    });

    // Go through the relevant colspan 0 rows
    $('td[colspan=0], td[data-colspan-zero], th[colspan=0], th[data-colspan-zero]').each((i, htmlElem) => {
      let currentRow = $(htmlElem).parent('tr');
      // Not including colspan 0 td/th
      let columnsInRow = currentRow.find('>td:visible, >th:visible').length - 1;
      let remainingCols = currentRow.closest('table').data('currentRows') - columnsInRow;

      if (remainingCols <= 0) {
        remainingCols = 1;
      }
      $(htmlElem).attr('colspan', remainingCols).attr('data-colspan-zero', 'true');
    });
  }
}
