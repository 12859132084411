import { Injectable } from 'injection-js';
import { Element, State } from 'shared/core';
import { Actions, Events } from 'shared/state';

@Injectable()
export class AccordionElement extends Element {
  private elementClass = '.accordion-element';
  private itemClass = `${this.elementClass}-item`;
  private navigationClass = `${this.itemClass}-navigation`;
  private contentClass = `${this.itemClass}-content`;
  private toggleClass = `${this.itemClass}-toggle`;

  constructor(protected state: State) {
    super();
  }

  public init(): void {
    $('body').on('click', this.navigationClass, e => this.toggleItem($(e.target)));
  }

  private toggleItem(element: JQuery): void {
    let accordion = $(element).closest(this.elementClass);
    let was_open = $(element).closest(this.navigationClass).children(this.toggleClass).hasClass('expanded');
    let item: JQuery = $(element).closest(this.itemClass);

    if (accordion.data('auto-close') == 1) {
      accordion.find(this.toggleClass).removeClass('expanded');
      accordion.find(this.contentClass).removeClass('expanded');
    } else {
      item.children(this.navigationClass).children(this.toggleClass).removeClass('expanded');
      item.children(this.contentClass).removeClass('expanded');
    }

    if (!was_open) {
      item.children(this.navigationClass).children(this.toggleClass).addClass('expanded');
      item.children(this.contentClass).addClass('expanded');

      this.state.dispatch({ name: Actions.STACK_BUTTONS, payload: item.siblings(this.contentClass) });
    }

    this.state.dispatch({ name: Events.ACCORDION_STATE_CHANGE, payload: element });
  }
}
