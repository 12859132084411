import { Module } from 'shared/core';
import { Injectable } from 'injection-js';
import { FlexTable } from 'shared/lib';

@Injectable()
export class OrdersModule extends Module {
  private startReturn: JQuery = $('.startReturn');
  private startReturnTrigger: JQuery = $('.startReturnTrigger');
  private startReturnCancel: JQuery = $('.startReturnCancel');
  private hideAtReturnStarted: JQuery = $('.hideAtReturnStarted');
  private returnsSubOrderSubtotals: JQuery = $('.order-suborders-panel-heading-subtotal');
  private returnItemCheckbox: JQuery = $('.returnItemCheckbox');
  private orderItems: JQuery = $('.orderItems');
  private returnableItems: JQuery = $('.returnableItems');

  private started = false;

  public init(): void {
    new FlexTable('sm');
    if ($('.returns').data('show-returns') == true) {
      this.validateReturn();
    }
    this.startReturnTrigger.on('click', () => this.toggleReturn());
    this.startReturnCancel.on('click', () => this.toggleReturn());
    this.returnItemCheckbox.on('click', () => this.validateReturn());
  }

  private toggleReturn(): void {
    this.startReturn.toggleClass('visible');
    this.startReturnTrigger.toggleClass('hidden');
    this.hideAtReturnStarted.toggleClass('hidden');
    this.orderItems.toggleClass('hidden');
    this.returnableItems.toggleClass('hidden');
    this.returnsSubOrderSubtotals.toggleClass('hidden');
    this.started = !this.started;

    if (this.started) {
      this.validateReturn();
    }
  }

  private validateReturn(): void {
    const checked = this.returnItemCheckbox.filter(':checked').length;
    const validation = checked > 0 ? false : 'disabled';
    this.startReturn.prop('disabled', validation);
  }
}
