import { OptionData } from 'shared/models';

export class Select {
  constructor(public select: JQuery) {
  }

  /**
   * Used for chaining where we don't want to keep the instance of select.  E.g.
   * Select.for('#mySelect').addOptionToSelect...  Cannot be continuously chained.
   *
   * @param select
   */
  public static for(select: JQuery|string): Select {
    if (typeof select === typeof String)
      return new Select($(select as string));
    else
      return new Select(select as JQuery);
  }

  /**
   * Adds an option to the select element.
   *
   * @param optionData
   */
  public addOption(optionData: OptionData): void {
    let option = $('<option>', {
      value: optionData.value,
      text: optionData.text,
      selected: optionData.selected,
      class: optionData.cssClass
    });

    if (typeof optionData.data != typeof undefined && optionData.data != null) {
      optionData.data.forEach((datum: { name: string, value?: any }) => {
        option.attr(`data-${datum.name}`, datum.value);
      });
    }

    this.select.append(option);
  }

  /**
   * Adds a simple option to the select element
   *
   * @param value
   * @param text
   * @param selected
   */
  public addSimpleOption(value: any, text: string, selected = false): void {
    this.addOption({
      value: value,
      text: text,
      selected: selected
    });
  }

  /**
   * Adds an option with data to the select element.
   *
   * @param value
   * @param text
   * @param selected
   * @param dataName
   * @param dataValue
   */
  public addOptionWithData(value: any, text: string, selected = false, dataName: string, dataValue: string, cssClass: string = null): void {
    this.addOption({
      value: value,
      text: text,
      selected: selected,
      cssClass: cssClass,
      data: [
        {
          name: dataName,
          value: dataValue
        }
      ]
    });
  }

  /**
   * Adds a "blank" option to the TOP of the select element, essentially like a placeholder option.
   *
   * @param text
   * @param selected
   */
  public addBlankOption(text: string, selected = false): void {
    this.select.prepend($('<option>', {
      text: text,
      value: '',
      selected: selected
    }));
  }

  /**
   * Removes all options from the select element
   */
  public clearAllOptions(): void {
    this.select.html('');
  }

  /**
   * Removes all options from the select element then adds in the replacement options.
   *
   * @param optionData
   * @param blankOption
   * @param keepCurrent
   */
  public replaceOptionsWith(optionData: OptionData[], blankOption: string = null, keepCurrent = true): void {
    const currentValue = this.select.val();

    this.clearAllOptions();

    optionData.forEach((optionDatum: OptionData) => {
      this.addOption(optionDatum);
    });

    if (keepCurrent) {
      this.select.val(currentValue);
    }

    if (blankOption != null) {
      this.addBlankOption(blankOption, this.select.val() == null);
    }
  }
}
