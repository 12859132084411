/**
 * A component to control flash alerts and notices, either those already on the page or those dynamically
 * added to the page using the methods within.
 *
 * Often this component is short lived, due to the higher-than-average use of static methods.  This class performs
 * no error handling if data passed to it is incorrect and will likely result in undefined behaviour or errors.
 */
import { Toasts } from 'shared/lib/toasts';

export class Flash {
  /**
   * The flash element controlled by this instance of flash.  This may be multiple
   * DOM objects on the page.
   */
  public element: JQuery = $('.flash');

  public removeAfterMilliseconds = 5000;

  /**
   * Instantiate Flash for a particular container, then shows and removed the flash messages.  If container is
   * undefined, then a generic instance of Flash for all .flash containers is created.
   *
   * The promise for showing, hiding and removing flash messages here is always ignored.
   *
   * @param container
   */
  public static forContainer(container?: string | JQuery): Flash {
    let flash: Flash = new Flash();

    if (typeof container === 'string')
      flash.element = $(container as string);

    if (container instanceof jQuery)
      flash.element = (container as JQuery);

    if ($('.content-with-filters').length > 0) {
      $('#content .flash').addClass('filter-width-flash');
    }

    flash.showAndRemove().then();
    return flash;
  }

  /**
   * From a json AJAX response, check if there are flash messages.  If there are then display them
   * to the page or containerElem.  Returns an object containing a boolean for whether any notices
   * where displayed or any alerts were displayed.
   *
   * It is expected that the containerElem contains a .flash element for the flash messages to be inserted
   * into.
   *
   * @param response The json response
   * @param containerElem optional - A JQuery object for the element to display flash messages in
   */
  public static fromAjaxRequest(response: unknown, containerElem?: JQuery)
    : { notices: boolean, alerts: boolean, stay_alerts: boolean } {

    if (window.App.preferToasts) {
      if (typeof containerElem == typeof undefined || containerElem == null) {
        return Toasts.fromAjaxRequest(response);
      }
    }

    let ret = { notices: false, alerts: false, stay_alerts: false };

    if (typeof response['flash'] !== 'object') {
      return ret;
    }

    let notices = Flash.cleanFlashResponse(response['flash']['notice']);
    let alerts = Flash.cleanFlashResponse(response['flash']['alert']);
    let stay_alerts = Flash.cleanFlashResponse(response['flash']['stay_alert']);

    if (alerts.length > 0) {
      ret.alerts = Flash.addFlashes(alerts, 'alert-danger', containerElem);
    }

    if (stay_alerts.length > 0) {
      ret.stay_alerts = Flash.addFlashes(stay_alerts, 'alert-danger stay-here', containerElem);
    }

    if (notices.length > 0) {
      ret.notices = Flash.addFlashes(notices, 'alert-info', containerElem);
    }

    return ret;
  }

  /**
   * For a json response, check if there are any flash messages and put them in the form element.  If the form
   * element does not already have a .flash container, this method will create it for you.
   *
   * @param response - The json response
   * @param formElem - The JQuery object
   */
  public static fromAjaxRequestForForm(response: unknown, formElem: JQuery)
    : { notices: boolean, alerts: boolean, stay_alerts: boolean } {

    let containerElem;

    if (formElem.find('.flash').length === 0) {
      $('<div class="flash"></div>').prependTo(formElem);
    }

    containerElem = formElem.find('.flash').first();

    return Flash.fromAjaxRequest(response, containerElem);
  }

  /**
   * Add a generic error alert to the current container.  Promise is resolved once flash message show animation is
   * complete.
   *
   * @param message The string alert message
   */
  public static async genericAlert(message: string): Promise<void> {
    await Flash.addFlashes(this.cleanFlashResponse(message), 'alert-danger');
  }


  /**
   * Add a generic notice to the current container.  Promise is resolved once flash message show animation is
   * complete.
   *
   * @param message The string alert message
   */
  public static async genericNotice(message: string): Promise<void> {
    await Flash.addFlashes(this.cleanFlashResponse(message), 'alert-info');
  }

  /**
   * Add a generic notice to the current container.  Promise is resolved once flash message show animation is
   * complete.
   *
   * @param message The string alert message
   */
  public static async genericSuccess(message: string): Promise<void> {
    await Flash.addFlashes(this.cleanFlashResponse(message), 'alert-success');
  }

  /**
   * Add a generic error alert for a specific container.  Promise is resolved once flash message show animation is
   * complete.
   *
   * @param message The string alert message
   * @param container - The JQuery object
   */
  public static async genericAlertForContainer(message: string, container?: JQuery): Promise<void> {
    if (container.find('.flash').length === 0) {
      $('<div class="flash"></div>').prependTo(container);
    }

    await Flash.addFlashes(this.cleanFlashResponse(message), 'alert-danger', container);
  }


  /**
   * Remove all flash messages
   */
  public static removeAllMessages(container?: string | JQuery): void {
    let flash = Flash.forContainer(container);
    flash.element.find('.alert-info, .alert-danger, .alert-warning').remove();
  }

  /**
   * Show all flash messages for the current container.  Does not remove them after.  Promise is resolved once
   * flash message show animation is complete.
   */
  public async show(): Promise<void> {
    let element = this.element.find('.alert-info, .alert-danger, .alert-warning');
    await Flash.showAnimation(element);
  }

  /**
   * Show all the flash message for the current container and remove them after they have been displayed for long
   * enough.  Promise is resolved once them flash messages are fully removed from the page.
   *
   */
  public async showAndRemove(): Promise<void> {
    let element = this.element.find('.alert-info, .alert-danger, .alert-warning, .alert-success');
    await Flash.showAnimation(element);

    if(!element.hasClass('stay-here')) {
      await element.delay(this.removeAfterMilliseconds).fadeOut(1000).promise();
      await element.remove();
    }
  }

  /**
   * Show the element, where the element is intended to be a flash container.  Promise is resolved once the
   * container is visible.
   *
   * @param messageElem
   * @private
   */
  private static async showAnimation(messageElem: JQuery): Promise<void> {
    await messageElem.fadeIn(500).promise();
  }

  /**
   * Add flash messages to a container in a non-blocking manner.
   *
   * @param messages An array of strings representing the messages
   * @param messageClass The class name for the messages
   * @param containerElem Optional - The element to insert the messages to
   * @private
   */
  private static addFlashes(messages: string[], messageClass: string, containerElem?: JQuery): boolean {
    let hadFlash = false;

    for(let message of messages) {
      message = message.trim();

      if (message.length > 0) {
        hadFlash = true;
        Flash.addFlash(message, messageClass, containerElem).then();
      }
    }

    let flash = new Flash();
    if (containerElem instanceof jQuery) {
      flash.element = containerElem;
    } else if (typeof containerElem == typeof undefined) {
      if ($('#seraph .content-with-filters').length > 0) {
        $('#content .flash').addClass('filter-width-flash');
      }
    }

    flash.showAndRemove().then();

    return hadFlash;
  }

  /**
   * Adds a new flash message to the containerElem (or if not set, the first .flash in the DOM).  Removes the flash
   * message once it has been shown.
   *
   * @param message The flash message to show
   * @param messageClass The class for the flash message, e.g. .alert-danger
   * @param containerElem Optional - The container element to insert into
   * @private
   */
  private static async addFlash(message: string, messageClass: string, containerElem?: JQuery): Promise<void> {
    let messageElem = $('<div></div>').addClass(messageClass).html(message);

    if (containerElem instanceof jQuery) {
      messageElem.appendTo(containerElem);
    } else {
      messageElem.appendTo($('.flash').first());
    }

    Flash.forContainer(containerElem);
  }

  /**
   * Used to clean up a flash response value, typically from a json ajax response.
   *
   * @param response
   * @private
   */
  private static cleanFlashResponse(response: string|string[]|null): string[] {
    if (typeof response === typeof undefined || response == null) {
      return [];
    }

    if (typeof response === 'string') {
      response = (response as string).trim();

      if (response.length > 0) {
        return [response];
      } else {
        return [];
      }
    }

    // At this point, we can only assume the message is an array of strings.  If it is not, this will likely
    // result in undefined behaviour.
    let cleaned: string[] = Array<string>();

    for(let message of response) {
      if (typeof message === 'string') {
        message = message.trim();

        if (message.length > 0) {
          cleaned.push(message);
        }
      }
    }

    return cleaned;
  }

}
