import { Module, Http, State } from 'shared/core';
import { Injectable } from 'injection-js';
import { AjaxForm } from 'shared/lib';

@Injectable()
export class ShippingModule extends Module {

  constructor(
    protected http: Http,
    protected state: State,
  ) {
    super();
  }

  public init(): void {
    $('body').on('change', '#order_address_to_child', (e: JQuery.ChangeEvent) => {
      new AjaxForm().submitForm($(e.target).closest('form') as JQuery<HTMLFormElement>);
    });

    $('body').on('input', '#tax_reference', (e: JQuery.ChangeEvent) => {
      let tax_reference = $('#tax_reference').val().toString();
      $('#confirmTaxReference').prop('disabled', tax_reference.length == 0)
    });
  }
}
