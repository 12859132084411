// The purpose of this is to add a span to the page that contains icons that FontAwesome uses.
// Then it will measure the width and then add the FontAwesome font to it and measure the width until it changes.
// If it changes it will mean the font has loaded.
// If it times out then something has gone wrong but we will continue anyway to make
//  sure the page is still usable.
import { Events } from 'shared/state';

export class CheckFonts {
  private font = 'Font Awesome 5 Free';
  private fontClass: string = this.font.toLowerCase().replace( /\s/g, '' );
  private testFont = 'Courier New';
  private glyphs = '&#xf2f9;&#xf067;&#xf077;&#xf2ed;&#xf303;';
  private delay = 50;
  private timeOut = 5000;
  private readonly tester: JQuery;
  private readonly fallbackFontWidth: number;

  constructor() {
    // Create the temporary span
    this.tester = $('<span>' + this.glyphs + '</span>')
      .css('position', 'absolute')
      .css('top', '-9999px')
      .css('left', '-9999px')
      .css('visibility', 'hidden')
      .css('fontFamily', this.testFont)
      .css('fontSize', '250px');

    // Add the span to the body
    $('body').append(this.tester);

    // Measure the width to get a baseline width
    this.fallbackFontWidth = this.tester.outerWidth();

    // Add the fontawesome font
    this.tester.css('fontFamily', `'${this.font}', '${this.testFont}'`);

    // Start the check process
    this.checkFont();
  }

  private checkFont() : void {
    // Get the span width
    let loadedFontWidth: number = this.tester.outerWidth();

    // If it is different to the baseline width then it must be loaded
    if (this.fallbackFontWidth !== loadedFontWidth){
      this.success();
    } else if (this.timeOut < 0) {
      // If we have exhausted all the attempts then fail
      this.failure();
    } else {
      // Retry if we haven't exhausted all the attempts
      this.retry();
    }
  }

  private retry() : void {
    // Wait for the delay period and check again
    setTimeout(() => this.checkFont(), this.delay);
    // Deduct the delay period from the total so that it will eventually be exhausted
    this.timeOut = this.timeOut - this.delay;
  }

  private failure(): void {
    // even though this is a failure we are still going to treat it as loaded so
    //  that we load the buttons on the page
    $('html').addClass('no-' + this.fontClass);
    this.complete();
  }

  private success(): void {
    $('html').addClass(this.fontClass);
    this.complete();
  }

  private complete(): void {
    this.tester.remove();
    window.App.state.dispatch({ name: Events.FONTS_LOADED });
  }
}
