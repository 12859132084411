export enum Actions {
  // Payload: String - ID of static modal
  OPEN_STATIC_MODAL = 'OPEN_STATIC_MODAL',

  // Payload: Object
  // - options (optional): Object
  //   - title (optional): String - modal title
  //   - body (optional): String - modal body
  //   - ok (optional): String - OK Button text
  //   - cancel (optional): String - Cancel button text
  //   - keep_open (optional): boolean - close the model after confirmation or not (default: false)
  // - confirmed (optional): (event) => void
  // - cancelled (optional): (event) => void
  OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRM_MODAL',

  // Payload: Object
  // - options (optional): Object
  //   - title (optional): String - modal title
  //   - body (optional): String - modal body
  //   - ok (optional): String - OK Button text
  // - confirmed (optional): (event) => void
  // - after: Func<JQuery>
  OPEN_ALERT_MODAL = 'OPEN_ALERT_MODAL',

  // Payload: Object
  //   - form: JQuery - form element
  //   - title (optional): String - input title title
  //   - input (optional): JQuery - input element
  DEBOUNCE_SUBMIT = 'DEBOUNCE_SUBMIT',

  // Payload: Object
  // - url: String - url for the remote ajax call
  // - after: Func<JQuery>
  OPEN_DYNAMIC_MODAL = 'OPEN_DYNAMIC_MODAL',

  // Payload:
  // - modal: JQuery|String - HTML content for the modal
  // - after: Func<JQuery>
  OPEN_NEW_MODAL = 'OPEN_NEW_MODAL',

  // Payload: String
  OPEN_JSX_MODAL = 'OPEN_JSX_MODAL',

  // Payload: String
  CLOSE_JSX_MODAL = 'CLOSE_JSX_MODAL',

  // Payload:
  // - title: String
  // - content: JQuery|String - HTML content for the modal, but the html doesn't include the modal stuff itself.......
  // - sizeClass: String - default 'medium'
  // - after: Func<JQuery>
  // - useVnodes: Whether the content is a VNode and needs to use a different render process
  OPEN_NEW_MODAL_FROM_CONTENT = 'OPEN_NEW_MODAL_FROM_CONTENT',

  // Payload: Undefined|String - Id of Modal to close, or all modals (except "keep open" modals)
  CLOSE_MODAL = 'CLOSE_MODAL',

  // Payload: Object
  // - name: String
  // - type: String - "open", "close"
  MEASUREMENTS_VALIDATION = 'MEASUREMENTS_VALIDATION',

  // Payload: Undefined
  MEASUREMENTS_FORM_SUBMIT = 'MEASUREMENTS_FORM_SUBMIT',

  // Payload: JQuery - stackable buttons element, singular or multiple
  STACK_BUTTONS = 'STACK_BUTTONS',

  // Payload: String - HTML of new basket content
  BASKET_RENDER = 'BASKET_RENDER',

  // Payload: Undefined
  BODY_PREVENT_SCROLL = 'BODY_PREVENT_SCROLL',

  // Payload: Undefined
  BODY_ALLOW_SCROLL = 'BODY_ALLOW_SCROLL',

  // Payload: JQuery object of container or string selector
  ENABLE_CONTAINER = 'ENABLE_CONTAINER',

  // Payload:
  // JQuery object of container or String selector
  // or...
  // - container: JQuery object of container or String selector
  // - message: message to display in the container
  DISABLE_CONTAINER = 'DISABLE_CONTAINER',

  // Payload: Undefined
  DISALLOW_AUTOCOMPLETE = 'DISALLOW_AUTOCOMPLETE',

  // Payload: Undefined
  PICK_UP_ADDRESS_CHANGED = 'PICK_UP_ADDRESS_CHANGED',

  // Payload: Undefined
  STOCK_REQUEST_SUBMITTED = 'STOCK_REQUEST_SUBMITTED',

  // Payload:
  // - table: JQuery (optional)
  TABLE_CHANGED = 'TABLE_CHANGED',

  // Payload: Undefined
  TOGGLE_COMMENTS = 'TOGGLE_COMMENTS',

  // Payload: Object
  // - batchId: number
  // - value: number
  LEDGER_UPDATE = 'LEDGER_UPDATE',

  // Payload: undefined
  JSX_RERENDER_VIEW = 'JSX_RERENDER_VIEW',

  // Payload: undefined
  TOGGLE_FILTER = 'TOGGLE_FILTER',

  // Payload: undefined
  PO_SELECTED = 'PO_SELECTED',

  // Payload: undefined
  FILTER_SUBMIT = 'FILTER_SUBMIT',

  // Payload: Object
  // - name: String
  RESET_SELECT = 'RESET_SELECT',

  // Payload: undefined
  JSX_RENDER = 'JSX_RENDER',
}
