import '../vendors/jquery.swinxy-combined.min';
import '../vendors/jquery.swinxyzoom.min';

export interface ZoomConfig {
  size: string;
  selector: string;
}

export class Zoom {

  private zoom: JQuery;
  
  constructor(config: ZoomConfig) {
    this.init(config);
  }

  private init(config: ZoomConfig): void {
    const { selector, size } = config;
    this.zoom = ($(selector) as any).swinxyzoom({
      mode: 'dock',
      dock: { position: 'right' },
      size: size,
      controls: false
    });
  }

}
