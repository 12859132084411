import { Element, Http, State, TurboLinks } from 'shared/core';
import { Injectable } from 'injection-js';
import { StringHelper } from 'webshop/helpers';
import { filter, takeUntil } from 'rxjs/operators';
import { Actions } from 'shared/state';
import { AjaxForm, Flash, Tabs, TypeAheadList } from 'shared/lib';

@Injectable()
export class PopoutPanelElement extends Element {
  private panel: JQuery = $('#popoutPanel');
  private panelContainer: JQuery = $('#popoutPanelContainer');
  private phoneBookContainer: JQuery = $('#phoneBookContainer');
  private notificationsContainer: JQuery = $('#notificationsContainer');
  private urlFavouritesContainer: JQuery = $('#urlFavouritesContainer');
  private leaveRequestsContainer: JQuery = $('#leaveRequestsContainer');
  private birthdaysContainer: JQuery = $('#birthdaysContainer');
  private closeTriggerId = '#popoutPanelClose';
  private openTriggerId = '#popoutPanelOpen';
  private static openClass = 'open-right';
  private timer: any;
  private acknowledgeNotificationForm: AjaxForm = null;
  private urlFavouriteForm: AjaxForm = null;
  private birthdayForm: AjaxForm = null;

  constructor(
    private http: Http,
    private state: State,
    private turbolinks: TurboLinks,
  ) {
    super();

    this.body.on('click', this.closeTriggerId, () => this.closePanel());
    this.body.on('click', this.openTriggerId, () => this.togglePanel());
  }

  public async init(): Promise<void> {
    this.state.get.pipe(
      filter(e => e.panelData !== null),
      takeUntil(this.turbolinks.visit$),
    ).subscribe(({ panelData }) => {
      this.updateTile(panelData);
    });

    this.acknowledgeNotificationForm = new AjaxForm(responseData => this.render(responseData));

    this.body.on('click', '.acknowledgeNotificationIcon', async e => {
      let form = $(e.target).closest('form') as JQuery<HTMLFormElement>;
      await this.acknowledgeNotificationForm.submitForm(form);
    });

    this.body.on('click', '#use_current_page', async e => {
      $('#url_favourite_url').val($(location).prop('href'));
    });

    this.urlFavouriteForm = AjaxForm.createGeneral('#url_favourite_modal .favourite_form', async () => {
      this.state.dispatch({ name: Actions.CLOSE_MODAL });
      await this.fetch(true);
    });

    this.birthdayForm = AjaxForm.createGeneral('#birthday_message_modal .birthday_message_form', async () => {
      this.state.dispatch({ name: Actions.CLOSE_MODAL });
      await this.fetch(true);
    });

    this.body.on('click', '.deleteUrlFavourite', e => {
      e.preventDefault();
      this.confirmDeleteUrlFavourite($(e.target));
    });

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    await this.fetch(true);

    let url = window.location.pathname.toString();
    if (url == '/seraph') {
      this.togglePanel();
    }
  }

  private async fetch(force: Boolean): Promise<void> {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();
    let day = now.getDate();
    let start_time = new Date(year, month, day, 9).getTime();
    let end_time = new Date(year, month, day, 17).getTime();

    let in_time = now.getTime() > start_time && now.getTime() < end_time;

    if (force || in_time) {
      const results = await this.http.getHtml('/seraph/popout_panels');
      this.render(results);
    }
  }

  private render(results: any): void {
    if (results.phone_book_html) {
      this.phoneBookContainer.html(results.phone_book_html);
      let typeAhead = TypeAheadList.forElement($('.user_search'));
      typeAhead.onSelect = (data: {id, text, data}) => {
        $('.popout_contact_card').empty();
        if (data.id != '0') {
          this.fetchContactCard(data.id);
          $('.user_search').val('0');
        }
      };
    }

    if (results.notifications_html) {
      this.notificationsContainer.html(results.notifications_html);
    }

    if (results.url_favourites_html) {
      this.urlFavouritesContainer.html(results.url_favourites_html);
    }

    if (results.leave_requests_html) {
      this.leaveRequestsContainer.html(results.leave_requests_html);
    }

    if (results.birthdays_html) {
      this.birthdaysContainer.html(results.birthdays_html);
    }

    if (results.its_my_birthday) {
      $('#popout_panel_accordion_accordion_panel_4').prependTo('#popout_panel_accordion>.accordion-element');
    }

    new Tabs({
      id: 'leave_request_tabs',
      remote: false,
    });

    this.updateButtons();
  }

  private togglePanel(open: boolean = null): void {
    if (open === null) {
      open = !this.panel.hasClass(PopoutPanelElement.openClass);
    }

    open ? this.openPanel() : this.closePanel();
  }

  private closePanel(): void {
    this.panel.removeClass(PopoutPanelElement.openClass).addClass('closed');
    this.state.dispatch({ name: Actions.BODY_ALLOW_SCROLL });
  }

  private openPanel(): void {
    this.panel.removeClass('closed').addClass(PopoutPanelElement.openClass);
    this.updateButtons();
    this.state.dispatch({ name: Actions.BODY_PREVENT_SCROLL });
  }

  private updateTile(data): void {
    let panelItems = $('.popout-panel-items');
    if (data.notifications == 0) {
      panelItems.hide();
      document.title = 'Seraph';
    } else {
      panelItems.html(data.notifications);
      panelItems.show();
      document.title = `Seraph (${data.notifications})`;
    }
  }

  private updateButtons(): void {
    let buttons = this.panelContainer.find($('.stackable-buttons'));
    this.state.dispatch({ name: Actions.STACK_BUTTONS, payload: buttons });
  }

  public disable(): void {
    this.panel.remove();
    $(this.openTriggerId).remove();
  }

  private confirmDeleteUrlFavourite(element: JQuery): void {
    let name = element.data('name');
    let url = element.data('url');

    let payload = {
      options: { title: `Delete ${name}?` },
      confirmed: () => {
        this.deleteUrlFavourite(url);
      }
    };

    this.state.dispatch({ name: Actions.OPEN_CONFIRMATION_MODAL, payload: payload });
  }

  private async deleteUrlFavourite(url: string): Promise<void> {
    this.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: this.urlFavouritesContainer });
    const results = await this.http.postHtml(url, {});
    this.render(results);
    this.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: this.urlFavouritesContainer });
  }

  private async fetchContactCard(user_id: string): Promise<void> {
    this.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: this.phoneBookContainer });
    let url = $('.popout_phone_book').data('url');
    const results: any = await this.http.getHtml(url, {user_id: user_id});
    $('.popout_contact_card').replaceWith(results.contact_card_html);
    this.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: this.phoneBookContainer });
  }
}
