import { Module } from 'shared/core';
import { Injectable } from 'injection-js';
import { Datepicker } from 'shared/lib';

@Injectable()
export class GdprModule extends Module {
  private dob: JQuery = $('.datepickable');

  public init(): void {
    let today = new Date();
    let minDate = new Date(today.getFullYear() - 100, 0, 1);
    let options = { startDate: minDate, endDate: new Date() };
    new Datepicker(this.dob, options);
  }
}
