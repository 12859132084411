import { FormDataBuilder, Tabs } from 'shared/lib/index';
import { Actions, Events } from 'shared/state';
import { filter } from 'rxjs/operators';
import { Http, State } from 'shared/core';

export class DhlReturns  {

  public tabs: Tabs;
  private contactName: JQuery = $('#return_order_delivery_contact_name');
  private contactNumber: JQuery = $('#return_order_delivery_contact_number');
  private pickUpDate: JQuery = $('#pick_up_date');

  constructor(
    private state: State,
    private http: Http,
  ) {
    this.init();
  }

  public init(): void {
    this.tabs = new Tabs({
      id: 'return_courier',
      remote: false,
    });

    $('body').on('change', '.required', () => this.checkValidity());
    $('body').on('change', '#choosePickUpTime', () => this.checkValidity());
    $('body').on('click', '.tabs-menu-item', () => this.checkValidity());
    $('body').on('keydown', '.enter-prevent', (event) => this.preventEnter(event));

    $('#pick_up input').on('change', () => this.getPickupCapabilities());

    $('#pick_up select').on('change', () => this.getPickupCapabilities());
    this.state.actions$.pipe(
      filter(e => e.name === Actions.PICK_UP_ADDRESS_CHANGED)
    ).subscribe(() => {
      this.checkValidity();
      this.getPickupCapabilities();
    });

    this.checkValidity();
  }

  private async getPickupCapabilities(): Promise<void> {
    let formBuilder = new FormDataBuilder();

    // detects whether html5 in enabled on this browser and if not dynamically creates date string
    let dateString = '';
    
    if (this.pickUpDate[0].tagName === 'SELECT') {
      let dates = $('#pick_up select.date');
      let day = (dates[0] as HTMLInputElement).value.toString();
      let month = (dates[1] as HTMLInputElement).value.toString();
      let year = (dates[2] as HTMLInputElement).value.toString();
      dateString = year + '-' + month + '-' + day;
    } else if (this.pickUpDate[0].tagName === 'INPUT') {
      dateString = this.pickUpDate.val().toString();
    }

    if (dateString.length > 0) {
      formBuilder.append_param('', 'pick_up_date', dateString);
      formBuilder.append_param('', 'address', $('.address-select').val().toString());
      formBuilder.append_param('', 'weight', $('#weight').val().toString());

      window.App.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: $('#pick_up') });

      let url = $('#dhl').data('capabilitiesUrl');
      const results: any = await this.http.post(url, formBuilder.formData);
      if (results.success) {
        $('#returns-confirm-capabilities').html(results.html);
      } else {
        $('#returns-confirm-capabilities').html(results.message);
      }
      this.checkValidity();
      window.App.state.dispatch({ name: Actions.ENABLE_CONTAINER, payload: $('#pick_up') });
    }
  }

  private preventEnter(event): void {
    if (event.key == 'Enter') {
      event.preventDefault();
    }
  }

  private checkValidity(): void {
    let name_valid = true;
    let number_valid = true;

    let courier_method_valid = true;
    let pick_up_valid = true;
    let drop_off_valid = true;

    let courier_method = $(this.tabs.getActiveTab()).data('tab');
    $('#courier_method').val(courier_method);
    let tab_contents = $(this.tabs.getActiveTabContents());

    if (courier_method == 'pick_up') {
      pick_up_valid = this.validate_pick_up(tab_contents);
    } else if (courier_method == 'drop_off')  {
      drop_off_valid = this.validate_drop_off(tab_contents);
    } else {
      courier_method_valid = false
    }

    if (this.contactName.length > 0) {
      name_valid = this.contactName.val().toString().trim().length > 0;
    }

    if (this.contactNumber.length > 0) {
      const provided_number = this.contactNumber.val().toString().trim();
      if (provided_number.length == 0) {
        number_valid = false;
      } else {
        const regexp = new RegExp(/^[- +()0-9]{4}[- +()0-9]*$/);
        number_valid = regexp.test(provided_number);
      }
    }

    this.contactName.toggleClass('error', !name_valid);
    this.contactNumber.toggleClass('error', !number_valid);

    this.state.dispatch({ name: Events.RETURN_CONFIRM_VALIDATED, payload: name_valid && number_valid && pick_up_valid && drop_off_valid && courier_method_valid });
  }

  private validate_pick_up(tab_contents: JQuery): boolean {
    let required_valid = true;
    let address_valid = $('.address-select').val().toString().length > 0;
    let pick_up_time_checked = $('#choosePickUpTime').is(':checked');

    $(tab_contents).find('input.required').each((i, htmlElem) => {
      let elem = $(htmlElem);
      if (elem.val().toString().length == 0) {
        required_valid = false;
      }
    });

    return address_valid && required_valid && pick_up_time_checked;
  }

  private validate_drop_off(active_tab: JQuery): boolean {
    const drop_off_date = ($('#drop_off_date').val() as string);
    return drop_off_date.length > 0;
  }

}
