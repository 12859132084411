import { Injectable } from 'injection-js';
import { Element, State } from 'shared/core';
import { Events } from 'shared/state';

@Injectable()
export class TabsElement extends Element {
  public tabsMenuItem: JQuery = $('.tabs-menu-item');
  public tabsToggle: JQuery = $('.tabsMobile');
  public tabsMobile: JQuery = $('.tabs-menu');
  private static tabsClass = '.tabs';
  private static visibilityClass = 'expanded';
  private static activeClass = '.tabsActive';

  constructor(private state: State) {
    super();
  }

  public init(): void {
    // Sets up the mobile tabs.  For each mobile tabs view, sets the activeClass (visible before dropdown)
    // to the active tab item or if there is no active tab item, the first tab item.
    $(TabsElement.tabsClass).each((index, tabsElement) => {
      let tabs = $(tabsElement);
      let activeTab = tabs.find('.tabs-menu-item.active');
      let firstActiveTab = null;
      if (activeTab.length > 0) {
        tabs.find(TabsElement.activeClass).text(activeTab.first().text());
        firstActiveTab = activeTab;
      } else {
        let defaultTab = tabs.find('.tabs-menu-item').first();
        tabs.find(TabsElement.activeClass).text(defaultTab.first().text());
        firstActiveTab = defaultTab;
      }

      if (tabs.length == 1) {
        tabs.find(TabsElement.activeClass).html('<a>' + firstActiveTab.html() + '</a>');
        tabs.find(TabsElement.activeClass).find('a').attr('href', firstActiveTab.attr('href'));
      }
    });

    if (this.tabsMobile.children().length > 1) {
      this.tabsToggle.on('click', e => this.toggleCollapse($(e.target).parents(TabsElement.tabsClass)));
    } else if (this.tabsMobile.children().length == 0) {
      this.tabsToggle.hide();
    } else {
      this.tabsToggle.find('.tabs-mobile-toggle').hide();
    }

    this.tabsMenuItem.on('click', e => this.setTab($(e.target)));
  }

  private setTab(tab: JQuery, toggle = true): void {
    const tabs = tab.parents(TabsElement.tabsClass);

    // Edge case: if clicking the tab will open a webpage in a new tab/window,
    // we shouldn't set the selected tab as active.
    // Also check for "active_disabled" class which prevents it from being set
    // as active, for example when a modal appears on click or another active.
    if (tab.prop('target') != '_blank' && !tab.hasClass('active_disabled')) {
      tabs.find(TabsElement.activeClass).text(tab.text());
    }

    if (toggle) {
      this.toggleCollapse(tabs);
      this.state.dispatch({ name: Events.ELEMENT_SHOWN, payload: tabs });
    }
  }

  private toggleCollapse(tabs: JQuery): void {
    tabs.toggleClass(TabsElement.visibilityClass);
  }
}
