import { Actions, Events } from 'shared/state';
import { State } from 'shared/core';
import { filter } from 'rxjs/operators';

export class MeasurementGuide {
  private container;
  private mainImagePath = null;
  private mainImage: JQuery;
  private currentUnit: string;
  private isMobile = false;
  private state: State = window.App.getProvider(State);

  private guidelines: Record<any, any> = {
    height: {
      message: 'Measure your child in bare feet from the floor to the top of their head. You might find it easier to do this whilst they are standing against a wall. Mark straight across the head and then measure from the floor to the mark.',
      imagePath: null,
    },
    chest: {
      message: 'Ensure that your child\'s arms are down by their side. Measure the entire circumference of the chest at the fullest part placing the tape measure close up under their arms and keeping it level. Please ensure that the tape is not pulled too tight, you should be able to easily place a finger underneath.',
      imagePath: null,
    },
    waist: {
      message: 'Measure around the waistline whilst your child is standing up straight. The waistline is normally just above the tummy button. Please ensure that the tape is not pulled too tight.',
      imagePath: null,
    },
    hip: {
      message: 'Measure the hip at the widest point, whilst your child is stood with feet together, ensuring the tape is kept level and again is not pulled too tight.',
      imagePath: null
    },
    neck: {
      message: 'Place the tape measure around the base of the neck where the collar sits. We suggest putting two fingers underneath the tape so that it\'s not too tight and to get the most suitable measurement.',
      imagePath: null,
    }
  };

  private guideElements: Record<any, any> = {};

  constructor(container_selector = null, validator = null) {
    if(!container_selector) throw new Error('Measurement Guide Container must be provided.');
    if(!validator) throw new Error('Measurement Guide Validator must be provided.');

    this.container = $(container_selector);
    this.isMobile = this.container.css('display') == 'none';

    this.mainImagePath = $('#measurements_asset_for_outline').val();

    for(let type in this.guidelines) {
      this.guidelines[type]['imagePath'] = $('#measurements_asset_for_' + type).val();
    }

    if (this.currentUnit == null) {
      this.currentUnit = $('#measurement_unit_value').val() as string;
    }

    window.addEventListener('resize', () => {
      this.isMobile = this.container.css('display') == 'none';
    });

    $('#select_cm').on('click', (e) => {
      e.preventDefault();

      this.toggleControls('inches', 'cm');
      this.bindFormControls();

      this.currentUnit = $('#measurement_unit_value').val() as string;
    });

    $('#select_inches').on('click', (e) => {
      e.preventDefault();

      this.toggleControls('cm', 'inches');
      this.bindFormControls();

      this.currentUnit = $('#measurement_unit_value').val() as string;
    });

    $('.help').on('click', (e: Event) => {
      if (!this.isMobile) return; // only respond to clicks when on mobile

      e.stopPropagation();

      let help = $(e.target).closest('.help');
      let formContent = $('<div/>');
      let guideText = $('<div/>');
      let type = help.data('type');
      let confirmButton = $('<a/>');
      let inputContainer = this.duplicateMeasurementSelect(type);

      confirmButton.addClass('button button-next button-success' );
      confirmButton.html('Confirm');

      guideText.addClass('guide-text');
      guideText.html(this.guidelines[type]['message']);

      formContent.append(guideText);
      formContent.append(inputContainer);
      formContent.append(confirmButton);

      this.initImage(formContent);
      this.initGuides(formContent, type);

      let payload: Record<string, any> = {
        id: 'measurement_guide_modal',
        title: `Measurement Guide ${type.substr(0,1).toUpperCase() + type.substr(1).toLowerCase()}`,
        content: formContent,
        sizeClass: 'large',

        after: (modal: JQuery) => {
          this.toggleGuide(guideText, type, true);

          modal.find('.button-success').on('click', (e) => {
            let selectedVal = modal.find(`#modal_child_measurement_${type}_${this.currentUnit}`).val();
            $(`#child_measurement_${type}_${this.currentUnit}`).val(selectedVal).change();

            this.state.dispatch({ name: Actions.CLOSE_MODAL });
          });
        }
      };

      this.state.dispatch({ name: Actions.OPEN_NEW_MODAL_FROM_CONTENT, payload: payload });
    });

    $('.help').on('mouseenter', (e: Event) => {
      if (this.isMobile) return; // only respond to mouseenter when not on mobile

      e.stopPropagation();
      let help = $(e.target).closest('.help');
      this.toggleGuide($('#measurement-guide .guide-text'),help.data('type'), true);
    });

    $('.help').on('mouseleave', (e: Event) => {
      if (this.isMobile) return; // only respond to mouseenter when not on mobile

      e.stopPropagation();
      let help = $(e.target).closest('.help');
      this.toggleGuide($('#measurement-guide .guide-text'), help.data('type'), false);
    });

    this.initImage();
    this.initGuides();
    this.bindFormControls();

    this.state.actions$.pipe(
      filter(e => e.name === Events.MODAL_CLOSED)
    ).subscribe(action => this.initGuides());
  }

  /**
   * copy the select for the selected measurement type
   **/
  private duplicateMeasurementSelect(type: string): JQuery {
    let inputSelector = `#child_measurement_${type}_${this.currentUnit}`;
    let inputContainer = $('<div/>');
    inputContainer.addClass('modal-input-container');

    let input = $(inputSelector).clone(true);
    let label = $(`<label>${this.currentUnit}</label>`);

    input.prop('id', `modal_${input.attr('id')}` );
    input.find('option:selected').prop('selected',false);
    input.find(`option[value="${$(inputSelector).val()}"]`).prop('selected',true);
    // input.trigger('change');

    inputContainer.append(label);
    inputContainer.append(input);

    return inputContainer;
  }

  /**
   * Bind events for the selects
   **/
  private bindFormControls(): void {
    let controls = $('.measurement-controls').find(`select.${this.currentUnit}`);

    controls.on('mouseenter focus', (e: Event) => {
      let type = $(e.target).data('name');

      this.toggleGuide($('#measurement-guide .guide-text'),type, true);
    });

    controls.on('mouseleave blur', (e: Event) => {
      let type = $(e.target).data('name');
      this.toggleGuide($('#measurement-guide .guide-text'),type, false);
    });
  }

  /**
   * Setup the main "silhouette" image
   **/
  private initImage(container: JQuery = this.container, forModal = false): JQuery {
    let mainImageContainer = $('<div/>');
    let imgTag = $('<img/>');

    imgTag.attr('src', this.mainImagePath);
    mainImageContainer.addClass('main-image');
    mainImageContainer.append(imgTag);

    container.append(mainImageContainer);

    this.mainImage = mainImageContainer;

    return this.mainImage;
  }

  /**
   * show/hide a guide for a specific measurement
   **/
  private initGuides(container: JQuery = this.container, showSpecific: string = null, forModal = false): void {
    for (const [key, value] of Object.entries(this.guidelines)) {
      let guide = $('<div/>');
      let guideImage = $('<img/>');

      guideImage.attr('src', value['imagePath']);

      guide.addClass('guide');
      guide.addClass(key);

      if(showSpecific != key) {
        guide.addClass('hidden');
      }

      guide.append(guideImage);
      container.append(guide);

      this.guideElements[key] = guide;
    }
  }

  /**
   * show/hide a guide for a specific measurement
   **/
  private toggleGuide(container: JQuery, type: string, show: boolean) {
    if (show && container) {
      container.html(this.guidelines[type]['message']);
      container.removeClass('hidden');

      this.guideElements[type].removeClass('hidden');
    } else {
      container.addClass('hidden');
      container.empty();

      this.guideElements[type].addClass('hidden');
    }
  }

  /**
   * toggle the selects when changing measurement unit
   **/
  private toggleControls(old_unit: string, new_unit: string): void {
    if (this.currentUnit == old_unit) {
      this.currentUnit = `${old_unit}_changing`;

      for (let item of Array.from($('.measurement_select.' + old_unit))) {
        const id = $(item).attr('id');
        const new_id = id.replace(old_unit, new_unit);

        // Toggle visibilities
        $(`#${id}, #${new_id}`).toggleClass('hidden');
        $(`#${id}_label, #${new_id}_label`).toggleClass('hidden');
      }

      this.changeMeasurementUnits(new_unit);
    }
  }

  private changeMeasurementUnits(new_unit: string): void {
    this.currentUnit = new_unit;

    $('#measurement_unit_value').val(new_unit);
    $('#select_cm, #select_inches').toggleClass('hidden');
  }
}
