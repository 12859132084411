import { Http, Module, TurboLinks } from 'shared/core';
import { Injectable } from 'injection-js';
import { FancySelect, Select } from 'shared/lib';
import { OptionData } from 'shared/models';

export interface PreviewFilterResponse {
  houses: any[];
  genders: any[];
}

export interface PreviewFilterOptions {
  setHouses?: boolean;
  setGenders?: boolean;
}

@Injectable()
export class ShopModule extends Module {
  private uniformRegulations = $('#uniformRegulations');
  private uniformRegulationsTrigger = $('#uniformRegulationsLink');
  private shopChildSelection = $('.shop-children-select');

  private previewFilters = $('#preview_filters');
  private previewToken = $('#preview_token');
  private filterSubSchool = $('#filter_sub_school_id');
  private filterHouse = new Select($('#filter_house_id'));
  private filterGender = new Select($('#filter_gender'));
  private hiddenGender: JQuery = $('#hidden-gender');

  constructor(
    private turbolinks: TurboLinks,
    private http: Http
  ) {
    super();
  }

  public init(): void {
    this.uniformRegulationsTrigger.on('click', () => this.toggleUniformRegulations());
    new FancySelect(this.shopChildSelection, { width: null, height: null, background: null, onSelected: e => this.onSelected(e) });

    if (this.previewFilters.length == 1) {
      this.setupPreviewFilters();
    }
  }

  private onSelected(e) {
    this.turbolinks.visit(e.selectedData.value);
  }

  private toggleUniformRegulations(): void {
    this.uniformRegulations.toggleClass('visible');
  }

  private setupPreviewFilters(): void {
    this.filterSubSchool.on('change', () => this.fetchData({
      setHouses: true,
      setGenders: true,
    }));

    this.filterHouse.select.on('change', () => this.fetchData({
      setGenders: true,
    }));

    this.fetchData({
      setHouses: true,
      setGenders: true,
    });
  }

  private async fetchData(options: PreviewFilterOptions): Promise<void> {
    if (typeof this.filterSubSchool.val() == typeof undefined || this.filterSubSchool.val().toString().length == 0) {
      this.render({ houses: [], genders: [] }, { setGenders: true, setHouses: true });
      return;
    }

    const results = await this.http.get<PreviewFilterResponse>('curriculum_collections/filter_options', {
      preview_token: this.previewToken.val(),
      'filter[sub_school_id]': this.filterSubSchool.val(),
      'filter[house_id]': this.filterHouse.select.val()
    });

    this.render(results, options);
  }

  private render(previewFilterResponse: PreviewFilterResponse, options: PreviewFilterOptions): void {
    const { houses, genders } = previewFilterResponse;
    const { setHouses, setGenders } = options;

    if (setHouses) {
      if (houses.length > 0) {
        let optionData: OptionData[] = [];
        houses.forEach(house => optionData.push({ text: house.name, value: house.id }));
        this.filterHouse.replaceOptionsWith(optionData, 'Please select a house', true);
      } else {
        this.filterHouse.clearAllOptions();
      }
      this.filterHouse.select.prop('disabled', houses.length == 0);
    }

    if (setGenders) {
      let isSingleGender = genders.length == 1;
      let currentGender = this.filterGender.select.val();

      if (genders.length > 0) {
        let optionData: OptionData[] = [];

        genders.forEach(gender => optionData.push({ text: gender[0], value: gender[1] }));
        this.filterGender.replaceOptionsWith(optionData, 'Please select sex', true);

        if (isSingleGender) {
          this.filterGender.select.val(genders[0][1]);
          this.filterGender.select.hide();
          this.filterGender.select.parent().find('label').hide();
        } else {
          this.filterGender.select.show();
          this.filterGender.select.parent().find('label').show();
        }
        this.hiddenGender.val(currentGender);
      } else {
        this.filterGender.clearAllOptions();
      }

      this.filterGender.select.prop('disabled', genders.length == 0);
    }
  }
}
