import { fromEvent } from 'rxjs';
import { debounceTime, filter, share, tap } from 'rxjs/operators';
import { Actions, Events } from 'shared/state';
import { Element, State } from 'shared/core';
import { CheckFonts } from 'shared/lib';
import { Injectable } from 'injection-js';

@Injectable()
export class ButtonStacking extends Element {
  constructor(private state: State) {
    super();

    fromEvent(window, 'resize').pipe(
      debounceTime(50),
      tap(() => ButtonStacking.stackButtons($('.stackable-buttons'))),
      share(),
    ).subscribe();

    this.state.actions$.pipe(filter(action => action.name === Actions.STACK_BUTTONS)).subscribe(action => {
      ButtonStacking.stackButtons(action.payload);
    });

    this.state.actions$.pipe(filter(action => action.name === Events.FONTS_LOADED)).subscribe(() => {
      ButtonStacking.stackButtons($('.stackable-buttons'));
    });

    ButtonStacking.stackButtons($('.stackable-buttons'));
  }

  public init(): void {
    new CheckFonts();
  }

  public static stackButtons(elements: JQuery): void {
    elements.each((index, element) => ButtonStacking.processContainer($(element)));
  }

  public static processContainer(container: JQuery): void {
    // Only do this if the container is visible otherwise the widths are 0!!!
    if (container.is(':visible')) {
      let currentlyStacked: boolean = container.hasClass('buttons-stacked');
      let containerWidth: number = container.innerWidth();
      let totalButtonsWidth = 0;

      // If unable to determine from the left and right divs then check the buttons
      container.find("a, button, input[type='submit']").each((index, button) => {
        totalButtonsWidth += ButtonStacking.processElement(currentlyStacked, $(button));
      });

      // Check if we need to stack or not.
      if (totalButtonsWidth >= containerWidth) {
        container.addClass('buttons-stacked');
      } else {
        container.removeClass('buttons-stacked');
      }

      // Set the containers opacity to 1 so that it becomes visible.
      container.css('opacity', 1);
    }
  }

  public static processElement(currentlyStacked: boolean, element: JQuery): number {
    let elementWidth = 0;
    let originalWidth: number = ($(element).data('originalWidth') as number);
    let actualWidth: number = $(element).outerWidth() + parseFloat($(element).css('marginRight'));

    // If it is currently stacked then just get the original pre-stacked width.
    if (currentlyStacked && originalWidth > 0) {
      elementWidth = originalWidth;
    } else {
      // No we haven't precessed this element yet so the width should be correct
      //  so we need to use this and store it in a data attribute.
      elementWidth = actualWidth;

      if (elementWidth > 0) {
        $(element).data('originalWidth', elementWidth);
      }
    }

    return elementWidth;
  }
}
