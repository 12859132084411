import { Module } from 'shared/core';

export class SubscriptionModule extends Module {
  init(): void {
    $('body').on('change', '#unsubscribe_feedback_reason', () => {
      this.reasonChange();
    });

    this.reasonChange();
  }

  private reasonChange(): void {
    let showReason = $('#unsubscribe_feedback_reason').val() == '';

    $('.unsubscribe_feedback_custom_text').toggle(showReason);
  }
}
