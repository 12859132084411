/* Add styles to pack */
import '../styles/webshop/index.scss';

/* Add images to pack */
require['context']('../images/webshop', true);
require['context']('../images/shared', true);
require['context']('../fonts/volkswagen', true);
require['context']('../fonts/galano', true);

/* Add rails specific libs */
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

Rails.start();
Turbolinks.start();

import 'reflect-metadata';

import * as SharedCore from 'shared/core';
import * as SharedElements from 'shared/elements';
import { WindowBase } from 'shared/models/windowBase';

import * as WebshopCore from 'webshop/core';
import * as WebshopModules from 'webshop/modules';
import * as WebshopElements from 'webshop/elements';

/* Create and export to global scope the App */
export const Webshop = new SharedCore.App({
  /* Providers are singletons used across the app */
  providers: [
    SharedCore.State,
    SharedCore.Display,
    SharedCore.Forms,
    SharedCore.Router,
    SharedCore.TurboLinks,
    SharedCore.Http,
    WebshopCore.Validators,
  ],
  /* Elements are shared, reused across different views */
  elements: [
    SharedElements.AccordionElement,
    SharedElements.ButtonStacking,
    SharedElements.DoubleClickProtectionElement,
    SharedElements.ExpandElement,
    SharedElements.FlashElement,
    SharedElements.FormsElement,
    SharedElements.LayoutElement,
    SharedElements.ModalElement,
    SharedElements.TabsElement,
    SharedElements.TooltipElement,
    SharedElements.TypeAheadListElement,

    WebshopElements.BasketElement,
    WebshopElements.BreadcrumbsElement,
    WebshopElements.MenuElement,
  ],
  /* Modules are executed only on particular view based on path */
  routes: [
    {
      path: '',
      exact: true,
      modules: [WebshopModules.RedirectModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['account', 'subscription_edit'],
      modules: [WebshopModules.SubscriptionModule],
    },
    {
      path: 'account',
      modules: [WebshopModules.AccountModule, WebshopModules.ChildrenModule],
    },
    {
      path: 'addresses',
      modules: [WebshopModules.AddressesModule],
    },
    {
      path: 'children',
      exclude: ['edit_measurements', 'update_measurements'],
      modules: [WebshopModules.ChildrenModule],
    },
    {
      path: ['children', 'edit_measurements'],
      modules: [WebshopModules.ChildrenMeasurementsModule],
    },
    {
      path: ['children', 'update_measurements'],
      modules: [WebshopModules.ChildrenMeasurementsModule],
    },
    {
      path: 'faqs',
      modules: [WebshopModules.FaqModule],
    },
    {
      path: 'order',
      exact: true,
      modules: [WebshopModules.CheckoutModule, WebshopModules.ChangeAddressModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['order', 'forgotten'],
      modules: [WebshopModules.ForgottenModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['order', 'name_tags'],
      modules: [WebshopModules.NameTagsModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['order', 'shipping'],
      modules: [WebshopModules.ShippingModule, WebshopModules.ChangeAddressModule, WebshopModules.DeliveryOptionsModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['order', 'check_shipping_country'],
      modules: [WebshopModules.ShippingModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['order', 'check_duplication'],
      modules: [],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['order', 'add_fasttrack'],
      modules: [],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['order', 'remove_fasttrack'],
      modules: [],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['order', 'payment'],
      modules: [WebshopModules.PaymentModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['order', 'finalize'],
      modules: [WebshopModules.PaymentAuthenticationModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['smartpay_checkouts', 'finalize'],
      modules: [WebshopModules.PaymentAuthenticationModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['invoices', 'payment'],
      modules: [WebshopModules.PaymentModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['invoices', 'finalize_with_card'],
      modules: [WebshopModules.PaymentAuthenticationModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['invoices', 'finalize_with_smartpay'],
      modules: [WebshopModules.PaymentAuthenticationModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: ['invoices'],
      modules: [WebshopModules.InvoiceModule]
    },
    {
      path: ['order', 'finalise'],
      modules: [],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: 'order_item_personalisations',
      modules: [WebshopModules.PersonalisationsModule],
      disable: [WebshopElements.BasketElement],
    },
    {
      path: 'orders',
      exclude: ['returns'],
      modules: [WebshopModules.OrdersModule],
    },
    {
      path: 'returns',
      exclude: ['confirm'],
      modules: [WebshopModules.OnlineReturnsModule, WebshopModules.StockRequestModule],
    },
    {
      path: ['returns', 'confirm'],
      modules: [WebshopModules.OnlineReturnsConfirmModule],
    },
    {
      path: 'curriculum_collections',
      modules: [WebshopModules.ShopModule, WebshopModules.ChangeAddressModule],
    },
    {
      path: 'spors',
      modules: [WebshopModules.ProductModule, WebshopModules.ProductGalleryModule, WebshopModules.ChangeAddressModule],
    },
    {
      path: 'sub_school_products',
      modules: [WebshopModules.ProductModule, WebshopModules.ProductGalleryModule, WebshopModules.ChangeAddressModule, WebshopModules.StockRequestModule],
    },
    {
      path: 'news',
      modules: [WebshopModules.NewsModule],
    },
    {
      path: 'contact_us',
      modules: [WebshopModules.ContactUsModule],
    },
    {
      path: 'custom_enquiries',
      modules: [WebshopModules.ContactUsModule],
    },
    {
      path: ['gdpr', 'subject_access_request'],
      modules: [WebshopModules.GdprModule],
    },
    {
      path: ['gdpr', 'rte_request'],
      modules: [WebshopModules.GdprModule],
    },
    {
      path: ['repairs', 'confirmation'],
      modules: [WebshopModules.RepairsConfirmModule],
    },
    {
      path: ['repairs', 'new'],
      modules: [WebshopModules.RepairsNewModule]
    },
    {
      path: ['repairs'],
      modules: [WebshopModules.RepairsModule],
    }
  ]
});

// Expose App Outside of Webpack Scope
declare global {
  interface Window extends WindowBase {
    /* Pca Adressess */
    pca: any;
    CapturePlusLoaded: any;
    CapturePlusCallback: any;
    google: any;
  }
}

window.App = Webshop;

// Must be the last thing to run on the setup
Webshop.run();
