import { Http, Module, State } from 'shared/core';
import { Injectable } from 'injection-js';
import { Tabs, TypeAheadList } from 'shared/lib';
import { fromEvent, merge } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Actions } from 'shared/state';

export interface ChildDataResponse {
  subSchools: any[];
  houses: any[];
  isHouseRequired: boolean;
  genders: any[];
  crest: string;
}

export interface ChildDataOptions {
  setSubschools?: boolean;
  setHouses?: boolean;
  setGenders?: boolean;
}

@Injectable()
export class ChildrenModule extends Module {
  private schoolSelect: JQuery = $('#child_school_id');
  private subschoolSelect: JQuery = $('#child_sub_school_id');
  private housesSelect: JQuery = $('#child_house_id');
  private genderSelect: JQuery = $('#child_gender');
  private hiddenGender: JQuery = $('#hidden-gender');
  private genderSelectContainer: JQuery = $('.gender-select');
  private crest: JQuery = $('#schoolCrest');
  private secondaryFields: JQuery = $('.secondaryFields, .birthday');

  private subschoolOriginal: JQuery = $('#child_sub_school_id_original');
  private houseOriginal: JQuery = $('#child_house_id_original');
  private genderOriginal: JQuery = $('#child_gender_original');

  private nameTag: JQuery = $('#nameTagLine');
  private nameTagInput: JQuery = $('#child_name_tag_text');
  private nameTagInputExtended: JQuery = $('#child_name_tag_text_line_1');
  private nameTagHiddenInput: JQuery = $('#child_name_tag_text');
  private nameTagInputOptional: JQuery = $('#child_name_tag_text_input_optional');
  private defaultNameTagText = '';
  private childId: string = $('.children-edit').data('childId');
  private firstName: JQuery = $('#child_first_name');
  private lastName: JQuery = $('#child_last_name');

  private form: JQuery<HTMLFormElement> = $('#child_form');

  private houseRequired = false;

  private tabs: Tabs = new Tabs({
    id: 'child-details',
    remote: true,
    queryParam: 'current_child_id',
    get: this.getChild.bind(this),
  });

  constructor(
    private http: Http,
    private state: State,
  ) {
    super();
  }

  public init(): void {
    TypeAheadList.withAjaxSource(this.schoolSelect, '/children/schools');

    this.schoolSelect.on('change', () => this.fetchData({
      setSubschools: true,
      setHouses: true,
      setGenders: true,
    }));

    this.subschoolSelect.on('change', () => this.fetchData({
      setHouses: true,
      setGenders: true,
    }));

    this.housesSelect.on('change', () => this.fetchData({
      setGenders: true,
    }));

    this.form.on('submit', (e:JQuery.SubmitEvent) => {
      // Check if the form is valid otherwise let the browser take it's default action
      // if the browser supports the checkValidity method.
      if (typeof this.form[0].checkValidity !== typeof Function || this.form[0].checkValidity()) {
        // Check the form hasn't already gone through this...
        if (this.form.data('submitProceed') !== true) {
          this.basketReloadWarning(e);
        } else {
          this.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: this.form });
        }
      }
    });

    this.nameTagInputExtended.on('keyup', (e:JQuery.KeyUpEvent) => {
      let val = ($(e.target).val() as string);
      if (val.length >= 20 && !this.nameTagInputOptional.val()) {
        this.nameTagInputOptional.val('');
        this.nameTagInputOptional.removeClass('hidden');
      } else if (val.length < 1 && !this.nameTagInputOptional.val()) {
        this.nameTagInputOptional.addClass('hidden');
        this.nameTagInputOptional.val('');
      }
    });

    if (this.nameTagInputOptional.val()) {
      this.nameTagInputOptional.removeClass('hidden');
    }

    this.bindNameTags();
  }

  // Determines if a basket warning needs to be displayed, displays it if so and cancels form submit.
  private basketReloadWarning(event: JQuery.SubmitEvent):void {
    if (!this.form.hasClass('edit')) {
      // Does not need to do anything
      this.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: this.form });
      return;
    }

    const subschoolChanged = this.subschoolSelect.val() != this.subschoolOriginal.val() && (<string>this.subschoolOriginal.val()).length > 0;
    const houseChanged = this.housesSelect.val() != this.houseOriginal.val() && (<string>this.houseOriginal.val()).length > 0;
    const genderChanged = this.genderSelect.val() != this.genderOriginal.val() && (<string>this.genderOriginal.val()).length > 0;

    let has_basket_items: boolean = $('.basket-element').find(`[data-child-id=${this.childId}]`).length > 0;

    if ((subschoolChanged || houseChanged || genderChanged) && has_basket_items) {
      // Cancel the form submit
      event.preventDefault();

      $('#clear_basket_warning .continue').on('click', () => {
        this.form.data('submitProceed', true); // Prevent this warning happening a second time
        this.form.trigger('submit');
      });

      this.state.dispatch({ name: Actions.OPEN_STATIC_MODAL, payload: 'clear_basket_warning' });
    } else {
      this.state.dispatch({ name: Actions.DISABLE_CONTAINER, payload: this.form });
    }
  }

  private bindNameTags(): void {
    let elements = [this.nameTagInput];

    if (this.nameTagInputExtended) {
      elements = [this.nameTagInputExtended];
    }

    if (this.nameTagInputOptional) {
      elements.push(this.nameTagInputOptional);
    }

    fromEvent<any>(elements, 'input').pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(() => this.setNameTag());
    merge(
      fromEvent(this.firstName, 'input'),
      fromEvent(this.lastName, 'input')
    ).pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(() => this.setNameTagServerSide());
  }

  private async fetchData(options: ChildDataOptions): Promise<void> {
    const results = await this.http.get<ChildDataResponse>('children/school_data', {
      school_id: this.schoolSelect.val(),
      sub_school_id: this.subschoolSelect.val(),
      house_id: this.housesSelect.val(),
    });

    this.render(results, options);
  }

  private render(childResponse: ChildDataResponse, options: ChildDataOptions): void {
    const { subSchools, crest, houses, isHouseRequired, genders } = childResponse;
    const { setSubschools, setHouses, setGenders } = options;
    this.houseRequired = isHouseRequired;

    if (setSubschools) {
      this.subschoolSelect.html('<option></option>');
      subSchools.forEach(subschool => this.subschoolSelect.append(`<option value="${subschool.id}">${subschool.name}</option>`));
      this.subschoolSelect.prop('disabled', false);
      this.crest.html('');

      if (crest) {
        this.crest.append(`<img src="${crest}">`);
      }
    }

    if (setHouses) {
      if (houses.length > 0) {
        this.housesSelect.html('<option></option>');
        houses.forEach(house => this.housesSelect.append(`<option value="${house.id}">${house.name}</option>`));
        this.housesSelect.prop('disabled', false);
      } else {
        this.housesSelect.html('');
        this.housesSelect.prop('disabled', true);
      }
    }

    if (setGenders) {
      let isSingleGender = genders.length == 1;
      let currentGender = this.genderSelect.val() ? this.genderSelect.val() : this.genderOriginal.val();
      this.genderSelect.html('<option></option>');
      genders.forEach(gender => this.genderSelect.append(`<option value="${gender[1]}">${gender[0]}</option>`));

      if (isSingleGender) {
        this.hiddenGender.val(genders[0][1]);
        this.genderSelect.val(genders[0][1]);
        this.genderSelectContainer.hide();
      } else {
        this.hiddenGender.val('');
        this.genderSelect.val('');
        this.genderSelectContainer.show();
      }
    }

    this.setNameTagServerSide();
    this.validateStep();
  }

  private validateStep(): void {
    const valid = this.subschoolSelect.val() !== '' && this.schoolSelect.val() !== '' && (this.housesSelect.val() !== '' || !this.houseRequired);
    this.secondaryFields.prop('disabled', !valid);
  }

  private setNameTag(): void {
    let nameTagText: string = (this.nameTagInputExtended ? this.nameTagInputExtended.val() : this.nameTagInput.val()) as string;
    let optionalText = this.nameTagInputOptional.val() as string;
    nameTagText = this.sanitize_name_tag(nameTagText);

    if (optionalText) {
      optionalText = this.sanitize_name_tag(optionalText);
    }

    if (this.nameTagInputOptional.val() != '') {
      if (optionalText) {
        nameTagText = `${nameTagText}\t${optionalText}`;
      }
    }

    if (nameTagText.length > 0) {
      this.nameTag.html(nameTagText.replace(/\t/, '<br/>'));
    } else {
      this.nameTag.html(this.defaultNameTagText.replace(/\t/, '<br/>'));
      this.nameTagInput.val(this.defaultNameTagText);
    }

    if (this.nameTagHiddenInput) {
      this.nameTagHiddenInput.val(nameTagText);
    }
  }

  private sanitize_name_tag(name_tag_text): string {
    if (typeof name_tag_text == typeof undefined) {
      return '';
    }
    return name_tag_text.trim().replace(/\s+/g, ' ');
  }

  private async setNameTagServerSide(): Promise<void> {
    const { name_tag_text }: any = await this.http.get('children/name_tag_text', {
      first_name: this.firstName.val(),
      last_name: this.lastName.val(),
      school_id: this.schoolSelect.val(),
      sub_school_id: this.subschoolSelect.val(),
      house_id: this.housesSelect.val(),
    });

    this.defaultNameTagText = name_tag_text;
    this.setNameTag();
  }

  private async getChild(id: number): Promise<Document> {
    return await this.http.getHtml(`children/${id}`, { partial: true });
  }
}
