import { Injectable } from 'injection-js';
import { Module, Http, State } from 'shared/core';
import { Checksum } from 'shared/core';
import { PostcodeAnywhereUrlsResponse } from 'webshop/models';
import { Webshop } from 'apps/webshop';
import { Actions } from 'shared/state';

@Injectable()
export class AddressesModule extends Module {
  private toggleElement: JQuery = $('.toggleBfpo');
  private customerForm: JQuery = $('.addressFormCustomer');
  private bfpoForm: JQuery = $('.addressFormBfpo');
  private checksum: JQuery = $('#address_checksum');
  private showBack: JQuery = $('.backButton');
  private verified: JQuery = $("input[id='verified']");

  constructor(
    private http: Http,
    private state: State,
  ) {
    super();
  }

  public init(): void {
    this.state.get.subscribe(state => {
      this.toggleBfpo(state.addresses?.bfpoErrors);
      if (state.addresses?.showBack) {
        this.showBack.show();
      }
    });

    this.toggleElement.on('change', (e: JQuery.ChangeEvent) => this.toggleBfpo(e.target.checked));

    // Cache Checksum if it's a valid address, otherwise blank
    if (this.verified.val() == 'true') {
      this.checksum.val(AddressesModule.Checksum);
    } else {
      this.checksum.val('');
    }
    this.customerForm.on('click', () => this.validateChecksum());

    this.loadPostcodeAnywhere();
  }

  // Dynamically load PCA and afterwards set the checksum callback and country
  private async loadPostcodeAnywhere() : Promise<void> {
    let response: PostcodeAnywhereUrlsResponse = await this.http.get<PostcodeAnywhereUrlsResponse>('addresses/postcode_anywhere_urls');
    await jQuery.getScript(response.script_url);


    $('<link/>', {
      rel: 'stylesheet',
      type: 'text/css',
      href: response.css_url
    }).appendTo('head');

    window.CapturePlusCallback = () => {
      // Update a hash of the address for later comparison
      this.checksum.val(AddressesModule.Checksum);
      // This is for hidden_field
      this.verified.val('1');
    };

    AddressesModule.assignPostcodeAnywhereCountry();

    // Reset the autocomplete since PCA changes it to 'off' which does not work in Chrome
    this.state.dispatch({ name: Actions.DISALLOW_AUTOCOMPLETE });
  }

  private static assignPostcodeAnywhereCountry(): void {
    const country = $('#customer_address_country_id option:selected').text();
    window.pca.capturePlus?.setCountry(country);
  }

  private toggleBfpo(bfpo: boolean): void {
    if (this.customerForm.length === 0) {
      return;
    }

    if (bfpo) {
      Webshop.display.hideElement(this.customerForm);
      Webshop.display.showElement(this.bfpoForm);
    } else {
      Webshop.display.showElement(this.customerForm);
      Webshop.display.hideElement(this.bfpoForm);
    }
  }

  private static concatenate(): string {
    const concatenated = '';
    return concatenated.concat(
      ($('input#customer_address_address_line_1').val() as string),
      ($('input#customer_address_address_line_2').val() as string),
      ($('input#customer_address_address_line_3').val() as string),
      ($('input#customer_address_city').val() as string),
      ($('input#customer_address_region').val() as string),
      ($('input#customer_address_postal_code').val() as string)
    ).replace(/\W/g, '');
  }

  private static get Checksum(): string {
    const value = AddressesModule.concatenate();
    return Checksum.stringCheck(value);
  }

  private validateChecksum(): void {
    if (AddressesModule.Checksum == this.checksum.val()) {
      this.verified.val('1');
    } else {
      this.verified.val('0');
    }
  }
}
