import { Injectable } from 'injection-js';
import { fromEvent } from 'rxjs';
import { share } from 'rxjs/operators';
import Turbolinks from 'turbolinks';

@Injectable()
export class TurboLinks {
  public navigation$ = null;
  public visit$ = null;

  constructor() {
    // Intentionally left empty
  }

  public createWithTurbolinks(): void {
    this.navigation$ = fromEvent(document, 'turbolinks:load').pipe(share());
    this.visit$ = fromEvent(document, 'turbolinks:before-render').pipe(share());
  }

  public createWithoutTurbolinks(): void {
    this.navigation$ = fromEvent(document, 'load').pipe(share());
    this.visit$ = fromEvent(document, 'load').pipe(share());
  }

  public visit(path: string): void {
    if (window.App.useTurbolinks) {
      Turbolinks.visit(path);
    } else {
      window.location.href = path;
    }
  }
}
