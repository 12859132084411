import { Injectable } from 'injection-js';
import { Http, Module, State, TurboLinks, Url } from 'shared/core';
import { Flash } from 'shared/lib';
import { filter, takeUntil } from 'rxjs/operators';
import { Events } from 'shared/state';
import 'webshop/elements/sizingselect';

export interface FitStatement {
  name?: string;
  statement: string;
}

export interface FitStatements {
  current_year: any[];
  next_year: any[];
  product_price: string;
  quantity_in_stock: number;
}

export interface FitStatementsParsed {
  yearCurrent: FitStatement[];
  yearNext: FitStatement[];
}

@Injectable()
export class ProductModule extends Module {
  private buyButton: JQuery = $('#productBuy');
  private subSchoolItemId: JQuery = $('#order_item_sub_school_item_id');
  private suggestion: JQuery = $('#order_item_suggested');
  private childId: JQuery = $('#child_id');
  private productSizing: JQuery = $('.product-sizing');
  private yearCurrent: JQuery = $('#yearCurrent');
  private yearNext: JQuery = $('#yearNext');
  private diagramHelpers: JQuery = $('#diagramHelpers');
  private productPrice: JQuery = $('#product-price');
  private requestEmailButton: JQuery = $('#requestEmailButton');
  private shopOutOfStock: JQuery = $('.shop_out_of_stock');
  private shopInStock: JQuery = $('.shop_in_stock');
  private emailRequested: JQuery = $('.email_requested');
  private quantity: JQuery = $('#order_item_quantity');
  private subSchoolItemIdRequested: JQuery = $('#sub_school_item_id');

  private sbSizeSelect: JQuery;
  private previewMode = false;

  private statements: FitStatementsParsed;
  private productPriceResult: string;
  private productQtyInStock: number;
  private quantityParam = 'quantity';
  private idParam = 'item_id';

  constructor(
    private http: Http,
    private state: State,
    private turbolinks: TurboLinks
  ) {
    super();
  }

  public init(): void {

    if (this.subSchoolItemId.length == 1 && this.subSchoolItemId.html()) {
      const cached_id = Url.getQueryString(this.idParam);
      if (cached_id && cached_id != this.subSchoolItemId.val()) {
        this.subSchoolItemId.val(cached_id);
      }

      this.sbSizeSelect = (this.subSchoolItemId as any).sbsizing({
        maxHeight: 320,
        onSelected: () => this.onSelected(),
      });
    }

    if ($('.product.preview').length > 0) {
      this.previewMode = true;
    }

    // Disable backorders for TOS items according to TJ.
    this.toggleBasketControls();
    $('body').on('click', this.requestEmailButton,  () => {
      this.setupEmailRequestForm();
    });

    this.state.actions$.pipe(
      filter(e => e.name === Events.STOCK_REQUEST_SUBMITTED),
      takeUntil(this.turbolinks.visit$)
    ).subscribe(e => this.reloadItems());

    // Cache quantity if value changes...
    $('body').on('change', '#order_item_quantity', () => { this.cache_value(this.quantityParam, this.quantity.val()); });
  }

  private async reloadItems() {
    this.turbolinks.visit(null);
  }

  private setupEmailRequestForm(): void {
    this.subSchoolItemIdRequested.val(this.subSchoolItemId.val());
  }

  // Switch between 'Add to basket' and 'Request email' button
  private toggleBasketControls(): void {
    let backorder = false;
    let email_requested = false;

    if (this.suggestion.val() == 'OneSize') {
      let form = $('#one_size_form');
      backorder = form.data('back-order');
      email_requested = form.data('email-requested');
    } else {
      backorder = this.subSchoolItemId.find(':selected').data('backorder');
      email_requested = this.subSchoolItemId.find(':selected').data('email-requested');
    }

    if (backorder) {
      this.shopInStock.hide();

      if (email_requested) {
        this.shopOutOfStock.hide();
      } else {
        this.shopOutOfStock.show();
      }

      if (email_requested) {
        this.emailRequested.show();
      }
    } else {
      this.shopInStock.show();
      this.shopOutOfStock.hide();
      this.emailRequested.hide();
    }
  }

  private onSelected(): void {
    // Cache item id if value changes...
    this.cache_value(this.idParam, this.subSchoolItemId.val());

    // Find && Save Sizing Suggestions for Chosen Product
    const suggestion = this.subSchoolItemId.find(':selected').data('suggested');
    this.suggestion.val(suggestion);

    // Refresh Fit Statements
    this.refreshFitStatements();

    if (!this.previewMode) {
      // Validate Chosen Size
      this.validateSize();
    }

    this.toggleBasketControls();
  }

  // Add a value to the querystring...
  private cache_value(param: string, value: any): void {
    const cached_value = Url.getQueryString(param);
    if(value != cached_value) {
      window.history.replaceState(null, document.title, Url.updateUrlParam(window.location.href, param, value));
    }
  }

  private async refreshFitStatements(): Promise<void> {
    if (!this.subSchoolItemId.val()) {
      return;
    }

    const statements: FitStatements = await this.http.get<FitStatements>('sub_school_products/fit_statements', {
      child_id: this.childId.val(),
      sub_school_item_id: this.subSchoolItemId.val(),
    });

    this.statements = {
      yearCurrent: statements.current_year.map(i => ({
        statement: i.statement,
        name: i.name,
      })),

      yearNext: statements.next_year.map(({ statement }) => ({ statement })),
    };

    this.productPriceResult = statements.product_price;
    this.productQtyInStock = statements.quantity_in_stock;
    this.render();
  }

  private render(): void {
    this.renderFitStatement(this.yearCurrent, this.statements.yearCurrent);
    this.renderFitStatement(this.yearNext, this.statements.yearNext);

    this.productSizing.show(); // Container must be visible to check visibility of child elements

    if (!this.yearCurrent.is(':visible') && !this.yearNext.is(':visible')) {
      this.productSizing.hide();
    } else {
      this.productSizing.show();
    }

    this.renderMeasurementsDiagram();
    this.renderPrice();
    this.renderQtySelect();
  }

  private renderFitStatement(property: JQuery, yearStatement: FitStatement[]): void {
    const sizeValues: JQuery = property.find('.sizingValues');
    sizeValues.html('');

    if (yearStatement.length == 0) {
      property.hide();
    } else {
      property.show();
    }

    yearStatement.forEach(data => {
      sizeValues.append(`<p>${data.statement}</p>`);
    });
  }

  private renderMeasurementsDiagram(): void {
    this.diagramHelpers.html('');
    this.statements.yearCurrent.forEach(data => {
      this.diagramHelpers.append(`<div class="measurements-diagram-silhouettes-${data.name}" />`);
    });
  }

  private renderPrice(): void {
    this.productPrice.html(this.productPriceResult);
  }

  private renderQtySelect(): void {
    let qtySelect = $('#order_item_quantity');

    qtySelect.empty();
    for(let i = 1; i <= this.productQtyInStock; i++){
      qtySelect.append(`<option value="${i}">${i}</option>`);
    }

    const cached_quantity = Url.getQueryString(this.quantityParam);
    if (cached_quantity && cached_quantity != this.quantity.val()) {
      this.quantity.val(cached_quantity);
    }
  }

  private validateSize(): void {
    if (!this.subSchoolItemId.val()) {
      this.buyButton.prop('disabled', true);
    } else {
      this.buyButton.prop('disabled', false);
    }
  }

  public cleanup(): void {
    /* Since SbSizing has not destroy method manual cleanup needs to be done */
    $('.dd-container').remove();
  }
}
