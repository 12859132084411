export abstract class Element {
    protected body: JQuery = $('body');
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    constructor(...args) {
        // Intentionally left empty
    }

    public abstract init(): void|Promise<void>;

    public disable(): void|Promise<void> {
        // Intentionally left empty
    }
}
