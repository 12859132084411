import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';

export class Datepicker {
  private readonly options:DatepickerOptions = {
    format: 'dd-mm-yyyy'
  };

  protected picker: any;
  protected selector:JQuery;

  constructor(element:string|JQuery, options?:DatepickerOptions) {
    this.options = { ...this.options, ...options };

    if (!(element instanceof jQuery)) {
      this.selector = $(<string>element);
    } else {
      this.selector = <JQuery>element;
    }

    this.picker = this.selector.datepicker(this.options);
  }

  public on(eventName:DatepickerEvents, handler: (eventObject: DatepickerEventObject) => any): void {
    this.picker.on(eventName, handler);
  }

  public destroy(): void {
    this.picker.destroy();
  }
}
