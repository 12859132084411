import { State } from 'shared/core/state';
import { Events } from 'shared/state';
import { ModelComponentDataElem, ModelComponentElem, ModelComponentOptions } from 'shared/models';
import { CommentableComment } from 'shared/models/commentable_comment';
/**
 * A model component represents a large element in the page that holds data.
 *
 * E.g. you might want to list customers in a table, each row is a ModelComponent.  The row exists as the
 * component part, the data as a model part.  This is an attempt to avoid complete modules.
 *
 * **Example:**
 *
 * TS:
 * ```
 * elements: { 'col1': {}, 'col2': {hidden: true}}
 * data: { name: {} }
 * ```
 *
 * HAML:
 * ```
 * %tr.js-row{ data: { name: 'my_name' }}
 *   %td.js-col1{data: { value: total }}
 *     This is just random text for a demo
 *   %td.js-col2
 *     = sku
 * ```
 *
 * TS:
 * ```
 * console.log(this.elements['col1'].value); // => returns 'total'
 * console.log(this.elements['col2'].value); // => returns 'sku' (text of element)
 * console.log(this.data['name'].value); // => returns 'my_name' (text of data[name] of js-row)
 * ```
 *
 * By default, typescript will hide '.js-col2'.
 */
export abstract class ModelComponent {
  protected options: ModelComponentOptions = new ModelComponentOptions();

  public constructor(prefix: string, element: JQuery, checkboxSelector: string|null = null) {
    this.element = element;
    this.prefix = prefix;
    this.checkboxSelector = checkboxSelector;

    this.state = window.App.getProvider(State);
  }

  public static init(): void {
    // override in model
  }

  public init(): void {
    this.assignDomElements(this.prefix, this.element);

    if (this.options.resetElementVisibilityOnInitialise) {
      this.resetElementsVisibility();
    }

    this.assignProperties(this.element.data());

    if (this.checkboxSelector != null) {
      this.recordSelect = this.element.find(this.checkboxSelector);
      this.selected = this.recordSelect.is(':checked');

      // we need to bind the click event to stop accordions from toggling
      this.recordSelect.on('click', e => {
        e.stopImmediatePropagation();
      });

      // we then bind the change event as normal to select the checkbox
      this.recordSelect.on('change', e => {
        this.selected = this.recordSelect.is(':checked');
        this.state.dispatch({
          name: Events.MODEL_COMPONENT_SELECTED_CHANGE,
          payload: { object: this, selected: this.selected }
        });
      });
    }
  }

  /**
   * Create an Object of the elements in the model, e.g:
   * ```
   * {
   *   'title': { hidden: false },
   *   'notes': { hidden: true },
   * }
   * ```
   *
   * Use `this.assign_dom_elements` to create jquery objects based on the elements object.
   *
   * Things in here should "belong" in the DOM, aka it should be visible to the user at some point
   *
   * @abstract
   * @protected
   */
  public abstract elements: { [key: string]: ModelComponentElem };

  /**
   * Create an Object with the data for this model, which is loaded from the main div (data attributes) from the DOM.
   *
   * This object is intended to hold INTERNAL values, such as statuses, id's, etc.  As such, it can be somewhat typed.
   *
   * Things in here should NOT "being" in the DOM, aka the user will never see it on the page.  This removes the need
   * for hidden divs containing data purely for TS
   *
   * @see ModelComponentDataElem
   */
  public data: { [key: string]: ModelComponentDataElem };

  /**
   * Child records visible on the page.
   * @protected
   */
  protected children: ModelComponent[] = [];

  public getChildren(): Array<ModelComponent> {
    return this.children;
  }

  /**
   * If this model component has comments, slap 'em 'ere
   * @protected
   */
  public comments: CommentableComment[] = [];
  protected commentsVisible: boolean = false;

  public element: JQuery;
  protected prefix: string;
  protected recordSelect: JQuery;
  protected editRecord: JQuery;

  /**
   * If this object can be selected, add a ".record_select" checkbox to the element.
   * @protected
   */
  protected selected: boolean = false;
  protected allowSelect: boolean = true;
  protected visible: boolean = true;

  protected state: State;
  protected checkboxSelector: string;
  protected editRecordLink: string;

  /**
   * Assigns properties from an object to those that exist in the ModelComponent.
   *
   * Note: the properties must be defined AND assigned (even if to null or undefined).
   *
   * @param {{[key: string]: Object}} obj
   */
  public assignProperties(obj: {[key: string]: Object}): void {
    if (typeof this.data == typeof undefined || typeof obj == typeof undefined) {
      return;
    }

    for (let key of Object.keys(this.data)) {
      if (obj.hasOwnProperty(key)) {
        let val = String(obj[key]);

        switch(this.data[key].type) {
          case 'boolean':
            this.data[key].value = ['1', 'true', 'yes', 'on'].includes(val.toLowerCase());
            break;

          case 'number':
            this.data[key].value = Number.parseFloat(val);
            break;

          case 'array':
            this.data[key].value = obj[key];
            break;

          default:
            this.data[key].value = val + '';
        }
      } else {
        this.data[key].value = undefined;
        this.data[key].type = this.data[key].type || 'string';
      }
    }
  }

  /**
   * Using the data provided in this.elements, assign all the JQuery objects to this.dom_elements with matching
   * key names.  Uses the prefix on the class search, searches within the parent.
   *
   * E.g. if prefix is 'js-', parent is $('.container') and this.elements contains the key 'title' then it will create
   * ```
   * this.dom_elements['title'] = $('.container').find('.js-title');
   * ```
   *
   * @param prefix
   * @param parent
   */
  public assignDomElements(prefix: string = '', parent: JQuery = null): void {
    if (parent == null) {
      parent = $('body');
    }

    if (typeof this.elements == typeof undefined) {
      return;
    }

    for (let key of Object.keys(this.elements)) {
      let $elem = parent.find(`.${prefix}${key}`);
      let value = undefined;

      this.elements[key]['element'] = $elem;

      if (typeof $elem.data('value') !== 'undefined') {
        value = $elem.data('value');
      } else {
        value = $elem.text();
      }

      this.elements[key]['value'] = value.toString().trim();
    }
  }

  public isSelected(): boolean {
    return this.selected;
  }

  public match(key, value, fuzzy: boolean = false, on: 'elements'|'data' = 'elements', keepOriginal: boolean = false): boolean {
    value = value.toLowerCase();

    let obj = {};

    if (on == 'elements') {
      obj = this.elements;
    } else {
      obj = this.data;
    }

    if (typeof obj[key] == typeof undefined) {
      return;
    }

    let test_value = obj[key].value.toLowerCase();
    let matched = (test_value == value);

    if (fuzzy == true) {
      matched = new RegExp(value).test(test_value);
    }

    if (matched) {
      if (!keepOriginal) {
        this.show();
      }

      return true;
    } else {
      this.hide();
      return false;
    }
  }

  public matchChildren(key, value, fuzzy: boolean = true, on: 'elements'|'data' = 'elements', keepOriginal: boolean = false): boolean {
    let matches = 0;

    for (let child of this.children) {
      if (child.match(key, value, fuzzy, on, keepOriginal)) {
        matches++;
      }
    }

    // If there are no children that match, they will all be hidden and therefore we can hide the parent.  Otherwise,
    // we must ensure the parent dom is visible as there is at least 1 children dom visit.
    if (matches > 0) {
      if (!keepOriginal) {
        this.show();
      }

      return true;
    } else {
      this.hide();
      return false;
    }
  }

  public isVisible(): boolean {
    return this.visible;
  }

  public showComments(): void {
    this.commentsVisible = true;

    for(let comment of this.comments) {
      comment.show();
    }
  }

  public hideComments(): void {
    this.commentsVisible = false;

    for(let comment of this.comments) {
      comment.hide();
    }
  }

  public hasComments(): boolean {
    return this.comments.length > 0;
  }

  /// DOM Manipulation
  /// The following methods are very closely related to DOM manipulation, where manipulation of the DOM is not
  /// just a side-affect of the method but it's entire purpose for existing.

  public show(including_children: boolean = false): void {
    this.element.removeClass('hidden');
    this.visible = true;

    if (including_children) {
      this.children.forEach(ch => ch.show(true));
    }
  }

  public hide(including_children: boolean = false): void {
    this.element.addClass('hidden');
    this.visible = false;

    if (including_children) {
      this.children.forEach(ch => ch.hide(true));
    }
  }

  public deselect(including_children: boolean = false): void {
    this.recordSelect.prop('checked', false);

    if (including_children) {
      this.children.forEach(ch => ch.deselect(true));
    }
  }

  /**
   * Resets element visibility to it's predefined visibility state
   * @param key
   * @protected
   */
  protected resetElementVisibility(key: string): void {
    let hidden = (this.elements[key].hidden === true);
    this.elements[key]['element'].toggle(!hidden);
    this.state.dispatch({ name: Events.MODEL_COMPONENT_ELEMENT_VISIBILITY_CHANGED });
  }

  /**
   * Resets all elements in this.elements to their predefined default visibility state
   * @protected
   */
  public resetElementsVisibility(): void {
    if (typeof this.elements == typeof undefined) {
      return;
    }

    // Reset all elements to their original state
    for (let k of Object.keys(this.elements)) {
      this.resetElementVisibility(k);
    }
    this.hideEdit();
  }

  protected hideElement(key: string): void {
    this.elements[key]['element'].toggle(false);
    this.state.dispatch({ name: Events.MODEL_COMPONENT_ELEMENT_VISIBILITY_CHANGED });
  }

  protected showElement(key: string): void {
    this.elements[key]['element'].toggle(true);
    this.state.dispatch({ name: Events.MODEL_COMPONENT_ELEMENT_VISIBILITY_CHANGED });
  }

  public selectDisable(): void {
    if (!this.allowSelect) {
      return;
    }

    this.selected = false;
    this.allowSelect = false;
    this.recordSelect.prop('checked', false);
    this.recordSelect.prop('disabled', true);

    this.state.dispatch({
      name: Events.MODEL_COMPONENT_SELECTED_CHANGE,
      payload: { object: this, selected: this.selected }
    });
  }

  public selectEnable(): void {
    if (this.allowSelect) {
      return;
    }

    this.recordSelect.prop('disabled', false);
    this.allowSelect = true;

    this.state.dispatch({
      name: Events.MODEL_COMPONENT_SELECTED_CHANGE,
      payload: { object: this, selected: this.selected }
    });
  }

  public showSelect(): void {
    this.recordSelect.show();
  }

  public hideSelect(): void {
    this.recordSelect.hide();
  }

  public showEdit(): void {
    if (typeof this.editRecord !== typeof undefined) {
      this.editRecord.show();
    }
  }

  public hideEdit(): void {
    if (typeof this.editRecord !== typeof undefined) {
      this.editRecord.hide();
    }
  }

  public resetSelect(including_children: boolean = false): void {
    this.selected = false;
    this.allowSelect = true;
    this.recordSelect.prop('checked', false);
    this.recordSelect.prop('disabled', false);
    this.showSelect();
    this.hideEdit();

    if (including_children) {
      this.children.forEach(child => {
        child.resetSelect();
      });
    }

    this.state.dispatch({
      name: Events.MODEL_COMPONENT_SELECTED_CHANGE,
      payload: { object: this, selected: this.selected }
    });
  }
}
