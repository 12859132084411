import { Module, Element } from 'shared/core/index';
import { TurboLinks } from './turbolinks';
import { Injectable, Type } from 'injection-js';
import { isArray } from 'lodash';
import { RenderableView } from 'shared/fender_render';

export interface Route {
  path: string | string[];
  exclude?: string[];
  modules?: Type<Module>[];
  disable?: Type<Element>[];
  exact?: boolean;
  view?: Type<RenderableView>;
}

@Injectable()
export class Router {
  /* If using exact path skip number of segments i.e. for location prefix */
  private static skipPathSegments = 1;
  private routes: Route[] = [];
  private activeModules: Module[] = [];
  private activeView: RenderableView = undefined;

  constructor(
    private turbolinks: TurboLinks,
  ) { }

  public create(routes: Route[], useEventBindings = true): void {
    this.routes = routes;

    if (useEventBindings) {
      this.createWithEventBindings();
    } else {
      this.createNow();
    }
  }

  private createWithEventBindings(): void {
    this.turbolinks.navigation$.subscribe(async () => {
      if (!await this.start()) {
        // If there is no defined route, we create all the elements
        await window.App.createElements([]);
      }

      $('body').addClass('ready');
    });

    this.turbolinks.visit$.subscribe(async () => {
      for (const module of this.activeModules) {
        await module.cleanup();
      }

      if (this.activeView != undefined) {
        this.activeView.cleanup();
        this.activeView = undefined;
      }
    });
  }

  private createNow(): void {
    // This is called only once the document is in a "ready" state OR if the document is
    // already in a ready state.
    $(async () => {
      for (const module of this.activeModules) {
        await module.cleanup();
      }

      if (this.activeView != undefined) {
        this.activeView.cleanup();
        this.activeView = undefined;
      }

      if (!await this.start()) {
        // If there is no defined route, we create all the elements
        await window.App.createElements([]);
      }

      $('body').addClass('ready');
    });
  }

  public async start(): Promise<boolean> {
    const route: Route = this.routes.find(item => Router.checkPath(item.path, item.exclude, item.exact));
    if (!route) {
      return false;
    }

    await window.App.createElements(route.disable);
    this.activeModules = [];
    let promises = [];

    if (route.view != undefined && route.modules != undefined) {
      throw new Error('Cannot load both a module and a view');
    }

    if(route.view != undefined) {
      const localView: RenderableView = window.App.makeClass(route.view as Type<RenderableView>);
      await localView.setup();
      promises.push(localView.render());
      this.activeView = localView;
    }

    if (route.view == undefined) {
      for (const module of route.modules) {
        const localModule: Module = window.App.makeClass(module);
        promises.push(localModule.init());

        this.activeModules.push(localModule);
      }
    }

    await Promise.all(promises);
    return true;
  }

  private static checkPath(path: string | string[], exclude: string[] = [], exact = false): boolean {
    if (exact === true) {
      let pathname = window.location.pathname.slice(1);
      let skip = 0;

      while (skip < Router.skipPathSegments) {
        let slash = pathname.indexOf('/');
        pathname = pathname.slice(slash + 1);
        skip++;
      }

      return path === pathname;
    }

    const segments: string[] = window.location.pathname.split('/');
    const excluded = !!exclude.find(exclusion => segments.includes(exclusion));

    if (isArray(path)) {
      return (path as string[]).every(item => segments.includes(item)) && !excluded;
    } else if (typeof path === 'string') {
      return segments.includes(path) && !excluded;
    } else {
      return false;
    }
  }
}
